.time-travel-modal {
  .modal-content {
    .modal-header {
      border: none;
    }
    .modal-wrapper {
      width: 100%;
      position: relative;

      .content-wrapper {
        width: 100%;
        position: relative;
        padding: 36px 30px 45px;

        .map-block-wrapper {
          width: 100%;
          height: auto;
        }
        .form-current-loc {
          margin-top: 3em;
          .form-control {
            width: 100%;
            background-color: transparent;
            font-family: 'Arboria-Book';
            color: #aaaaaa;
            border-radius: 30px;
            border: 1px solid #ced4da;
            border-right: none;
            z-index: 0;
            // &:focus {
            //   box-shadow: none;
            // }
          }

          button#search-loc {
            border-radius: 0 30px 30px 0;
            height: calc(1.5em + 0.75rem + 2px);
            line-height: initial;
            background-color: #fff;
            background-repeat: no-repeat;
            background-image: url(../images/icon-search-orange.svg);
            background-position: center;
            border: 1px solid #ced4da;
            border-left: none;
            position: absolute;
            right: 0;
            z-index: 1;
            &:hover {
              background-color: #ff8800;
              border-color: #ff8800;
              background-image: url(../images/icon-search-white.svg);
            }
            &:not(:disabled):not(.disabled):active:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.checklist-modal {
  width: 40.5625em;
  min-width: 40.5625em;
  &.summary-lg {
    min-width: 60.6875em;
  }

  .modal-content {
    padding: 2.0625em;
    width: 100%;
    .modal-header {
      border: none;
      padding: 0;
      .modal-header-wrap {
        width: 100%;
        border-bottom: 1px solid #e9eaee;
        padding-bottom: 0.90625em;
        display: flex;
        align-items: center;
        .m-title {
          font-family: Arboria;
          font-size: 20px;
          font-weight: 500;
          color: #22456e;
        }
        .close {
          opacity: 1;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .content-wrapper {
      padding: 1.15625em 0;
      width: 100%;
      .m-info {
        display: flex;
        p {
          font-family: 'Arboria-Book';
          font-size: 0.875em;
          color: #22456e;
          font-weight: 100;
          line-height: 1;
          margin-left: 0.75em;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
      .checklist-sections {
        padding-top: 2.3125em;
        .checklist-sub-sec {
          border-radius: 10px;
          border: solid 1px #bababa;
          padding: 2.0625em 2.0625em 0;
          .checklist-heading {
            margin-bottom: 1.3em;
            .checklist-title {
              font-family: Arboria;
              font-size: 1.125em;
              font-weight: bold;
              color: #22456e;
            }
          }
          .sub-section {
            .sec-sub-title {
              font-family: Arboria;
              font-size: 16px;
              font-weight: 500;
              color: #22456e;
              margin-bottom: 0.6em;
            }
            .step-btn {
              border-radius: 2em;
              border: solid 1px #bababa;
              margin-right: 2em;
              width: 10.1em;
              color: #22456e;
              font-weight: 500;
              padding: 0.5em;
              &.orange-btn {
                background-color: #ff8800;
                color: #fff;
                border: solid 1px #ff8800;
              }
            }
          }
        }
        .home-reapir {
          img {
            width: 23.9375em;
            height: 13.8125em;
          }
        }
        .home-reapir-info {
          p {
            font-family: Arboria;
            font-size: 23px;
            font-weight: bold;
            color: #22456e;
            margin: 4.375em 0;
            text-align: center;
          }
        }
        .checklist-sub-step {
          .home-sub-section {
            width: 66.666667%;
            padding-right: 1.03125em;
            .card {
              border: none;
              .card-header {
                padding: 1.1em 1em;
                border-radius: 5px;
                background-color: #f4f4f4;
                border: none;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-between;
                justify-content: space-between;
                margin-bottom: 10px;
                .left-title {
                  display: -webkit-flex;
                  display: flex;
                  -webkit-align-items: center;
                  align-items: center;
                  .image-block {
                    max-width: 20px;
                    margin-right: 20px;
                  }
                }

                .right-title {
                  display: -webkit-flex;
                  display: flex;
                  -webkit-align-items: center;
                  align-items: center;
                  .count-block {
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                    color: #fff;
                    background-color: #ff8800;
                    font-family: 'Arboria-Book';
                    font-size: 16px;
                    margin-right: 15px;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                  }

                  span {
                    font-family: 'Arboria-Book';
                    font-size: 18px;
                    color: #000;
                    margin-right: 35px;
                  }

                  .arrow-icon img {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              .card-body {
                padding: 0em 2em 2em 2em;
                font-family: 'Arboria-Book';
                font-size: 16px;
                color: #22456e;
                p {
                  border-bottom: solid 1px #cfd4dd;
                  padding: 1em 0 0.2em;
                }
              }
            }
          }
          .home-repair-action {
            width: 33.333333%;
            padding-left: 1.03125em;
            .home-repair-type {
              border-radius: 10px;
              background-color: #f2f3f5;
              padding: 1em 1.5em;
              margin-bottom: 1em;
              img {
                width: 2.5em;
                height: auto;
                object-fit: contain;
              }
              .home-repair-data {
                padding-left: 0.6em;
                .data-title {
                  font-family: 'Arboria-Book';
                  font-size: 18px;
                  letter-spacing: normal;
                  color: #22456e;
                }
                .data-value {
                  font-family: Arboria;
                  font-size: 22px;
                  font-weight: 500;
                  color: #aadc91;
                }
              }
            }
            .home-repair-or {
              text-align: center;
              margin: 1.2em 0;
              font-family: 'Arboria-Book';
              font-size: 18px;
              color: #22456e;
            }

            .home-repair-termi {
              padding: 0.7em;
              border-radius: 10px;
              background-color: #f2f3f5;
              width: 100%;
              font-family: Arboria;
              font-size: 16px;
              font-weight: 500;
              line-height: 1;
              text-align: center;
              color: #22456e;
            }
          }
        }
      }
      .btn-wrapper {
        margin-top: 1.5625em;
        .c-btn-action {
          min-width: 6.25em;
        }
      }
    }
  }
}

.propertyItemDetail-modal {
  width: 90%;
  min-width: 90%;
  .modal-content {
    background-color: #f6f7fa;
    padding: 2em;
    width: 100%;
    .buyer-dashboard .bottom-block .left-block button {
      height: auto;
    }
  }
}

.onhold-property-modal {
  max-width: 35.0625em;

  .modal-content {
    border-radius: 1.5em;
    padding: 0.5em;
    width: 100%;
    height: auto;
    .modal-header {
      border-bottom: none;
    }
    .modal-body {
      padding: 0 2em;
      .onhold-div {
        .onhold-img {
          display: flex;
          justify-content: center;

          img {
            width: 317px;
            height: 227px;
          }
        }

        .onhold-content {
          h1 {
            font-family: Arboria;
            font-size: 1.75em;
            font-weight: bold;
            letter-spacing: inherit;
            text-align: center;
            color: #22456e;
            margin-top: 1.2em;
          }

          p {
            margin: 0.4em;
            font-family: 'Arboria-Book';
            font-size: 1.125em;
            text-align: center;
            color: #22456e;
            line-height: normal;
          }
        }
      }
    }

    .modal-footer {
      justify-content: center;
      border: none;
      padding: 2em;
    }
  }
}
