.field-wrapper label {
  font-size: 0.875rem;
  color: #8181a5;
  margin-bottom: 0;
}

.field-wrapper input,
.field-wrapper select {
  display: block;
  /* border: 0;
  border-bottom: 1px solid #ccc; */
  background-color: #fff;
  width: 100%;
  /* padding: 5px 0px 10px; */
  letter-spacing: 0.18px;
  font-size: 0.875rem;
  color: #1c1d21;
  font-weight: 400;
}

.field-wrapper input:focus,
.field-wrapper select:focus {
  outline: none;
}

.field-wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.field-wrapper ::-webkit-input-placeholder,
.field-wrapper :-ms-input-placeholder,
.field-wrapper ::-ms-input-placeholder,
.field-wrapper ::placeholder {
  color: #8181a5;
}

.error {
  color: #ff0000;
}
