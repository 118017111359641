/* This is a custom radio btn 1 */

.radios-one [type='radio']:checked,
.radios-one [type='radio']:not(:checked) {
  position: absolute;
  left: 0px;
  height: 22px;
  width: 22px;
}

.radios-one [type='radio']:checked + label,
.radios-one [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  line-height: 26px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.18px;
  font-weight: bold;
  font-size: 0.875rem;
}

.radios-one [type='radio']:checked + label:before,
.radios-one [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #1b51e5;
  border-radius: 50%;
  background: #fff;
}

.radios-one [type='radio']:checked + label:after,
.radios-one [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #1b51e5;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radios-one [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radios-one [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* This is a custom radio btn 2 */

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold;
  color: #8181a5;
}
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-container input:checked ~ .checkmark {
  background-color: #1b51e5;
}
.radio-container input:checked ~ .checkmark::after {
  display: block;
}
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}
.radio-container .checkmark::after {
  content: '';
  position: absolute;
  display: none;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}
