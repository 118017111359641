.stepper-outer {
  background-color: $gray-shade-8;
  width: calc(100% - 260px);
  padding: 20px;

  @include bp(lg-max) {
    width: 100%;
  }
}

.step-header-input {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $gray-shade-9;
  .left-block {
    width: 60%;
    @include bp(lg-max) {
      display: flex;
      align-items: center;
    }
    @include bp(sm-max) {
      width: 50px;
    }
    input {
      height: 40px;
      padding: 5px 20px 5px 45px;
      border-radius: 10px;
      border: solid 1px $gray-shade-9;
      background-color: rgba(255, 255, 255, 0.87);
      width: 100%;
      background-image: url(../images/iconSearch.svg);
      background-repeat: no-repeat;
      background-position: center left 10px;
      background-size: 20px;
    }
  }
  .right-block {
    @include bp(sm-max) {
      width: calc(100% - 50px);
      display: flex;
      justify-content: flex-end;
    }
    .auth-btns-space {
      @include bp(sm-max) {
        display: flex;
        justify-content: flex-end;
      }
      @include bp(xs-max) {
        justify-content: space-between;
      }
      button {
        margin: 0 0 0 10px;
        @include bp(xs-max) {
          margin: 0;
        }
      }
    }
  }
}

.step-header-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: $blue;
}

.stepper-wrapper {
  display: flex;
  border-radius: 10px;
  overflow: hidden;

  .step-count {
    width: 260px;
    padding: 50px 35px;
    background-color: $blue;
    color: $white;

    @include bp(md-max) {
      padding: 30px 20px;
      width: 220px;
    }

    @include bp(sm-max) {
      width: 80px;
    }

    @include bp(s-max) {
      width: 65px;
      padding: 30px 15px;
    }

    @include bp(xxs-max) {
      width: 50px;
      padding: 30px 9px;
    }

    > div {
      &:last-child {
        .count {
          &:after {
            display: none;
          }
        }
      }
    }

    .count-wrapper {
      display: flex;
      margin-bottom: 65px;
      cursor: pointer;
      @include bp(sm-max) {
        margin-bottom: 35px;
      }

      &.active {
        .count {
          color: $orange-shade;
          border-color: $orange-shade;
        }

        .step-number {
          color: $orange-shade;
        }

        .step-title {
          color: $white;
        }
      }

      .count {
        border: 1px solid $blue-shade-3;
        height: 33px;
        width: 33px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $blue-shade-3;
        margin-right: 20px;
        font-family: $primary-font;
        font-size: 16px;
        position: relative;

        @include bp(sm-max) {
          margin-right: 0;
        }

        &:after {
          content: '';
          position: absolute;
          height: 69px;
          width: 1px;
          background-color: $blue-shade-3;
          top: 31px;
          opacity: 0.54;
          @include bp(sm-max) {
            height: 37px;
          }
        }
      }

      .title-wrapper {
        @include bp(sm-max) {
          display: none;
        }
      }

      .step-number {
        font-size: 12px;
        color: $blue-shade-3;
        font-family: $secondary-font-medium;
        line-height: 1;
      }

      .step-title {
        color: $blue-shade-3;
        font-size: 16px;
        font-family: $secondary-font-medium;
      }
    }
  }

  .step-conetnt-wrapper {
    width: calc(100% - 260px);
    background-color: $white;
    padding: 50px 65px;

    @include bp(xlg-max) {
      padding: 50px 30px;
    }

    @include bp(md-max) {
      padding: 30px;
      width: calc(100% - 220px);
    }

    @include bp(sm-max) {
      width: calc(100% - 80px);
    }

    @include bp(s-max) {
      width: calc(100% - 65px);
      padding: 30px 20px;
    }

    @include bp(xxs-max) {
      width: calc(100% - 50px);
      padding: 30px 15px;
    }

    .page-title {
      display: none;
      font-size: 20px;
      line-height: 24px;
      // font-family:$secondary-font-medium;
      margin-bottom: 10px;
      font-weight: 700;
      color: $blue;

      @include bp(sm-max) {
        display: block;
      }
    }
  }
}

.green-title {
  margin: 0;
  color: $color-light-green !important;
  font-family: $secondary-font-bold;
  font-size: 16px !important;
  margin-bottom: 20px !important;
}

.step-conetnt-wrapper {
  .address-map {
    .green-text {
      color: $color-light-green;
      font-family: $secondary-font-bold;
      font-size: 16px;

      @include bp(sm-max) {
        margin-bottom: 10px;
      }
    }

    .currently-select {
      display: flex;
      justify-content: space-between;
      color: $blue;
      font-size: 14px;
      font-family: $secondary-font-book;
      margin-bottom: 5px;

      @include bp(sm-max) {
        flex-direction: column;
      }

      div {
        @include bp(sm-max) {
          margin-bottom: 5px;
        }
      }
    }

    .map-block-wrapper {
      height: 257px;
      background-color: $gray-shade-2;
      margin-bottom: 20px;
    }

    .more-info {
      color: $blue;
      font-size: 14px;
      font-family: $secondary-font-book;
      margin-bottom: 10px;
    }
  }

  .basic-detail-wrapper {
    .more-info {
      color: $blue;
      font-size: 16px;
      font-family: $secondary-font-medium;
      margin-bottom: 25px;

      @include bp(sm-max) {
        font-size: 14px;
      }
    }

    .orange-fill-btn {
      margin-top: 20px;
      height: 45px;
      margin-left: 10px;
      min-width: 162px;

      @include bp(sm-max) {
        height: 35px;
        min-width: 120px;
        margin: 0 0 20px;
      }
    }

    .checkbox-flex-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .check-list {
      min-width: 160px;
      margin-bottom: 10px;

      @include bp(sm-max) {
        min-width: 135px;
        margin-right: 10px;
      }

      @include bp(xs-max) {
        min-width: calc(50% - 10px);
      }

      @include bp(xxs-max) {
        min-width: 100%;
        margin-right: 0;
      }

      label {
        color: $blue;
        font-size: 16px;
        font-family: $secondary-font-medium;

        @include bp(sm-max) {
          font-size: 14px;
        }
      }
    }

    .check-title {
      margin: 20px 0px;
      color: $gray-shade-3;
      font-family: $secondary-font-bold;
      font-size: 16px;
    }

    .flex-end-box {
      align-items: flex-end;
    }
  }

  .upload-media {
    .media-heading {
      .green-title {
        margin-right: 15px;
      }

      .sub-title {
        font-family: $secondary-font-book;
        font-size: 14px;
        color: $blue;
      }
    }

    .field-wrapper {
      .green-title {
        margin-bottom: 0px !important;
      }

      label {
        margin-bottom: 13px;
        font-size: 12px;
      }
    }
  }

  .btn-wrapper {
    &.flex {
      &.justify-end {
        button {
          @include bp(s-max) {
            min-width: calc(50% - 8px);
          }
        }
      }
    }
  }

  .stepper-sections {
    .preview {
      ul {
        display: flex;
        list-style: none;
        margin-top: 30px;
        flex-wrap: wrap;

        li {
          position: relative;
        }
      }

      .uploaded-image {
        width: 106px;
        height: 106px;
        margin: 0 10px 10px;
        box-shadow: 0 2px 4px 0 $grey-shade-13;
        object-fit: cover;
      }

      .del-btn {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 20px;
      }

      input {
        display: none;
      }

      .btn-image {
        width: 32px;
        height: 30px;
        margin: 0 37px 4px;
        object-fit: contain;
      }

      label {
        width: 106px;
        height: 106px;
        padding: 25px 0 28px;
        background-color: $gray-shade-12;
        border-radius: 4px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: repeating-linear-gradient(
            0deg,
            #22456e,
            #22456e 9px,
            transparent 9px,
            transparent 18px,
            #22456e 18px
          ),
          repeating-linear-gradient(
            90deg,
            #22456e,
            #22456e 9px,
            transparent 9px,
            transparent 18px,
            #22456e 18px
          ),
          repeating-linear-gradient(
            180deg,
            #22456e,
            #22456e 9px,
            transparent 9px,
            transparent 18px,
            #22456e 18px
          ),
          repeating-linear-gradient(
            270deg,
            #22456e,
            #22456e 9px,
            transparent 9px,
            transparent 18px,
            #22456e 18px
          );
        background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
      }
    }

    .field-outer-flex {
      &.label-only {
        margin-bottom: 0;
      }
    }

    .step-btn {
      border-radius: 25px;
      font-size: 16px;
      padding: 7px 20px;
      min-width: 148px;
      height: 40px;
      transition: all 0.3s;

      @include bp(md-max) {
        margin-right: 15px !important;
        min-width: 130px;
        line-height: 1;
      }

      @include bp(sm-max) {
        font-size: 14px;
        line-height: 1;
        height: 35px;
        margin-right: 10px !important;
        min-width: 120px;
        margin-bottom: 10px;
      }

      @include bp(s-max) {
        min-width: calc(50% - 10px);
      }
    }

    .bordered-btn {
      border: 2px solid $gray-shade;
      color: $blue;
      background-color: $white;
      margin-right: 30px;
    }

    .orange-btn {
      border: 2px solid $orange-shade;
      color: $white;
      background-color: $orange-shade;
      margin-right: 30px;
    }

    .more-info {
      color: $blue;
      font-size: 16px;
      font-family: $secondary-font-book;
      margin-bottom: 25px;
      font-size: 14px;
      line-height: 1.5;
      text-align: justify;
    }

    .sub-section {
      margin: 30px 0;
      padding: 24px 29px;
      border-radius: 10px;
      border: solid 1px $gray-shade-9;

      @include bp(sm-max) {
        padding: 20px 15px;
        margin: 20px 0;
      }

      .accordion {
        .card-header {
          padding: 9px 33px 10px 21px;
          border-radius: 5px;
          background-color: $grey-shade-14;
          border: none;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          @include bp(xs-max) {
            padding: 10px 15px;
            flex-direction: column;
          }

          .left-title {
            display: flex;
            align-items: center;

            @include bp(xs-max) {
              margin-bottom: 10px;
            }

            .image-block {
              max-width: 20px;
              margin-right: 20px;
            }
          }

          .right-title {
            display: flex;
            align-items: center;

            @include bp(xs-max) {
              justify-content: flex-end;
            }

            .count-block {
              width: 25px;
              height: 25px;
              border-radius: 100%;
              color: $white;
              background-color: $orange-shade;
              font-family: $secondary-font-book;
              font-size: 16px;
              margin-right: 15px;
              display: flex;
              align-items: center;
              justify-content: center;

              @include bp(xs-max) {
                width: 18px;
                height: 18px;
              }
            }

            span {
              font-family: $secondary-font-book;
              font-size: 18px;
              color: $black;
              margin-right: 35px;
            }
          }
        }

        .card {
          border: none;
        }

        .card-body {
          padding: 10px 15px 25px;
          font-family: $secondary-font-medium;
          font-size: 16px;
          color: $blue;

          @include bp(xs-max) {
            padding: 10px 15px 20px;
          }

          p {
            border-bottom: solid 1px #cfd4dd;
            padding-bottom: 10px;
          }
        }
      }

      .sec-title {
        font-family: $secondary-font-bold;
        font-size: 18px;
        color: $blue;
        margin-bottom: 30px;

        @include bp(sm-max) {
          margin-bottom: 15px;
          font-size: 16px;
        }
      }

      .sec-sub-title {
        font-size: 16px;
        font-weight: 500;
        color: $blue;
        margin-bottom: 20px;
      }

      .sec-input {
        margin-top: 11px;
        width: 85%;
      }

      p.vidUrl {
        height: 39px;
        border-radius: 10px;
        padding: 5px 15px;
        margin-top: 11px;
        width: 100%;
        border: solid 2px #e2e2e2;
        font-size: 16px;
        letter-spacing: 0.18px;
      }
    }

    .upload-doc-div {
      margin-top: 15px;
      display: flex;
      align-items: center;

      span.doc-span {
        font-size: 18px;
        font-weight: 800;
        color: #ff8800;
      }
    }

    .dashed-file-border {
      width: 215px;
      height: 40px;
      padding: 8px 0 10px 16px;
      background-color: $gray-shade-12;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    .dashed-file-border img {
      width: 15px;
      height: 18px;
      margin-right: 7px;
      object-fit: contain;
    }

    .upload-doc {
      display: none;
    }

    .no-doc {
      display: flex;
      align-items: center;
      margin-top: 15px;

      @include bp(sm-max) {
        margin-top: 0;
      }

      img {
        width: 15px;
        height: 15px;
        margin-right: 10px;

        @include bp(sm-max) {
          width: 13px;
          height: 13px;
          margin-right: 5px;
        }
      }

      span {
        @include bp(sm-max) {
          font-size: 14px;
        }
      }
    }

    .sub-div {
      display: flex;
      flex-wrap: wrap;

      .review-sec {
        flex: 0 0 33.33%;
        padding-right: 10px;
        margin-bottom: 30px;

        @include bp(sm-max) {
          margin-bottom: 15px;
        }

        @include bp(xs-max) {
          flex: 0 0 calc(100% - 10px);
        }
      }

      .review-sec h5 {
        font-size: 16px;
        font-family: $secondary-font-medium;
        color: $gray-shade-3;
      }

      .review-sec p {
        font-size: 16px;
        font-family: $secondary-font-medium;
        color: $blue;
        margin-top: 6px;
      }
    }

    .address-map {
      .map {
        height: 230px;
        background-color: #d8d8d8;
        margin: 20px 0;
      }
    }

    .sub-section2 {
      border-radius: 4px;
      margin: 30px 0;
      padding: 24px 29px;
      background-color: #22456e;
      display: flex;
      justify-content: space-between;

      @include bp(sm-max) {
        padding: 20px 15px;
        margin: 20px 0;
      }

      @include bp(xs-max) {
        flex-direction: column;
      }

      .review-price {
        @include bp(xs-max) {
          margin-bottom: 10px;
        }
      }

      .review-price h6 {
        font-size: 16px;
        color: #fff;
        font-weight: 300;
      }

      .review-price p {
        font-size: 18px;
        color: #aadc91;
        @include bp(sm-max) {
          font-size: 16px;
        }
      }
    }
  }
}

.dashed-file-border {
  background-image: repeating-linear-gradient(
      0deg,
      #22456e,
      #22456e 9px,
      transparent 9px,
      transparent 18px,
      #22456e 18px
    ),
    repeating-linear-gradient(
      90deg,
      #22456e,
      #22456e 9px,
      transparent 9px,
      transparent 18px,
      #22456e 18px
    ),
    repeating-linear-gradient(
      180deg,
      #22456e,
      #22456e 9px,
      transparent 9px,
      transparent 18px,
      #22456e 18px
    ),
    repeating-linear-gradient(
      270deg,
      #22456e,
      #22456e 9px,
      transparent 9px,
      transparent 18px,
      #22456e 18px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.terms-condition-modal {
  .modal-content {
    border-radius: 24px;
    padding: 29px 29px 63px;

    .modal-header {
      padding: 0 0 16px;
      border-bottom: solid 1px #e9eaee;
      margin-bottom: 31px;

      .modal-title {
        font-size: 20px;
        font-weight: 500;
        color: #22456e;
      }

      .close {
        display: none;
      }
    }

    .modal-body {
      .terms-condition-div {
        border-radius: 10px;
        border: solid 1px #e9eaee;
        padding: 0px 22px 22px 22px;

        .terms-items {
          margin-bottom: 26px;

          h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 11px;
            margin-top: 25px;
          }

          strong {
            font-weight: unset;
          }

          p {
            font-size: 18px;
            line-height: normal;
            text-align: justify;
          }
        }
      }

      .terms-checkbox-div {
        margin-top: 21px;

        .field-outer-flex {
          margin-left: 0;

          .more-info {
            font-size: 17.6px;
            font-family: 'Arboria-Book';
          }
        }

        .modal-footer {
          border: none;
          justify-content: flex-start;
          padding: 0;
        }
      }
    }
  }
}

.success-property-modal {
  max-width: 561px;

  .modal-content {
    border-radius: 24px;
    padding: 106px 79px 79px 79px;
    width: 100%;
    height: 628px;

    .modal-body {
      .success-div {
        .success-img {
          display: flex;
          justify-content: center;

          img {
            width: 317px;
            height: 227px;
          }
        }

        .success-content {
          h1 {
            font-family: Arboria;
            font-size: 32px;
            margin-top: 47px;
            font-weight: 500;
            color: #aadc91;
          }

          p {
            font-size: 22px;
            font-weight: normal;
            margin-top: 5px;
          }
        }
      }
    }

    .modal-footer {
      justify-content: center;
      border: none;
      margin-top: 33px;
    }
  }
}
