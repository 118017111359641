.schedule-tour-modal {
  .modal-content {
    padding: 27px 35px;
    @include bp(xs-max){
      padding: 22px 18px;
    }

    .modal-header {
      padding: 0;

      .modal-title {
        font-size: 18px;
        font-weight: 800;
        color: #22456e;
        margin-bottom: 16px;
      }

      // button.close {
      //   display: none;
      // }
    }
    .modal-body {
      margin-top: 29px;

      .schedule-type-btn {
        margin-bottom: 26px;

        .sche-btn-wrap {
          display: flex;
          justify-content: space-between;
          img {
            width: 19px;
            height: 12px;
            margin-right: 7px;
          }
          .btn {
            padding: 9px 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include bp(xs-max){
              padding: 9px 20px;
            }
            @include bp(xxs-max){
              padding: 9px 15px;
            }
          }
          // .orange-fill-btn {
          //   padding: 9px 46px;
          // }
          // .gray-fill-btn {
          //   padding: 9px 41px;
          //   display: flex;
          //   align-items: center;
          // }
        }
      }
      h6.schedule-title {
        font-family: Arboria;
        font-size: 18px;
        font-weight: 500;
        color: #22456e;
        margin-bottom: 11px;
      }
      .select-date-div {
        margin-bottom: 32px;

        .select-date-ul {
          display: flex;
          justify-content: space-between;
          @include bp(xs-max){
            overflow: auto;
            padding-bottom: 10px;
          }

          .delect-date-li {
            text-align: center;
            padding: 13px 15px;
            // background: #aadc91;
            border-radius: 10px;
            .day {
              font-family: Arboria;
              font-size: 16px;
              font-weight: 500;
              color: #a1abbd;
            }

            .date {
              font-family: Arboria;
              font-size: 16px;
              font-weight: 500;
              &:after {
                display: block;
                border-radius: 50%;
                width: 8px;
                margin: 7px auto 0;
                height: 8px;
                content: '';
                background-color: #a1abbd;
              }
            }
            &.green-bg-li {
              background: #aadc91;

              .day {
                color: #fff;
              }
              .date {
                color: #fff;

                &:after {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
      .select-time-div {
        margin-bottom: 32px;

        .react-datepicker-wrapper {
          display: block;
          input {
            width: 100%;
            position: relative;
          }
        }
      }
    }

    .modal-footer {
      border: none;
      padding: 12px 0;
      button {
        width: 100%;
      }
    }
  }
}
