@font-face {
    font-family: 'Arboria-Book';
    src: url('Arboria-Book.eot');
    src: url('Arboria-Book.eot?#iefix') format('embedded-opentype'),
        url('Arboria-Book.woff2') format('woff2'),
        url('Arboria-Book.woff') format('woff'),
        url('Arboria-Book.ttf') format('truetype'),
        url('Arboria-Book.svg#Arboria-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arboria-bold';
    src: url('Arboria-Bold.eot');
    src: url('Arboria-Bold.eot?#iefix') format('embedded-opentype'),
        url('Arboria-Bold.woff2') format('woff2'),
        url('Arboria-Bold.woff') format('woff'),
        url('Arboria-Bold.ttf') format('truetype'),
        url('Arboria-Bold.svg#Arboria-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arboria';
    src: url('Arboria-Medium.eot');
    src: url('Arboria-Medium.eot?#iefix') format('embedded-opentype'),
        url('Arboria-Medium.woff2') format('woff2'),
        url('Arboria-Medium.woff') format('woff'),
        url('Arboria-Medium.ttf') format('truetype'),
        url('Arboria-Medium.svg#Arboria-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

