.agreement-wrapper {
  .tab-header {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    @include bp(md-max) {
      flex-wrap: nowrap;
      overflow-y: auto;
    }
    li {
      @include bp(md-max) {
        min-width: fit-content;
        margin: 0 15px;
      }
      .tab-link {
        font-family: $secondary-font-medium;
        font-size: 20px;
        font-weight: 500;
        color: $blue;
        background-image: url(../images/image-arrow-inactive.png);
        background-position: center right 0px;
        background-repeat: no-repeat;
        background-size: 20px;
        padding-right: 30px;
        &.active {
          background-image: url(../images/image-arrow-blue.png);
        }
        &.complete {
          background-image: url(../images/image-done.png);
        }
      }
    }
  }
  .content-block {
    background-color: $gray-shade-8;
    padding: 40px;
    @include bp(lmd-max) {
      padding: 30px 10px;
    }
    @include bp(xs-max) {
      padding: 20px 0;
    }
    .review-agreement {
      display: flex;
      @include bp(md-max) {
        flex-wrap: wrap;
      }
      .left-block {
        background-color: $white;
        border-radius: 10px;
        padding: 35px;
        flex: 0 0 65%;
        max-width: 65%;
        @include bp(lg-max) {
          flex: 0 0 55%;
          max-width: 55%;
        }
        @include bp(md-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;
        }
        @include bp(sm-max) {
          padding: 20px;
        }
        .heading {
          display: flex;
          margin-bottom: 30px;
          @include bp(xs-max) {
            justify-content: space-between;
            overflow: auto;
          }
          li {
            margin-right: 55px;
            font-family: $secondary-font-medium;
            font-size: 18px;
            color: $blue-shade-3;
            margin-bottom: 25px;
            min-width: 95px;
            position: relative;
            @include bp(lg-max) {
              margin-right: 35px;
            }
            @include bp(xs-max) {
              margin-right: 25px;
              min-width: fit-content;
            }
            &:last-child {
              @include bp(xs-max) {
                margin-right: 0;
              }
            }
            .tab-link {
              &:after {
                content: '';
                background-color: $color-light-green;
                height: 2px;
                width: 84px;
                position: absolute;
                left: 0;
                bottom: -10px;
                display: none;
              }
              &.active {
                color: $blue;
                &:after {
                  display: inline-block;
                }
              }
            }
          }
        }
        .inner-tab-content {
          max-height: 500px;
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: $color-light-green $white;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background-color:$color-light-green;
            border-radius: 4px;
          }
          .content-item {
            margin-bottom: 25px;
            h3 {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 11px;
              color: $blue;
              font-family: $secondary-font-medium;
            }
            p {
              font-size: 18px;
              line-height: normal;
              text-align: justify;
              font-family: $secondary-font-book;
              color: $blue;
            }
          }
        }
        .content {
          margin-bottom: 25px;
          display: flex;
          @include bp(xxs-max) {
            flex-direction: column;
          }
          .title {
            min-width: 150px;
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue-shade-3;
          }
          .value {
            font-family: $secondary-font-medium;
            font-size: 18px;
            color: $blue;
            .more-info {
              font-family: $secondary-font-book;
              color: $gray-shade-5;
              font-size: 16px;
            }
          }
        }
      }
      .right-block {
        padding-left: 30px;
        @include bp(md-max) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0 10px;
        }
        .title {
          span {
            position: relative;
            display: inline-block;
            margin-bottom: 20px;
            font-family: $secondary-font-medium;
            font-size: 18px;
            color: $blue;
            &:after {
              content: '';
              background-color: $color-light-green;
              height: 2px;
              width: 48px;
              position: absolute;
              left: 0;
              bottom: -10px;
            }
          }
        }
        .content {
          font-family: $secondary-font-book;
          font-size: 18px;
          color: $blue;
          line-height: 1.1;
          text-align: justify;
          margin-bottom: 30px;
        }
        .field-wrapper {
          .custom-input {
            &.secondary-input {
              background-image: url('../images/signature.svg');
              background-position: center left 10px;
              background-repeat: no-repeat;
              padding-left: 50px;
              &::placeholder {
                font-size: 18px;
                color: $gray-shade-9;
                @include bp(sm-max) {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .checkbox-wrapper {
          margin-bottom: 35px;
        }
        .btn-wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 50px;
        }
      }
    }
    .payment-method-wrapper {
      background-color: $white;
      border-radius: 10px;
      padding: 35px;
      display: flex;
      align-items: flex-start;
      @include bp(lmd-max) {
        padding: 30px 20px;
      }
      @include bp(md-max) {
        flex-wrap: wrap;
      }
      > .left-block {
        flex: 0 0 60%;
        max-width: 60%;
        @include bp(xlg-max) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include bp(md-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;
        }
        @include bp(xs-max) {
          margin-bottom: 10px;
        }
        .title {
          font-family: $secondary-font-medium;
          font-size: 20px;
          color: $gray-shade-9;
          margin-bottom: 10px;
        }
        .property-view {
          display: flex;
          justify-content: space-between;
          border-bottom: solid 1px $grey-shade-15;
          padding-bottom: 45px;
          margin-bottom: 25px;
          @include bp(xs-max) {
            flex-direction: column;
          }
          .left-block {
            display: flex;
            align-items: flex-start;
            @include bp(xs-max) {
              margin-bottom: 20px;
            }
            .image-block {
              height: 80px;
              width: 80px;
              overflow: hidden;
              border-radius: 10px;
              margin-right: 10px;
              img {
                height: 100%;
                width: 100%;
              }
            }
            .property-detail {
              display: flex;
              flex-direction: column;
              height: 100%;
              .title {
                font-family: $secondary-font-medium;
                font-size: 18px;
                color: $blue;
                line-height: 1;
              }
              .sub-tile {
                font-family: $secondary-font-book;
                color: $gray-shade-5;
                font-size: 16px;
              }
              .rent-tag {
                font-family: $secondary-font-medium;
                font-size: 14px;
                color: $white;
                height: 23px;
                width: 95px;
                display: inline-block;
                background-color: $color-light-green;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: auto;
              }
            }
          }
          .right-block {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @include bp(xs-max) {
              align-items: flex-start;
            }
            .label {
              font-family: $secondary-font-medium;
              font-size: 18px;
              line-height: 1;
              color: $blue;
            }
            .amount {
              font-family: $secondary-font-medium;
              font-size: 32px;
              line-height: 1;
              color: $color-light-green;
            }
          }
        }
        .label-count-wrapper {
          .block-outer {
            border-bottom: solid 1px $grey-shade-15;
            margin-bottom: 25px;
            &:last-child {
              border-color: $blue;
            }
          }
          .block-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            @include bp(xxs-max) {
              flex-direction: column;
            }
            .label {
              font-family: $secondary-font-medium;
              font-size: 16px;
              color: $gray-shade-5;
            }
            .value {
              font-family: $secondary-font-medium;
              font-size: 18px;
              color: $blue;
            }
          }
        }
      }
      > .right-block {
        flex: 0 0 calc(40% - 30px);
        max-width: calc(40% - 30px);
        margin-left: 30px;
        background-color: $grey-shade-22;
        border-radius: 10px;
        padding: 30px;
        @include bp(xlg-max) {
          flex: 0 0 calc(50% - 30px);
          max-width: calc(50% - 30px);
        }
        @include bp(lmd-max) {
          padding: 20px;
          margin-left: 20px;
          flex: 0 0 calc(50% - 20px);
          max-width: calc(50% - 20px);
        }
        @include bp(md-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-left: 0;
        }
        .title {
          display: flex;
          justify-content: center;
          font-family: $secondary-font-medium;
          color: $blue;
          margin-bottom: 40px;
          @include bp(lmd-max) {
            margin-bottom: 25px;
          }
        }
        .block-wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          @include bp(s-max) {
            align-items: flex-start;
            flex-direction: column;
          }
          .left-block {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            @include bp(s-max) {
              margin-bottom: 10px;
            }
            .label {
              font-family: $secondary-font-medium;
              font-size: 16px;
              color: $blue;
            }
            .value {
              font-family: $secondary-font-medium;
              font-size: 28px;
              color: $color-light-green;
            }
          }
          .right-block {
            flex: 1;
            @include bp(s-max) {
              width: 100%;
            }
            .field-wrapper {
              margin-bottom: 0;
              width: 195px;
              margin-left: auto;
              @include bp(s-max) {
                width: 100%;
              }
            }
          }
        }
        .link-bank {
          margin-bottom: 2.5em;
          button {
            width: 100%;
            height: 3.4em;
            background-color: #ff8500;
            color: #ffffff;
            border-radius: 4px;
            text-transform: uppercase;
            font-weight: 600;
            font-family: 'Arboria';
          }
        }
        .btn-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue;
            margin-top: 25px;
          }
        }
      }
    }
  }
}
