.buyer-property-list {
  padding: 20px 35px;
  background-color: $white;
  border-radius: 10px;
  @include bp(md-max) {
    padding: 20px 15px;
  }
  .buyer-property {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    @include bp(md-max) {
      align-items: flex-start;
    }
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
    .title {
      font-family: $secondary-font-medium;
      font-size: 18px;
      line-height: 22px;
      color: $blue;
    }
    .info-text {
      font-family: $secondary-font-book;
      font-size: 16px;
      color: $gray-shade-5;
    }
    .orange-text {
      color: $orange-shade;
      font-family: $secondary-font-medium;
      font-size: 16px;
      margin-top: auto;
    }
    .left-block {
      display: flex;
      width: 290px;
      @include bp(sm-max) {
        width: 100%;
      }
      .image-block {
        height: 80px;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 15px;
      }
      .content {
        display: flex;
        flex-direction: column;
      }
    }
    .right-block {
      width: calc(100% - 350px);
      @include bp(xlg-max) {
        width: calc(100% - 320px);
      }
      @include bp(sm-max) {
        width: 100%;
      }
      .orange-text {
        margin-bottom: 15px;
        display: block;
      }
      .btn-wrapper {
        display: flex;
        @include bp(md-max) {
          flex-wrap: wrap;
        }
        button {
          height: 40px;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          width: 33.33%;
          margin-right: 25px;
          @include bp(xl-max) {
            margin-right: 5px;
            padding: 10px;
          }
          @include bp(md-max) {
            width: calc(50% - 5px);
            margin-bottom: 5px;
          }
          @include bp(sm-max) {
            width: calc(33.33% - 5px);
          }
          @include bp(s-max) {
            width: calc(50% - 5px);
          }
          @include bp(xxs-max) {
            width: 100%;
          }
          img {
            margin-right: 15px;
          }
          &:last-child {
            margin-right: 0;
          }
          &.rounded-fill-btn {
            &:hover {
              color: $white;
              background-color: $color-light-green;
            }
          }
        }
      }
    }
  }
}
.checklist-modal {
  .modal-content {
    @include bp(xs-max) {
      margin: 0;
    }
  }
  .modal-dialog {
    max-width: 690px;
    @include bp(xs-max) {
      max-width: 100%;
    }
    .modal-header {
      padding: 15px 30px;
      .modal-title {
        font-family: $secondary-font-medium;
        font-size: 20px;
        color: $blue;
      }
    }
    .modal-body {
      padding: 20px 30px;
      @include bp(xs-max) {
        padding: 20px 10px;
      }
      .info-block {
        display: flex;
        margin-bottom: 30px;
        .img-block {
          max-width: 20px;
          display: inline-block;
          margin-right: 10px;
        }
        span {
          font-family: $secondary-font-book;
          font-size: 16px;
          line-height: 1;
          color: $blue;
        }
      }
      .content-wrapper {
        padding: 25px;
        border-radius: 10px;
        border: solid 1px $gray-shade-9;
        @include bp(xs-max) {
          padding: 20px 10px;
        }
        .title {
          font-family: $secondary-font-bold;
          font-size: 18px;
          color: $blue;
          margin-bottom: 20px;
        }
        .field-wrapper {
          margin: 10px 0;
          width: 100%;
        }
        .que-ans-block {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 15px;
          label {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue;
            display: block;
            width: 100%;
            margin-bottom: 5px;
          }
        }
        .select-ans {
          height: 40px;
          border-radius: 20px;
          min-width: 148px;
          line-height: 1;
          display: inline-block;
          border: 2px solid $gray-shade;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $secondary-font-medium;
          font-size: 16px;
          color: $blue;
          cursor: pointer;
          margin-right: 20px;
          &.active {
            background-color: $orange-shade;
            color: $white;
            border-color: $orange-shade;
          }
          @include bp(xs-max) {
            margin-right: 5px;
            min-width: 125px;
          }
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        @include bp(xs-max) {
          flex-wrap: wrap;
        }
        .btn {
          height: 40px;
          font-family: $secondary-font-medium;
          font-size: 16px;
          line-height: 1;
        }
        .left-block {
          @include bp(xs-max) {
            width: 100%;
            margin-bottom: 10px;
          }
          .btn {
            min-width: 100px;
            margin-right: 20px;
          }
        }
      }
    }
  }
  &.checklist-summary {
    .content-block {
      display: flex;
      justify-content: center;
      margin: 60px 0;
      @include bp(xs-max) {
        margin: 30px 0;
      }
    }
    .fixed-item {
      font-family: $secondary-font-bold;
      font-size: 23px;
      color: $blue;
      display: block;
      text-align: center;
      margin-bottom: 80px;
      @include bp(xs-max) {
        margin-bottom: 20px;
      }
    }
    .modal-dialog {
      .modal-body {
        .btn-wrapper {
          justify-content: center;
          .btn {
            margin: 0 10px 5px;
          }
        }
      }
    }
  }
  &.checklist-final {
    .modal-dialog {
      max-width: 1050px;
      .accordion {
        .card-header {
          padding: 9px 33px 10px 21px;
          border-radius: 5px;
          background-color: $grey-shade-14;
          border: none;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          @include bp(s-max) {
            flex-wrap: wrap;
          }
          .left-title {
            display: flex;
            align-items: center;
            @include bp(s-max) {
              width: 100%;
            }
            .image-block {
              max-width: 20px;
              margin-right: 20px;
            }
          }
          .right-title {
            display: flex;
            align-items: center;
            @include bp(s-max) {
              justify-content: flex-end;
              width: 100%;
            }
            .count-block {
              width: 25px;
              height: 25px;
              border-radius: 100%;
              color: $white;
              background-color: $orange-shade;
              font-family: $secondary-font-book;
              font-size: 16px;
              margin-right: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            span {
              font-family: $secondary-font-book;
              font-size: 18px;
              color: $black;
              margin-right: 35px;
            }
          }
        }
        .card {
          border: none;
        }
        .card-body {
          padding: 10px 25px 25px;
          ul {
            li {
              font-family: $secondary-font-book;
              font-size: 16px;
              line-height: 24px;
              padding: 8px 0;
              border-bottom: 1px solid $grey-shade-14;
            }
          }
        }
      }
      .modal-body {
        display: flex;
        @include bp(sm-max) {
          flex-wrap: wrap;
        }
        .left-block {
          flex: calc(100% - 295px);
          max-width: calc(100% - 295px);
          padding-right: 30px;
          @include bp(sm-max) {
            flex: 100%;
            max-width: 100%;
            padding-right: 0;
          }
        }
        .right-block {
          flex: 0 0 295px;
          max-width: 295px;
          @include bp(sm-max) {
            flex: 100%;
            max-width: 100%;
          }
          .block-wrapper {
            display: flex;
            align-items: center;
            border-radius: 10px;
            background-color: $color-grey;
            padding: 15px 35px;
            margin-bottom: 20px;
            .image-block {
              max-width: 40px;
              margin-right: 20px;
            }
            .content-block {
              p {
                font-family: $secondary-font-book;
                font-size: 18px;
                color: $blue;
                line-height: 1;
              }
              span {
                font-family: $secondary-font-medium;
                font-size: 22px;
                color: $color-light-green;
              }
            }
          }
          .optional-block {
            display: flex;
            justify-content: center;
            font-family: $secondary-font-book;
            font-size: 18px;
            color: $blue;
            margin: 10px 0;
          }
          .gray-fill-btn {
            width: 100%;
            border-radius: 10px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
