:root {
  --width: 100vw;
  --full-width: 100vw;

  @media (min-width: 42em) {
    --width: 42rem;
  }
  --magic-number: 0.09719;
  --skew-padding: calc(var(--width) * var(--magic-number));
}

.modal-wrapper {
  display: flex;
  .image-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      z-index: 1;
      position: relative;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      background-color: $color-grey;
      bottom: 0;
      height: 125%;
      transform: rotate(-6deg);
      transform-origin: -22% 90%;
      outline: 1px solid transparent;
      backface-visibility: hidden;
    }
    .dots-wrapper {
      display: flex;
      position: absolute;
      right: 50px;
      bottom: 50px;
      li {
        width: 13px;
        height: 13px;
        border-radius: 100%;
        background-color: $gray-shade-7;
        margin-right: 8px;
        &.active {
          background-color: $color-light-green;
        }
      }
    }
  }
  .content-wrapper {
    padding: 100px 30px 45px;
    .head-title {
      font-size: 32px;
      font-family: $secondary-font-bold;
      color: $blue-shade-2;
      margin-bottom: 20px;
    }
    .content {
      font-family: $secondary-font-book;
      font-size: 18px;
      line-height: 32px;
      color: $blue;
      margin-bottom: 25px;
    }
    .btn-wrapper {
      margin-top: 80px;
    }
    .rounded-fill-btn {
      &.link-btn {
        width: 226px;
        padding: 12px 16px;
        border-radius: 50px;
      }
      &.with-icon {
        display: flex;
        align-items: center;
        width: 150px;
        justify-content: center;
        padding: 0.4rem 4rem;
        width: fit-content;
        img {
          padding-right: 1rem;
        }
      }
    }
  }
}

.verification-modal {
  max-width: 900px;
  .modal-content {
    border-radius: 35px;
  }
}

.signup-success-modal {
  max-width: 582px;
  .modal-content {
    min-width: unset;

    .content-wrapper {
      text-align: center;
      padding: 56px 75px;

      .head-title {
        color: #aadc91;
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      .image-wrap {
        width: 273px;
        height: 223px;
        margin: 27px 47px 30px 51px;
        object-fit: contain;
      }
      .sub-title {
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #22456e;
      }
      .content {
        margin: 11px 0 38px 0;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #22456e;
      }
      .btn-wrapper {
        display: flex;
        justify-content: center;
      }
      .MuiSlider-root.MuiSlider-colorPrimary {
        color: #ff8500;
        .MuiSlider-rail {
          height: 5px;
        }
        .MuiSlider-track {
          height: 5px;
        }
        .MuiSlider-thumb {
          width: 14px;
          height: 14px;
        }
      }
      .PrivateValueLabel-offset-3 {
        left: unset;
        &:before {
          border: solid;
          border-color: #ff8500 transparent;
          border-width: 7px 4px 0 4px;
          content: '';
          left: 45%;
          bottom: -7px;
          position: absolute;
        }
      }

      .PrivateValueLabel-label-5 {
        transform: rotate(0deg);
      }

      .PrivateValueLabel-circle-4 {
        transform: rotate(0deg);
        padding: 11px;
        width: fit-content;
        border-radius: 7px;
      }
    }
  }
}
.change-label-success {
  .PrivateValueLabel-circle-6 {
    width: auto;
    transform: rotate(0deg);
    min-width: 32px;
    padding: 0 8px;
    border-radius: 5px;
    .PrivateValueLabel-label-7 {
      transform: rotate(0deg);
    }
  }

  .MuiSlider-valueLabel {
    left: unset;
    &:before {
      border: solid;
      border-color: #ff8500 transparent;
      border-width: 7px 4px 0 4px;
      content: '';
      left: 40%;
      bottom: -7px;
      position: absolute;
    }
  }
}
.role-select {
  .content-wrapper {
    flex: 0 0 50%;
    max-width: 50%;

    .select-type {
      display: flex;
      justify-content: space-between;
      .rounded-border-btn {
        width: 48%;

        &.active {
          background-color: #aadc91;
          color: #fff;
        }
      }
    }
  }
}
