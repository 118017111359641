//Break points
@mixin bp($point) {
  $xxs-max: "(max-width: 374px)";
  $s-max: "(max-width: 479px)";
  $xs-max: "(max-width: 575px)";
  $sm-max: "(max-width: 767px)";
  $md-max: "(max-width: 991px)";
  $lmd-max: "(max-width: 1023px)";
  $lg-max: "(max-width: 1199px)";
  $xlg-max: "(max-width: 1280px)";
  $xl-max: "(max-width: 1439px)";
  $xxl-max: "(max-width: 1680px)";
  
  $s-min: "(min-width:480px)";
  $xs-min: "(min-width: 576px)";
  $sm-min: "(min-width: 768px)";
  $md-min: "(min-width: 992px)";
  $lmd-min: "(min-width: 1024px)";
  $lg-min: "(min-width: 1200px)";
  $xl-min: "(min-width: 1440px)";
  $xxl-min: "(min-width: 1681px)";
  

  @if $point==xxs-max {
    @media #{$xxs-max} {
      @content;
    }
  } @else if $point==s-max {
      @media #{$s-max} {
        @content;
      }
      } @else if $point==s-min {
        @media #{$s-min} {
          @content;
        }
        } @else if $point==xs-max {
          @media #{$xs-max} {
            @content;
          }
          } @else if $point==xs-min {
            @media #{$xs-min} {
              @content;
            }
            } @else if $point==sm-max {
              @media #{$sm-max} {
                @content;
              }
              } @else if $point==sm-min {
                @media #{$sm-min} {
                  @content;
                }
                } @else if $point==md-max {
                  @media #{$md-max} {
                    @content;
                  }
                  } @else if $point==md-min {
                    @media #{$md-min} {
                      @content;
                    }
                    } @else if $point==lmd-max {
                      @media #{$lmd-max} {
                        @content;
                      }
                      } @else if $point==lmd-min {
                        @media #{$lmd-min} {
                          @content;
                        }
                        }@else if $point==lg-min {
                          @media #{$lg-min} {
                            @content;
                          }
                        }
                        @else if $point==lg-max {
                          @media #{$lg-max} {
                            @content;
                          }
                          } @else if $point==xlg-max {
                            @media #{$xlg-max} {
                              @content;
                            }
                            } @else if $point==xl-max {
                              @media #{$xl-max} {
                                @content;
                              }
                              } @else if $point==xl-min {
                                @media #{$xl-min} {
                                  @content;
                                }
                                } @else if $point==xxl-max {
                                  @media #{$xxl-max} {
                                    @content;
                                  }
                                  } @else if $point==xxl-min {
                                    @media #{$xxl-min} {
                                      @content;
                                    }
                                  }
                                }
