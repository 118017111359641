/*----------------Utility classes-----------------*/
.container-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  width:100%;
}
.flex-middle-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-middle {
  display: flex;
  justify-content: center;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}
.mr-neg-1 {
  margin-right: -20px !important;
}
.mr-neg-2 {
  margin-right: -40px !important;
}
.no-wrap {
  white-space: nowrap;
}
.lightFont {
  font-weight: 400 !important;
}
.opnSans {
  font-family: "Open Sans", sans-serif !important;
}
.Rubik {
  font-family: "Rubik", sans-serif !important;
}
.w-100 {
  width: 100% !important;
}
.w-80 {
  width: calc(80% - 8px);
}
.w-70 {
  width: calc(70% - 8px);
}
.w-60 {
  width: calc(60% - 8px);
}
.w-50 {
  width: calc(50% - 8px);
}
.w-40 {
  width: calc(40% - 8px);
}
.w-30 {
  width: calc(30% - 8px);
}
.w-20 {
  width: calc(20% - 8px);
}
.w-10 {
  width: calc(10% - 8px);
}
// -----------------------Colors

.blue-bg {
  background-color: #003d55;
}
// ------------------------Form Controls
.btn {
  padding: 10px 20px;
  border-radius: 31px;
  font-weight: 600;
}

input {
  outline: none;
}
