.contact-page {
  .orange-fill-btn {
    margin-bottom: 15px;
  }
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    @include bp(xlg-max) {
      justify-content: space-between;
    }
    .contact-card {
      padding: 20px 30px 20px 35px;
      background-color: $white;
      border-radius: 10px;
      width: 295px;
      border-bottom: 13px solid $color-light-green;
      margin-right: 95px;
      margin-bottom: 50px;
      &:nth-child(3n) {
        @include bp(xl-max) {
          margin-right: 0;
        }
        @include bp(sm-max) {
          margin-right: auto;
        }
      }
      @include bp(xxl-max) {
        margin-right: calc(33.33% - 295px);
      }
      @include bp(xlg-max) {
        width: 32%;
        margin-right: 0;
      }
      @include bp(md-max) {
        padding: 20px;
      }
      @include bp(sm-max) {
        width: 48%;
      }
      @include bp(xs-max) {
        width: 295px;
        max-width: 100%;
        margin: 0 auto 50px;
      }
      .profile-block {
        display: flex;
        margin-bottom: 30px;
        .img-block {
          height: 35px;
          width: 35px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 15px;
        }
        .content {
          display: flex;
          flex-direction: column;
          .name {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue;
          }
          .email {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $gray-shade-9;
          }
        }
      }
      .more-info {
        display: flex;
        margin-bottom: 25px;
        .img-block {
          display: inline-block;
          max-width: 15px;
          margin: 3px 20px 0 5px;
          &.phone-icon {
            max-width: 20px;
          }
        }
        .value {
          font-family: $secondary-font-book;
          font-size: 16px;
          color: $blue;
        }
      }
      .btn-wrapper {
        font-family: $secondary-font-book;
        font-size: 16px;
        color: $blue;
        text-align: center;
      }
    }
  }
}

.contact-detail-page {
  .btn-wrapper {
    margin-bottom: 30px;
    @include bp(sm-max) {
      margin-bottom: 20px;
    }
    button {
      height: 40px;
      font-size: 16px;
      font-family: $secondary-font-medium;
      line-height: 1;
      margin-right: 40px;
      padding: 0 30px;
      min-width: 180px;
      @include bp(sm-max) {
        margin-right: 15px;
        margin-bottom: 10px;
      }
      &.gray-border-btn {
        background-color: $white;
      }
    }
  }
  .detail-wrapper {
    background-color: $white;
    border-radius: 10px;
    display: flex;
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
    .left-block {
      flex: 0 0 55%;
      max-width: 55%;
      padding: 10px 20px;
      @include bp(lg-max) {
        flex: 0 0 60%;
        max-width: 60%;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      position: relative;
      .person-name {
        font-family: $secondary-font-bold;
        color: $blue;
        font-size: 16px;
        text-align: center;
        margin-bottom: 15px;
      }
      .upload-profile {
        height: 95px;
        width: 95px;
        margin: 0 auto 5px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .upload-block {
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 28px;
          input {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            opacity: 0;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        background-color: $grey-shade-18;
        right: -10px;
        top: 10px;
        bottom: 50px;
        @include bp(md-max) {
          right: 0;
        }
        @include bp(sm-max) {
          right: 10px;
          bottom: 0;
          left: 10px;
          height: 1px;
          width: calc(100% - 20px);
          top: auto;
        }
      }
      .fill-detail {
        .name-wrapper {
          display: flex;
          justify-content: space-between;
          @include bp(md-max) {
            flex-direction: column;
          }
          .first-name {
            width: calc(45% - 10px);
            @include bp(md-max) {
              width: 100%;
            }
          }
          .last-name {
            width: calc(35% - 10px);
            @include bp(md-max) {
              width: 100%;
            }
          }
          .surname {
            width: calc(20% - 10px);
            @include bp(md-max) {
              width: 100%;
            }
          }
        }
      }
      .form-wrapper {
        label {
          font-family: $secondary-font-book;
          font-size: 16px;
          color: $gray-shade-5;
          margin-bottom: 15px;
        }
        .field-outer-flex {
          @include bp(sm-max) {
            flex-wrap: wrap;
          }
        }
        .field-wrapper {
          margin-bottom: 20px;
          @include bp(sm-max) {
            flex: 0 0 100%;
            max-width: 100%;
          }
          &.city-detail {
            display: flex;
            justify-content: space-between;
            input {
              width: calc(33.33% - 10px);
            }
          }
        }
        .update-password {
          text-align: right;
          display: block;
          font-family: $secondary-font-book;
          font-size: 16px;
          color: $orange-shade;
        }
      }
      .btn-wrapper {
        margin-top: 40px;
      }
    }
    .right-block {
      flex: 0 0 45%;
      max-width: 45%;
      padding: 10px 35px;
      @include bp(lg-max) {
        flex: 0 0 40%;
        max-width: 40%;
        padding: 10px 25px;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .title {
        font-family: $secondary-font-book;
        font-size: 16px;
        color: $gray-shade-5;
        margin-bottom: 30px;
      }
      .orange-fill-btn {
        margin-bottom: 25px;
        background-color: $orange-shade !important;
        color: $white !important;
        min-width: 150px !important;
        padding: 10px 20px !important;
        border-radius: 31px !important;
      }
      .account-detail {
        padding: 10px 25px;
        background-color: $color-grey;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .left-detail {
          display: flex;
          flex-direction: column;
          .label {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $color-light-green;
            line-height: 20px;
          }
          .acount-number {
            font-family: $secondary-font-medium;
            font-size: 18px;
            line-height: 22px;
            color: $blue;
          }
        }
        .right-detail {
          i {
            display: inline-block;
            max-width: 20px;
            margin-left: 30px;
            &.delete-btn {
              max-width: 17px;
            }
          }
        }
      }
    }
  }
  .payment-block {
    display: flex;
    @include bp(md-max) {
      flex-wrap: wrap;
    }
    .account-detail-wrapper {
      flex: 0 0 260px;
      width: 260px;
      @include bp(md-max) {
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
      }
      @include bp(sm-max) {
        flex-wrap: wrap;
      }
      .top-block {
        padding: 20px;
        background-color: $white;
        border-radius: 10px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @include bp(md-max) {
          flex: 0 0 265px;
          max-width: 265px;
          margin-right: 10px;
        }
        @include bp(sm-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-right: 0px;
        }
        .chart-wrapper {
          height: 75px;
          width: 130px;
          margin: 0;
        }
        .left-block {
          .title {
            font-family: $secondary-font-book;
            font-size: 12px;
            color: $gray-shade-3;
          }
          .value {
            font-family: $secondary-font-bold;
            font-size: 22px;
            color: $color-light-green;
          }
        }
      }
      .bottom-block {
        padding: 20px;
        background-color: $white;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        @include bp(md-max) {
          flex: 0 0 calc(100% - 275px);
          max-width: calc(100% - 275px);
        }
        @include bp(sm-max) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .content {
          @include bp(md-max) {
            flex: 0 0 35%;
            max-width: 35%;
            padding-right: 10px;
            &:nth-last-child(2) {
              flex: 0 0 30%;
              max-width: 30%;
              @include bp(sm-max) {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
            @include bp(sm-max) {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
        .title {
          font-family: $secondary-font-medium;
          font-size: 16px;
          color: $gray-shade-5;
          line-height: 1;
          margin-bottom: 10px;
        }
        .value {
          font-family: $secondary-font-medium;
          font-size: 22px;
          color: $blue;
          margin-bottom: 50px;
          @include bp(md-max) {
            font-size: 18px;
            margin-bottom: 20px;
          }
        }
        .sychronize-bank {
          font-family: $secondary-font-medium;
          font-size: 14px;
          color: $orange-shade;
          display: inline-block;
          margin-bottom: 50px;
        }
      }
    }
    .custom-table {
      .red-text {
        font-family: $secondary-font-medium;
        font-size: 18px;
        color: $red;
      }
      .green-text {
        font-family: $secondary-font-medium;
        font-size: 18px;
        color: $color-light-green;
      }
    }
    .transaction-outer {
      flex: calc(100% - 280px);
      width: calc(100% - 280px);
      background-color: $white;
      border-radius: 10px;
      margin-left: 20px;
      @include bp(md-max) {
          margin-top: 20px;
          margin-left: 0;
          flex: 100%;
          width: 100%;
        }
      .form-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 10px 25px;
        @include bp(sm-max) {
          flex-direction: column;
          padding: 10px;
        }
        .field-wrapper {
          margin-bottom: 0;
          @include bp(sm-max) {
              margin-bottom: 10px;
            }
            .react-datepicker-wrapper {
              @include bp(sm-max) {
                width: 100%;
              }
            }
          .search-block {
            min-width: 350px;
            background-image: url("../images/iconSearch.svg");
            background-repeat: no-repeat;
            background-position: center left 10px;
            background-size: 18px;
            padding-left: 40px;
            &::placeholder {
              font-family: $secondary-font-book;
              font-size: 18px;
              color: $gray-shade-9;
            }
            @include bp(sm-max) {
              min-width: 100%;
            }
          }
          &.date-picker {
            input {
              background-image: url("../images/icon-calendar.svg");
              background-repeat: no-repeat;
              background-position: center left 16px;
              padding-left: 50px;
            }
          }
        }
      }
      .list-btn {
        padding: 10px 25px;
        @include bp(sm-max){
          padding: 10px;
        }
        .transaction-btn {
          height: 40px;
          border-radius: 50px;
          background-color: $color-grey;
          padding: 5px 16px;
          margin-right: 15px;
          margin-bottom: 10px;
          font-family: $secondary-font-bold;
          font-size: 16px;
          letter-spacing: 1.43px;
          color: $blue;
          &.active {
            background-color: $orange-shade;
            color: $white;
          }
        }
      }
      table{
        &.custom-table {
          tr {
            .MuiTableCell-root{
              min-width: 130px;
            }
          }
        }
      }
    }
  }
}
