.custom-loader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

.custom-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: $color-orange;
  opacity: 1;
  -webkit-animation: custom-loader 0.6s infinite alternate;
  animation: custom-loader 0.6s infinite alternate;
}
@keyframes custom-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

@-webkit-keyframes custom-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.custom-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.custom-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
