.slider-section {
  margin: 30px 0 60px;
  .container-main {
    display: flex;
    justify-content: space-between;
    @include bp(xs-max) {
      flex-wrap: wrap;
    }
    .slider-wrapper {
      flex: 0 0 calc(100% - 360px);
      width: calc(100% - 360px);
      border: 1px solid $grey-shade-15;
      border-radius: 15px 0 0 15px;
      overflow: hidden;
      height: 567px;
      position: relative;
      @include bp(lg-max) {
        height: 450px;
        flex: 0 0 calc(100% - 300px);
        width: calc(100% - 300px);
      }
      @include bp(sm-max) {
        height: 300px;
        flex: 0 0 calc(100% - 210px);
        width: calc(100% - 210px);
      }
      @include bp(xs-max) {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 20px;
        border-radius: 15px 15px 0 0;
      }
      .owl-item {
        height: 567px;
        @include bp(lg-max) {
          height: 450px;
        }
        @include bp(sm-max) {
          height: 300px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .owl-prev {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        background-image: url(../images/image-left-arrow.png);
        height: 40px;
        width: 40px;
        background-size: 40px;
        font-size: 0;
      }
      .owl-next {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background-image: url(../images/image-right-arrow.png);
        height: 40px;
        width: 40px;
        background-size: 40px;
        font-size: 0;
      }
      .no-img {
        width: 100%;
      }
    }
    .right-block {
      width: 350px;
      @include bp(lg-max) {
        width: 290px;
      }
      @include bp(sm-max) {
        width: 200px;
      }
      @include bp(xs-max) {
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .top-image {
        margin-bottom: 8px;
        border-top-right-radius: 15px;
        overflow: hidden;
        border: 1px solid #e9eaee;
        height: 280px;
        @include bp(lg-max) {
          height: 220px;
        }
        @include bp(sm-max) {
          height: 145px;
        }
        @include bp(xs-max) {
          border-radius: 0 0 15px 15px;
          width: calc(50% - 5px);
          height: 120px;
          img{
            height: 100%;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .bottom-image {
        border-bottom-right-radius: 15px;
        overflow: hidden;
        border: 1px solid #e9eaee;
        height: 280px;
        @include bp(lg-max) {
          height: 220px;
        }
        @include bp(sm-max) {
          height: 145px;
        }
        @include bp(xs-max) {
          border-radius: 0 0 15px 15px;
          width: calc(50% - 5px);
          height: 120px;
          img{
            height: 100%;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.property-detail-wrapper {
  margin-bottom: 150px;
  @include bp(lg-max) {
    margin-bottom: 50px;
  }
  .container-main {
    display: flex;
    justify-content: space-between;
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
    > .left-block {
      flex: 0 0 calc(100% - 470px);
      max-width: calc(100% - 470px);
      @include bp(lg-max) {
        flex: 0 0 calc(100% - 360px);
        max-width: calc(100% - 360px);
      }
      @include bp(md-max) {
        flex: 0 0 calc(100% - 290px);
        max-width: calc(100% - 290px);
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .property-info {
        display: flex;
        align-items: center;
        padding: 20px;
        border: solid 2px $gray-shade;
        border-radius: 10px;
        margin-bottom: 20px;
        @include bp(md-max){
          flex-wrap: wrap;
          padding: 10px;
        }
        .block-detail {
          flex: 0 0 38%;
          max-width: 38%;
          text-align: center;
          border-right: 1px solid $gray-shade;
          @include bp(md-max) {
            flex: 0 0 50%;
            max-width: 50%;
          }
          @include bp(s-max) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 20px;
          }
          &:first-child {
            flex: 0 0 45%;
            max-width: 45%;
            @include bp(md-max) {
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 20px;
              border-right: 0;
            }
          }
          &:last-child {
            flex: 0 0 17%;
            max-width: 17%;
            border: none;
            @include bp(md-max) {
              flex: 0 0 50%;
              max-width: 50%;
            }
            @include bp(s-max) {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
          .title {
            font-family: $secondary-font-bold;
            font-size: 20px;
            color: $blue;
          }
          .sub-title {
            font-family: $secondary-font-book;
            font-size: 18px;
            color: $gray-shade-3;
          }
          .view {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              max-width: 28px;
              margin-right: 15px;
            }
          }
          .like {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              max-width: 20px;
              margin-right: 15px;
            }
          }
        }
      }
      .text-block {
        font-family: $secondary-font-book;
        font-size: 18px;
        line-height: 20px;
        color: $blue-shade-2;
        text-align: justify;
        margin-bottom: 30px;
      }
      .section-title {
        font-family: $secondary-font-bold;
        font-size: 25px;
        color: $blue;
        margin-bottom: 25px;
      }
         
      .travel-time {
        margin-top: 26px;
        display: flex;
        align-items: center;
        .travel-text {
          font-family: Arboria;
          font-size: 25px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #003d55;
        }
        .MuiSwitch-root{
          margin-left: 10px;
          .MuiSwitch-colorSecondary{
            &.Mui-checked {
              color: #aadc91;
              &:hover {
                background-color: rgba(0, 132, 100, 0.16);
              }
              
            }
            
          }
          .MuiSwitch-track {
            background-color: #aadc91;
          }
        }
    }
    }
    > .right-block {
      flex: 0 0 440px;
      max-width: 440px;
      border-radius: 20px;
      border: solid 1px $gray-shade-9;
      @include bp(lg-max) {
        flex: 0 0 340px;
        max-width: 340px;
      }
      @include bp(md-max) {
        flex: 0 0 280px;
        max-width: 280px;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 20px;
      }
      .top-block {
        padding: 45px 45px 25px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $grey-shade-15;
        @include bp(lg-max) {
          padding: 25px 25px 15px;
        }
        .title {
          font-family: $secondary-font-bold;
          color: $gray-shade-5;
          font-size: 18px;
        }
        .price {
          font-family: $secondary-font-bold;
          font-size: 32px;
          color: $color-light-green;
        }
        .image-block {
          max-width: 34px;
        }
      }
      .content-block {
        padding: 20px 0;
        @include bp(md-max) {
          padding: 0;
        }
        .tab-header {
          display: flex;
          margin-bottom: 5px;
          .tab {
            width: 50%;
            padding: 5px;
            text-align: center;
            border-bottom: solid 8px $grey-shade-15;
            font-family: $secondary-font-bold;
            font-size: 18px;
            color: $gray-shade-5;
            @include bp(md-max) {
              font-size: 15px;
              border-bottom: solid 4px $grey-shade-15;
            }
            &.active {
              color: $blue;
              border-color: $color-light-green;
            }
          }
        }
        .tab-content {
          .payment-block {
            padding: 25px 15px;
            background-color: $blue-shade-2;
            @include bp(md-max) {
              padding: 10px 5px;
            }
            .block-detail {
              background-color: $white;
              border-radius: 20px;
              padding: 30px 40px;
              margin-bottom: 20px;
              position: relative;
              @include bp(lg-max) {
                padding: 15px 10px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              .monthly-payment {
                margin-bottom: 10px;
                span {
                  font-family: $secondary-font-bold;
                  font-size: 32px;
                  color: $color-light-green;
                }
                sub {
                  font-family: $secondary-font-book;
                  font-size: 18px;
                  color: $blue;
                  top: -3px;
                  margin-left: 15px;
                }
              }
              .tag-wrapper {
                display: flex;
                flex-wrap: wrap;
                .tag {
                  background-color: rgba(255, 136, 0, 0.2);
                  min-height: 32px;
                  padding: 5px 15px;
                  font-family: $secondary-font-book;
                  font-size: 14px;
                  color: $blue;
                  margin-right: 15px;
                  margin-bottom: 10px;
                  @include bp(md-max) {
                    margin-right: 5px;
                  }
                }
              }
              .info-text {
                font-family: $secondary-font-bold;
                font-size: 18px;
                color: $blue;
              }
              .arrow-icon {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
      .saving-block {
        .tiny-text {
          text-align: center;
          font-family: $secondary-font-book;
          font-size: 18px;
          color: $gray-shade-5;
          margin-bottom: 10px;
        }
        .saving-price {
          text-align: center;
          font-family: $secondary-font-bold;
          font-size: 34px;
          color: $blue;
          margin-bottom: 30px;
        }
      }
      .btn-wrapper {
        button {
          width: 90%;
          margin: 0 5% 15px;
          &.rounded-fill-btn {
            height: 45px;
          }
          &.gray-fill-btn {
            height: 36px;
            line-height: 1;
            margin-bottom: 25px;
          }
        }
      }
      .note-block {
        font-family: $secondary-font-book;
        font-size: 16px;
        text-align: center;
        color: $blue;
        margin-bottom: 25px;
      }
    }
  }
}
.similar-properties {
  padding: 80px 0;
  .title {
    font-family: $secondary-font-bold;
    color: $color-light-green;
    font-size: 32px;
    text-align: center;
    position: relative;
    margin-bottom: 75px;
    &::after {
      content: "";
      position: absolute;
      width: 80px;
      height: 2px;
      background-color: $orange-shade;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .owl-item {
    margin-bottom: 10px;
  }
  .property-block {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);
    background-color: $white;
    position: relative;
    .top-block {
      position: absolute;
      top: 32px;
      left: 16px;
      right: 16px;
      display: flex;
      z-index: 1;
      justify-content: space-between;
      .profile-block {
        display: flex;
        align-items: center;
        i {
          display: inline-block;
          height: 30px;
          width: 30px;
          border-radius: 100%;
          margin-right: 25px;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        span {
          font-family: $secondary-font-book;
          font-size: 16px;
          color: $white;
        }
      }
      .like-block {
        img {
          max-width: 32px;
        }
      }
    }
    .property-image {
      height: 200px;
      width: 100%;
      display: inline-block;
      position: relative;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .like-view-block {
      border-radius: 6px;
      border: solid 1px $gray-shade-4;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      padding: 21px 14px 21px;
      margin: -30px 16px 20px;
      z-index: 1;
      position: relative;
      @include bp(sm-max) {
        margin: -30px 6px 20px;
      }
      .left-block {
        flex: 0 0 50%;
        max-width: 50%;
        .lable {
          font-family: $secondary-font-book;
          font-size: 14px;
        }
        .price {
          font-family: $secondary-font-bold;
          font-size: 22px;
          color: $color-light-green;
          @include bp(sm-max) {
            font-size: 18px;
          }
        }
      }
      .right-block {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: right;
        i {
          margin-right: 5px;
          display: inline-block;
        }
        span {
          font-family: $secondary-font-bold;
          font-size: 18px;
          color: $blue;
          min-width: 50px;
          @include bp(sm-max) {
            font-size: 14px;
          }
        }
        .view {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          i {
            max-width: 28px;
          }
        }
        .like {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          i {
            max-width: 20px;
          }
        }
      }
    }
    .more-detail {
      padding: 0 16px;
      padding-bottom: 10px;
      .property-name {
        font-family: $secondary-font-bold;
        font-size: 22px;
        color: $blue;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .property-address {
        font-family: $secondary-font-book;
        font-size: 17px;
        color: $gray-shade-5;
        margin-bottom: 25px;
      }
      ul {
        display: flex;
        margin-bottom: 30px;
        li {
          flex: 0 0 33.33%;
          max-width: 33.33%;
          position: relative;
          font-family: $secondary-font-book;
          font-size: 17px;
          color: $blue;
          @include bp(sm-max) {
            font-size: 14px;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            position: absolute;
            height: 9px;
            width: 1px;
            top: 8px;
            right: 25px;
            background-color: $gray-shade-6;
            @include bp(sm-max) {
              right: 15px;
            }
          }
        }
      }
    }
  }
}
.price-section {
  padding: 60px 0 90px;
  background-color: rgba(170, 220, 145, 0.2);
  @include bp(lg-max) {
    padding: 50px 0;
  }
  .container-main {
    display: flex;
    justify-content: space-between;
    @include bp(sm-max){
      flex-wrap: wrap;
    }
  }
  .left-block {
    flex: 0 0 calc(100% - 470px);
    max-width: calc(100% - 470px);
    @include bp(lg-max) {
      flex: 0 0 calc(100% - 360px);
      max-width: calc(100% - 360px);
    }
    @include bp(md-max) {
      flex: 0 0 calc(100% - 310px);
      max-width: calc(100% - 310px);
    }
    @include bp(sm-max){
      flex: 0 0 100%;
      max-width: 100%;
    }
    .title {
      font-family: $secondary-font-bold;
      color: $blue-shade-2;
      text-align: center;
      font-size: 38px;
      position: relative;
      margin-bottom: 50px;
      &:after {
        content: "";
        position: absolute;
        width: 90px;
        height: 6px;
        background-color: $orange-shade;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .tab-outer {
      .tab-header {
        display: flex;
        justify-content: space-between;
        .tab {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(50% - 10px);
          background-color: $white;
          min-height: 50px;
          border: solid 2px $gray-shade;
          border-bottom-color: $color-light-green;
          position: relative;
          margin-bottom: 35px;
          @include bp(s-max){
            width: calc(50% - 5px);
            padding: 0 6px;
          }
          &:after {
            content: "";
            height: 2px;
            width: 24px;
            background-color: $color-light-green;
            position: absolute;
            bottom: -2px;
            right: -25px;
            z-index: 1;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          .image-block {
            display: inline-block;
            max-width: 28px;
            margin-right: 10px;
          }
          &.active {
            border-color: $color-light-green;
            border-bottom-color: transparent;
          }
        }
      }
      .tab-content {
        .block-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-family: $secondary-font-bold;
            font-size: 18px;
            color: $blue;
            margin-bottom: 20px;
          }
          .display-price {
            background-color: $white;
            height: 37px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            font-family: $secondary-font-book;
            font-size: 16px;
            color: $blue;
            padding-left: 30px;
            border-radius: 6px;
            min-width: 110px;
            &:before {
              content: "$";
              position: absolute;
              left: 8px;
              font-family: $secondary-font-book;
              font-size: 16px;
              color: $gray-shade-3;
            }
          }
        }
        .term-wrapper {
          margin-bottom: 40px;
          @include bp(lg-max) {
            margin-bottom: 20px;
          }
          .term-btn {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include bp(s-max){
              justify-content: center;
            }
            span {
              width: 150px;
              height: 52px;
              border-radius: 50px;
              border: solid 2px $gray-shade;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 45px;
              font-family: $secondary-font-medium;
              font-size: 20px;
              line-height: 1.3;
              cursor: pointer;
              color: $blue;
              @include bp(md-max){
                width: 120px;
                height: 45px;
                margin-right: 5px;
                font-size: 18px;
              }
              @include bp(s-max){
                width: 140px;
                margin-bottom: 10px;
              }
              &:last-child {
                margin-right: 0;
              }
              &.active {
                background-color: $orange-shade;
                border-color: $orange-shade;
                font-size: 20px;
                line-height: 1.3;
                color: $white;
              }
            }
          }
        }
        .slider-wrapper {
          .MuiSlider-markLabel {
            display: none;
          }
          .MuiSlider-root {
            @include bp(lg-max) {
              margin-bottom: 20px;
            }
          }
        }
        .maintenance-wrapper {
          padding: 20px;
          border-radius: 10px;
          background-color: $color-light-green;
          @include bp(md-max){
            padding: 10px;
          }
          .block-title {
            text-align: center;
            color: $white;
            font-family: $secondary-font-bold;
            font-size: 22px;
            margin-bottom: 15px;
          }
          .maintenance-block {
            display: flex;
            justify-content: center;
            @include bp(s-max){
              flex-wrap: wrap;
            }
          }
          .content-block {
            width: 257px;
            margin-right: 25px;
            background: $white;
            padding: 20px;
            border-radius: 10px;
            display: flex;
            position: relative;
            overflow: hidden;
            @include bp(md-max){
              margin-right: 5px;
              padding: 10px;
            }
            @include bp(s-max){
              width: 100%;
              margin-bottom: 10px;
            }
            em {
              font-size: 20px;
              position: absolute;
              color: $white;
              right: 5px;
              top: -3px;
              z-index: 1;
              font-weight: 600;
            }
            &:after {
              content: "";
              height: 0;
              width: 0;
              border: 20px solid $orange-shade;
              border-bottom-color: transparent;
              border-left-color: transparent;
              position: absolute;
              top: 0;
              right: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            img {
              min-width: 74px;
              width: 74px;
              margin-right: 15px;
              @include bp(md-max){
                height: 55px;
                width: 55px;
                min-width: 55px;
              }
            }
            .content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding-right: 20px;
              .label {
                font-family: $secondary-font-bold;
                font-size: 16px;
                color: $blue;
                line-height: 1;
              }
              .opation {
                font-family: $secondary-font-bold;
                font-size: 16px;
                color: $blue-shade-3;
              }
            }
          }
        }
        .pay-cash-wrap {
          background: #ffffff;
          padding: 4em 5em;
          margin-bottom: 3.1875em;
           
          .slider-wrapper {
            margin: 0;
                   
            .MuiSlider-root {
                margin-bottom: 0;
            }
          }

          .pay-cash {
            margin-top: 1em;
        
            .right-block-cash {
              text-align: right;
            }
        
            .title-c {
                font-family: "Arboria-Book";
                font-size: 16px;
                color: #a1abbd;
                margin-right: 10px;
                .p-highlight {
                  color: #ff8500;
                  font-weight: bolder;
                }
            }
            .value{
              font-weight: 600;
              &.v-highlight {
                background: #cfcacd;
                padding: 5px 7px;
                border-radius: 4px;
              }
            }
            
            
        
           }


        }
      }
    }
  }
  .right-block {
    flex: 0 0 440px;
    max-width: 440px;
    @include bp(lg-max) {
      flex: 0 0 340px;
      max-width: 340px;
    }
    @include bp(md-max) {
      flex: 0 0 300px;
      max-width: 300px;
    }
    @include bp(sm-max){
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 20px;
    }
    .top-block {
      padding: 35px 35px 40px;
      background-color: $color-light-green;
      border-radius: 10px 10px 0 0;
      @include bp(md-max) {
        padding: 25px;
      }
      p {
        font-family: $secondary-font-bold;
        font-size: 20px;
        color: $white;
        text-align: center;
      }
      em {
        font-family: $secondary-font-bold;
        font-size: 35px;
        color: $blue-shade-2;
        font-style: normal;
        text-align: center;
        display: block;
      }
    }
    hr {
      border: 1px solid $gray-shade;
      height: 0;
      margin: 10px 0 20px;
    }
    .content-block {
      padding: 30px;
      @include bp(md-max) {
        padding: 20px 10px;
      }
      background-color: $white;
      border-radius: 0 0 10px 10px;
      border: solid 2px $gray-shade;
      .content {
        display: flex;
        margin-bottom: 10px;
        .title {
          flex: 0 0 50%;
          max-width: 50%;
          font-family: $secondary-font-book;
          font-size: 18px;
          color: $blue-shade-3;
        }
        .value {
          flex: 0 0 50%;
          max-width: 50%;
          font-family: $secondary-font-bold;
          font-size: 18px;
          color: $blue-shade-2;
          text-align: right;
        }
      }
      button {
        margin: 10px 0 0;
        width: 100%;
      }
    }
  }
}
.property-slider-modal {
  .modal-lg {
    max-width: 100%;
  }
  .modal-content {
    width: 100%;
    margin: 0;
    background-color: transparent;
    border: none;
  }
  #sync1 {
    width: 1200px;
    max-width: 90%;
    margin: 10px auto 50px;
    height: 60vh;
    @include bp(sm-max){
      margin: 10px auto 20px;
    }
    .owl-nav {
      display: none;
    }
    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
      .owl-item {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  #sync2 {
    height: 200px;
    @include bp(sm-max){
      height: 120px;
    }
    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 200px;
      .owl-item {
        img {
          height: 200px;
          width: 100%;
          object-fit: cover;
          @include bp(sm-max){
            height: 120px;
          }
        }
      }
    }
    .owl-prev {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      background-image: url(../images/image-left-arrow.png);
      height: 40px;
      width: 40px;
      background-size: 40px;
      font-size: 0;
    }
    .owl-next {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      background-image: url(../images/image-right-arrow.png);
      height: 40px;
      width: 40px;
      background-size: 40px;
      font-size: 0;
    }
  }
  .modal-header {
    border: none;
    .close {
      font-weight: 400;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.69;
      background: #000;
      border: 0 none !important;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      height: 46px;
      -webkit-transition: background 0.2s ease-in-out;
      transition: background 0.2s ease-in-out;
      width: 46px;
      border-radius: 50%;
      margin-right: 16px;
      padding: 10px;
      @include bp(md-max){
        margin-right: 0;
      }
      span{
        margin-top: -8px;
      }
    }
  }
}


.coming-soon {
  .modal-header {
    border: none;
  }
 .modal-body {
    width: 100%;
    .modal-wrapper {
      width: 100%;
      display: block;
      .content-wrapper {
        padding: 45px 30px;
        text-align: center;
        .image-wrap {
          justify-content: center;
          display: flex;
          margin-bottom: 1em;
        }
        .sub-title {
          text-align: center;
          display: block;
        }
      }
    }
  }
}