/*----------------Utility classes-----------------*/

.login-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  @include bp(sm-max) {
    align-items: center;
    justify-content: center;
    background-image: url(../images/siginImage.png);
    &:after {
      content: '';
      background: rgba(0, 0, 0, 0.5);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
    }
  }
  .left-block {
    width: 565px;
    border-left: 16px solid $color-light-green;
    padding: 50px 50px 50px 66px;
    background-color: $white;
    z-index: 1;
    position: relative;
    @include bp(lg-max) {
      width: 450px;
      padding: 50px 20px;
    }
    @include bp(md-max) {
      width: 370px;
      padding: 30px 20px;
    }
    @include bp(sm-max) {
      width: 100%;
      margin: 50px;
      border-left: 0;
      border-radius: 10px;
    }
    @include bp(xs-max) {
      margin: 50px 15px;
    }
  }
  .right-block {
    width: calc(100% - 565px);
    @include bp(lg-max) {
      width: calc(100% - 450px);
    }
    @include bp(md-max) {
      width: calc(100% - 370px);
    }
    @include bp(sm-max) {
      display: none;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.logo-block {
  max-width: 170px;
  display: inline-block;
  margin-bottom: 85px;
  @include bp(lg-max) {
    margin-bottom: 15px;
  }
}
.prelogin-form {
  .title {
    color: $blue;
    font-size: 32px;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  .subtitle {
    font-size: 18px;
    margin-bottom: 50px;
    @include bp(lg-max) {
      margin-bottom: 15px;
    }
    .link-text {
      color: $color-light-green;
      margin-left: 5px;
      font-family: $primary-font;
      font-weight: 500;
    }
  }
  .btn-wrapper {
    margin-top: 78px;
    text-align: center;
    @include bp(lg-max) {
      margin-top: 25px;
    }
    .rounded-fill-btn {
      width: 100%;
      margin-bottom: 30px;
    }
    .forgot-password {
      color: $color-orange;
      font-family: $primary-font;
      font-weight: 500;
    }
  }
}
.sign-up-form {
  .subtitle {
    margin-bottom: 25px;
  }
  .select-type {
    display: flex;
    justify-content: space-between;
    .rounded-border-btn {
      width: calc(50% - 5px);
      &.active {
        background-color: $color-light-green;
        color: $white;
      }
    }
  }
  .btn-wrapper {
    margin-top: 45px;
  }
}

.signup-hr {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: $secondary-font-book;
  color: $gray-shade;
  &:before {
    content: '';
    left: 0;
    width: calc(50% - 30px);
    position: absolute;
    height: 1px;
    background-color: $gray-shade;
  }
  &:after {
    content: '';
    right: 0;
    width: calc(50% - 30px);
    position: absolute;
    height: 1px;
    background-color: $gray-shade;
  }
}
.thick-line {
  border-bottom: 5px solid $gray-shade;
  border-radius: 50px;
  margin: 10px 0;
}

.social-media-wrapper {
  .social-media-icon {
    position: relative;
    background: $color-grey;
    border-radius: 31px;
    padding: 0px 20px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    min-height: 40px;
    border-radius: 20px;
    width: 100%;
    color: $blue;
    background-repeat: no-repeat;
    background-position: center left 50px;
    border-color: $color-grey;
    font-weight: normal;
    &.apple-icon {
      background-image: url('../images/applelogo.png');
    }
    &.google-icon {
      background-image: url('../images/googlelogo.png');
    }
    &.facebook-icon {
      background-image: url('../images/facebooklogo.png');
    }
    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          color: #fff;
          background-color: $color-light-green;
          border-color: $color-light-green;
          box-shadow: 0 0 0 0.2rem $color-light-green;
        }
      }
    }
    @include bp(xs-max) {
      background-position: center left 20px;
    }
  }
}

.btn-sigin-social-media {
  width: 100%;
  padding: 0px 10px;
  color: $color-light-green;
  font-weight: 500;
  border-radius: 25px;
  padding: 10px 0;
}
