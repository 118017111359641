.seller-property-wrapper {
  .btn-wrapper {
    margin-bottom: 30px;
    @include bp(sm-max) {
      margin-bottom: 20px;
    }
    button {
      height: 40px;
      font-size: 16px;
      font-family: 'Arboria';
      line-height: 1;
      margin-right: 40px;
      padding: 0 30px;
      min-width: 180px;
      @include bp(sm-max) {
        margin-right: 15px;
        margin-bottom: 10px;
      }
      &.gray-border-btn {
        background-color: $white;
      }
    }
  }
  .content-wrapper {
    border-radius: 10px;
    background-color: $white;
    // padding: 30px 0;
    @include bp(sm-max) {
      padding: 20px;
    }
    .search-wrapper {
      display: flex;
      justify-content: end;
      position: relative;
      padding: 1.3em 2em 1em;
      .field-wrapper {
        min-width: 70%;
        @include bp(sm-max) {
          min-width: auto;
          width: calc(100% - 110px);
        }
      }
      .search-block {
        min-width: 100%;
        background-image: url('../images/iconSearch.svg');
        background-repeat: no-repeat;
        background-position: center left 10px;
        background-size: 18px;
        padding-left: 40px;
        &::placeholder {
          font-family: $secondary-font-book;
          font-size: 18px;
          color: $gray-shade-9;
        }
      }
      .sort-btn {
        height: 46px;
        min-width: 115px;
        border-radius: 23px;
        background-color: $blue-shade-3;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        @include bp(sm-max) {
          height: 40px;
          min-width: 100px;
        }
        span {
          margin-right: 10px;
        }
      }
      .sort-content {
        position: absolute;
        right: 0;
        top: 46px;
        width: 205px;
        .inner-links {
          border-radius: 20px;
          box-shadow: 0 10px 34px 0 rgba(0, 0, 0, 0.17);
          background-color: $color-grey;
          padding: 8px 0;
          .sort-item {
            border-bottom: 1px solid $gray-shade;
            font-size: 14px;
            color: $blue;
            font-family: $secondary-font-medium;
            &:last-child {
              border-bottom: none;
            }
            button {
              padding: 6px 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              .value {
                font-size: 10px;
                &.active {
                  color: $color-light-green;
                }
              }
            }
            .sub-sort-list {
              padding: 0 1em 1em 1em;
              .sub-sort-list-item {
                padding: 0.4rem 1rem;
                &:first-child {
                  border-bottom: 1px solid #e2e2e2;
                }
                &:last-child {
                  margin-top: 0.4rem;
                }
                &:hover {
                  background-color: rgba(170, 220, 145, 0.16);
                  border-radius: 17px;
                  border: none;
                }
                &.active {
                  background-color: rgba(170, 220, 145, 0.16);
                  border-radius: 17px;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
    .list-outer {
      max-height: calc(100vh - 17.8em);
      overflow: auto;
      padding: 1.3em 2em 1em;
      scrollbar-width: none;
      .list-block {
        display: flex;
        justify-content: space-between;
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid $grey-shade-20;
        @include bp(sm-max) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        &:last-child {
          border-bottom: none;
          @include bp(sm-max) {
            margin-bottom: 0;
            padding: 0;
          }
        }
        .property-detail {
          display: flex;
          // max-width: 35%;
          width: 35%;
          @include bp(xlg-max) {
            max-width: 34%;
            width: 34%;
          }
          @include bp(md-max) {
            align-items: center;
            max-width: 40%;
            width: 40%;
          }
          @include bp(sm-max) {
            max-width: 100%;
            width: 100%;
            margin-bottom: 15px;
            flex-wrap: wrap;
            align-items: flex-start;
          }
        }
        .image-block {
          height: 80px;
          width: 80px;
          min-width: 80px;
          border-radius: 10px;
          overflow: hidden;
          margin-right: 15px;
          @include bp(sm-max) {
            margin-bottom: 10px;
          }
          img {
            height: 100%;
            width: 100%;
            overflow: hidden;
            object-fit: cover;
          }
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 25px;
          .title {
            font-family: $secondary-font-medium;
            font-size: 18px;
            color: $blue;
            line-height: 1;
            margin-bottom: 5px;
          }
          .sub-title {
            font-family: $secondary-font-book;
            color: $gray-shade-5;
            line-height: 1;
          }
          .tab {
            display: inline-flex;
            height: 23px;
            align-items: center;
            justify-content: center;
            min-width: 95px;
            background-color: $color-light-green;
            border-radius: 2px;
            font-family: $secondary-font-medium;
            color: $white;
            margin-top: 15px;
          }
        }
        .tab-wrapper {
          display: flex;
          align-items: center;
          width: 60%;
          justify-content: center;
          @include bp(md-max) {
            flex-direction: column;
          }
          @include bp(sm-max) {
            flex-direction: row;
            margin-bottom: 5px;
            max-width: 100%;
            width: 100%;
            flex-wrap: wrap;
          }
          .tab-block {
            height: 46px;
            border-radius: 13px;
            background-color: $grey-shade-15;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-family: $secondary-font-medium;
            color: $blue;
            min-width: 124px;
            padding: 5px 15px;
            margin-right: 25px;
            text-align: center;
            @include bp(xlg-max) {
              padding: 5px 10px;
              min-width: 114px;
              margin-right: 15px;
            }
            @include bp(md-max) {
              height: 38px;
              font-size: 14px;
              margin-right: 0;
              margin-bottom: 6px;
              border-radius: 8px;
            }
            @include bp(sm-max) {
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .like-view-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          margin-bottom: 15px;
          width: 5%;
          @include bp(sm-max) {
            align-items: flex-start;
            flex-direction: row;
            justify-content: flex-start;
            max-width: 100%;
            width: 100%;
          }
          .like-property {
            display: flex;
            align-items: center;
            @include bp(sm-max) {
              margin-right: 15px;
            }
            img {
              max-width: 20px;
              margin-right: 10px;
            }
          }
          .view-property {
            display: flex;
            align-items: center;
            img {
              max-width: 20px;
              margin-right: 10px;
            }
          }
        }
        .edit-icon {
          display: flex;
          align-items: center;
          width: 6em;
          justify-content: center;
          @include bp(sm -max) {
            margin-right: 15px;
            width: auto;
          }
          img {
            max-width: 26px;
          }
        }
        .delete-icon {
          display: flex;
          align-items: center;
          img {
            max-width: 21px;
          }
        }
      }
    }
  }
}
.financial-wrapper {
  padding: 35px;
  border-radius: 10px;
  background-color: $white;
  min-height: calc(100% - 150px);
  @include bp(sm-max) {
    min-height: auto;
    padding: 20px;
  }
  @include bp(xs-max) {
    padding: 10px;
  }
  .record {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include bp(md-max) {
      flex-wrap: wrap;
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px;
    }
    @include bp(md-max) {
      border-bottom: 1px solid $gray-shade-9;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      @include bp(md-max) {
        border-bottom: none;
      }
    }
    .block-wrapper {
      padding-right: 20px;
      flex: 0 0 19%;
      max-width: 19%;
      @include bp(xl-max) {
        flex: 0 0 17%;
        max-width: 17%;
        padding-right: 10px;
      }
      @include bp(md-max) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 15px;
      }
      @include bp(xs-max) {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
      }
      .title {
        font-family: $secondary-font-medium;
        font-size: 16px;
        color: $blue-shade-3;
        @include bp(xl-max) {
          font-size: 14px;
        }
        @include bp(xs-max) {
          margin-right: 15px;
        }
      }
      .value {
        font-family: $secondary-font-medium;
        font-size: 20px;
        color: $blue;
        @include bp(xl-max) {
          font-size: 16px;
        }
        &.green-text {
          color: $color-light-green;
        }
      }
      &.btn-wrapper {
        margin-bottom: 0px;
        padding-right: 0;
        flex: 0 0 24%;
        max-width: 24%;
        @include bp(xl-max) {
          flex: 0 0 28%;
          max-width: 28%;
        }
        @include bp(md-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 0;
        }
        button {
          min-width: 130px;
          width: 130px;
          padding: 5px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          margin-bottom: 10px;
          @include bp(xl-max) {
            min-width: 110px;
            width: 110px;
            font-size: 14px;
            margin-right: 10px;
          }
          &.rounded-fill-btn {
            &:hover {
              background-color: $color-light-green;
              color: $white;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          img {
            margin-right: 10px;
            @include bp(xl-max) {
              margin-right: 7px;
            }
          }
        }
        .download-btn {
          width: 30px;
          min-width: 30px;
          margin-left: 20px;
          img {
            max-width: 20px;
            margin: 0;
          }
        }
      }
    }
  }
}
.seller-dashboard {
  .custom-table-wrapper {
    margin-bottom: 20px;
    .top-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px 20px;
      @include bp(sm-max) {
        padding: 0 10px 20px;
      }
      .title {
        font-family: $secondary-font-bold;
        font-size: 16px;
        color: $blue;
      }
    }
    padding: 20px 0 50px;
    border-radius: 10px;
    background-color: $white;
    .catagory-tag {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 88px;
      height: 30px;
      border-radius: 15px;
      background-color: $grey-shade-15;
    }
  }
  .custom-table {
    .red-text {
      font-family: $secondary-font-medium;
      font-size: 18px;
      color: $red;
    }
    .green-text {
      font-family: $secondary-font-medium;
      font-size: 18px;
      color: $color-light-green;
    }
    .image-block {
      img {
        max-width: 20px;
      }
    }
    tr {
      .image-block {
        &.MuiTableCell-root {
          min-width: 50px;
          width: 50px;
        }
      }
      .MuiTableCell-root {
        min-width: 130px;
      }
    }
  }
  .bar-chart-wrapper {
    .top-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      @include bp(xs-max) {
        flex-wrap: wrap;
      }
      .title {
        font-family: $secondary-font-bold;
        font-size: 16px;
        color: $blue;
        @include bp(xs-max) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    border-radius: 10px;
    background-color: $white;
    padding: 20px;
    margin-bottom: 30px;
    .container {
      @include bp(xs-max) {
        padding: 0;
      }
    }
    canvas {
      min-height: 350px;
      @include bp(xs-max) {
        min-height: 280px;
        height: 280px !important;
      }
      @include bp(xs-max) {
        min-height: 250px;
        height: 250px !important;
      }
    }
  }
  .page-title {
    font-family: $secondary-font-medium;
    font-size: 20px;
    color: $blue;
    margin-bottom: 20px;
  }
  .welcome-section {
    background-color: $blue;
    padding: 30px 35px;
    border-radius: 10px;
    margin-bottom: 20px;
    @include bp(sm-max) {
      padding: 15px;
    }
    h2 {
      font-family: $secondary-font-bold;
      font-size: 22px;
      color: $white;
      margin-bottom: 10px;
    }
    .left-block {
      display: flex;
      align-items: flex-start;
      @include bp(sm-max) {
        flex-wrap: wrap;
      }
      p {
        font-family: $secondary-font-book;
        font-size: 14px;
        line-height: 1.8;
        color: $white;
        padding-right: 80px;
        @include bp(lg-max) {
          padding-right: 20px;
        }
        @include bp(sm-max) {
          width: 100%;
          margin-bottom: 20px;
          padding-right: 0;
          line-height: 1.5;
        }
      }
    }
  }
  .bottom-block {
    display: flex;
    justify-content: space-between;
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
    .head-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .title {
        font-family: $secondary-font-bold;
        font-size: 16px;
        color: $blue;
      }
    }
    .left-block {
      flex: 0 0 calc(55% - 15px);
      max-width: calc(55% - 15px);
      background-color: $white;
      padding: 20px;
      border-radius: 10px;
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
      .content-wrapper {
        .content-block {
          border-bottom: solid 1px $grey-shade-21;
          margin-bottom: 20px;
          &:last-child {
            border: none;
          }
          .top-block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            @include bp(s-max) {
              flex-wrap: wrap;
            }
            .block-detail {
              display: flex;
              @include bp(s-max) {
                width: 100%;
              }
              img {
                height: 40px;
                width: 40px;
                margin-right: 20px;
                @include bp(s-max) {
                  height: 50px;
                  width: 50px;
                }
              }
              .name-block {
                @include bp(s-max) {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }
                span {
                  font-family: $secondary-font-medium;
                  font-size: 18px;
                  color: $color-light-green;
                  line-height: 1;
                }
                p {
                  font-family: $secondary-font-medium;
                  font-size: 16px;
                  line-height: 1;
                  color: $blue;
                  @include bp(s-max) {
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .time-block {
              font-family: $secondary-font-medium;
              font-size: 16px;
              color: $gray-shade-5;
              @include bp(s-max) {
                margin-left: 70px;
                margin-top: 5px;
              }
            }
            .link-block {
              font-family: $secondary-font-medium;
              color: $green;
            }
          }
          .info {
            font-family: $secondary-font-book;
            color: $gray-shade-5;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .right-block {
      flex: 0 0 calc(45% - 15px);
      max-width: calc(45% - 15px);
      background-color: $white;
      padding: 20px;
      border-radius: 10px;
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .content-wrapper {
        .content-block {
          border-bottom: solid 1px $grey-shade-21;
          margin-bottom: 20px;
          &:last-child {
            border: none;
          }
          .top-block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            @include bp(s-max) {
              flex-wrap: wrap;
            }
            .block-detail {
              display: flex;
              align-items: center;
              @include bp(s-max) {
                width: 100%;
              }
              img {
                height: 56px;
                width: 56px;
                margin-right: 20px;
              }
              .name-block {
                span {
                  font-family: $secondary-font-bold;
                  font-size: 18px;
                  color: $blue;
                  line-height: 1;
                  margin-bottom: 8px;
                  display: block;
                }
                p {
                  font-family: $secondary-font-book;
                  font-size: 16px;
                  line-height: 1;
                  color: $gray-shade-3;
                }
              }
            }
            .link-block {
              font-family: $secondary-font-medium;
              color: $green;
              @include bp(s-max) {
                margin-left: 75px;
              }
            }
          }
        }
      }
    }
  }
}
.chart-block-outer {
  margin: 0 -10px 30px;
  display: flex;
  justify-content: space-between;
  @include bp(sm-max) {
    flex-wrap: wrap;
    margin: 0;
  }
  .block-wrapper {
    background-color: $white;
    border-radius: 10px;
    padding: 15px;
    flex: 0 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    margin: 0 10px;
    display: flex;
    @include bp(xlg-max) {
      flex: 0 0 calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
      margin: 0 5px;
      padding: 15px 10px;
    }
    @include bp(md-max) {
      flex-wrap: wrap;
    }
    @include bp(sm-max) {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0 0 20px;
    }
    .left-block {
      width: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      @include bp(md-max) {
        width: 100%;
        margin-bottom: 15px;
      }
      @include bp(sm-max) {
        width: calc(100% - 100px);
      }
      .label {
        font-family: $secondary-font-medium;
        font-size: 14px;
        color: $gray-shade-3;
      }
      .value {
        font-family: $secondary-font-bold;
        font-size: 24px;
        color: $color-light-green;
        line-height: 1;
      }
      .link-wrapper {
        display: flex;
        align-items: center;
        margin-top: auto;
        font-family: $secondary-font-medium;
        font-size: 16px;
        color: $blue;
        @include bp(sm-max) {
          font-size: 14px;
        }
        img {
          width: 20px;
          margin-left: 10px;
        }
      }
    }
    .chart-wrapper {
      width: 100px;
      canvas {
        margin-top: -10px;
      }
      .content {
        margin: 0;
      }
    }
  }
  .rented-property-block {
    background-color: $white;
    border-radius: 10px;
    padding: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
