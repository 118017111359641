.modal-lg {
  max-width: 880px;
}
.modal-body {
  padding: 0;
}
.modal-content {
  width: 880px;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 20px;
  @include bp(xs-max) {
    margin: 0 10px;
  }
}
.modal-content-wrapper {
  display: flex;
  align-items: center;
  .image-block {
    flex: 0 0 45%;
    max-width: 45%;
    background-color: $color-grey;
    padding: 65px;
    min-height: 510px;
    display: flex;
    align-items: center;
    @include bp(md-max) {
      min-height: 400px;
    }
    @include bp(xs-max) {
      display: none;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .content-block {
    flex: 0 0 55%;
    max-width: 55%;
    padding: 60px;
    @include bp(md-max) {
      padding: 20px;
    }
    @include bp(xs-max) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 50px 20px;
      position: relative;
      z-index: 1;
      &:after {
        content: "";
        background-image: url(../images/forgotPwd.png);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        position: absolute;
        background-size: 40%;
        background-position: bottom right;
        opacity: 0.5;
        z-index: -1;
        background-repeat: no-repeat;
      }
    }
    h3 {
      font-family: $secondary-font-bold;
      font-size: 22px;
      color: $blue;
      letter-spacing: 1.96px;
      margin-bottom: 18px;
    }
    .discription-text {
      font-family: $secondary-font-book;
      font-size: 16px;
      color: $gray-shade-3;
      margin-bottom: 60px;
      @include bp(md-max) {
        margin-bottom: 30px;
      }
    }
    .btn-wrapper {
      button {
        margin-right: 20px;
        min-width: 100px;
      }
    }
    .field-wrapper {
      margin-bottom: 80px;
      @include bp(md-max) {
        margin-bottom: 50px;
      }
      &.no-margin {
        margin-bottom: 10px;
      }
    }
    .icon-wrapper {
      display: inline-block;
      max-width: 70px;
      margin-bottom: 45px;
    }
  }
}
// .container-forget {
//   max-width: 1500px;
//   margin: 0 auto;
//   padding: 0;
// }

// .fg-bg-image {
//   background-color: $color-grey;
//   img {
//     padding: 35% 15%;
//     height: 100%;
//     width: 100%;
//   }
// }

// .modal-body {
//   padding: 0px;
// }

// .fg-text-contain {
//   padding: 0 30px;
// }

// .fg-forget-header {
//   color: $blue-shade-1;
//   font-weight: 500;
//   margin-top: 100px;
// }

// .fg-text-1 {
//   margin-top: 10px;
//   color: $color-light-grey;
// }

// .fg-form-wrapper {
//   margin: 45px 0px 75px 0px;
// }

// .fg-form-div {
//   border: 2px solid $color-grey;
//   border-radius: 20px;
//   padding: 8px 31px;
//   margin: 5px 134px 5px 0px;
// }

// .fg-form-input {
//   width: 90%;
// }

// .fg-btn-cancel {
//   padding: 5px 25px;
//   border: 2px solid $color-grey;
//   border-radius: 25px;
//   background: $color-grey;
//   font-weight: 500;
//   color: $blue-shade-1;
// }

// .fg-btn-next {
//   padding: 5px 25px;
//   margin-left: 20px;
//   border: 2px solid transparent;
//   border-radius: 25px;
//   background: $color-light-green;
//   font-weight: 500;
//   color: white;
// }

// .fg-footer-btn {
//   margin: 50px 0px;
// }

// .pwd-footer {
//   margin-top: 150px;
//   margin-bottom: 60px;
// }
