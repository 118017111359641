.sidebar-wrapper {
  box-shadow: 6px 0 4px 0 rgba(224, 224, 224, 0.4);
  background-color: $white;
  width: 260px;
  padding: 45px 20px;
  min-height: 100vh;
  z-index: 5;
  position: relative;
  @include bp(lg-max) {
    position: fixed;
    left: -270px;
  }
  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    img {
      max-width: 170px;
    }
    span {
      font-family: $secondary-font-medium;
      font-size: 18px;
      color: $orange-shade;
      letter-spacing: 1.79px;
    }
  }
  .sidebar-link {
    &.has-submenu {
      background-image: url(../images/image-arrow.png);
      background-repeat: no-repeat;
      background-position: center right 40px;
      background-size: 5px;
    }
    a {
      display: flex;
      width: 100%;
      align-items: center;
      height: 42px;
      border-radius: 22px;
      padding: 5px 36px;
      margin-bottom: 15px;
      i {
        width: 20px;
        height: auto;
        margin-right: 20px;
      }
      span {
        font-family: $secondary-font-book;
        color: $blue;
        font-size: 16px;
      }
    }
    &.active {
      a {
        background-color: rgba(170, 220, 145, 0.16);
      }
    }
  }
  .sidebar-menu-wrapper {
    margin-bottom: 80px;
  }
  .btn-wrapper {
    button {
      width: 100%;
    }
  }
  &.buyer-sidebar {
    .logo {
      span {
        color: $color-light-green;
      }
    }
  }
}
.sidebar-overlay {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.sidebar-open {
  overflow-y: hidden;
  .sidebar-overlay {
    opacity: 1;
    visibility: visible;
  }
  .sidebar-wrapper {
    left: 0px;
    padding:0;
    box-shadow: none;
    .sidebar-content{
      padding: 0 20px 45px;
      margin-top: 90px;
      overflow-y: auto;
      height: 100vh;
    }
  }
}
