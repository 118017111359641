$primary-font: "Roboto", sans-serif;
$secondary-font-bold: "Arboria-bold";
$secondary-font-book: "Arboria-Book";
$secondary-font-medium: "Arboria";
$fonts-montserrat: "Montserrat", sans-serif;

$black: #000;
$white: #fff;

$black-shade: #262626;
$black-shade-1: #2d0d0d;

$blue: #22456e;
$blue-shade-1: #033c54;
$blue-shade-2: #003d55;
$blue-shade-3: #638ea5;
$color-light-green: #aadc91;

$color-orange: #ff8500;
$orange-shade: #ff8800;
$red: #ff0000;
$red-shade-1: #ff0033;

$green:#55d493;

$color-grey: #f2f3f5;
$color-light-grey: #7f7f7f;
$gray-shade: #e2e2e2;
$gray-shade-1: #777777;
$gray-shade-2: #d8d8d8;
$gray-shade-3: #aaaaaa;
$gray-shade-4: #dcdcdc;
$gray-shade-5: #a1abbd;
$gray-shade-6: #cbced6;
$gray-shade-7: #cdcdcd;
$gray-shade-8: #f6f7fa;
$gray-shade-9: #bababa;
$gray-shade-10: #6e7189;
$gray-shade-11: #cfd4dd;
$gray-shade-12: #f3f4fb;
$grey-shade-13: #c2c9d2;
$grey-shade-14: #f4f4f4;
$grey-shade-15: #e9eaee;
$grey-shade-16: #f1f2f4;
$grey-shade-17: #f7f7f9;
$grey-shade-18:#979797;
$grey-shade-19:#e5e6e9;
$grey-shade-20:#cdcfd2;
$grey-shade-21:#e0e0e0;
$grey-shade-22:#f5f5f5;

$color-dark-teal: #003d55;
$color-blue-shade: #22456e;

$light-blue: #6cb9ff;
$light-blue-bg: rgba(108, 185, 255, 0.2);
