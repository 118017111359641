.seller-property-wrapper {
  .property-detail {
    .status {
      font-family: Arboria;
      font-size: 16px;
      font-weight: 500;

      &.under-process {
        color: #ff8800;
      }
      &.on-rent {
        width: 95px;
        height: 23px;
        border-radius: 2px;
        background-color: #aadc91;
        color: #fff;
        text-align: center;
      }
    }
  }
  .prop-list {
    width: 58%;
    justify-content: space-between;
    .status-tab {
      width: 15em;
      height: 2.8em;
      padding: 1em;
      border-radius: 50px;
      border: solid 2px #e2e2e2;
      background-color: #fff;
      font-family: Arboria;
      font-size: 16px;
      font-weight: 500;
      line-height: 0.63;
      text-align: center;
      color: #22456e;
      margin: 1em 0;
      &.in-progress {
        background-color: #ff8800;
        color: #fff;
        border: 1px solid #ff8800;
        cursor: pointer;
      }
      &.done {
        display: flex;
        background-color: #aadc91;
        border: 1px solid #aadc91;
        color: #fff;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 0.6em;
          height: 20px;
        }
      }
      &.failed {
        background-color: #f03;
        border: 1px solid #f03;
        color: #fff;
      }
      &.maintanance {
        width: 20em;
      }
    }
  }
  .list-block-detail {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.list-outer::-webkit-scrollbar {
  width: 0.4em;
}

.list-outer::-webkit-scrollbar-track {
  border-radius: 0.7em;
  background-color: #e9eaee;
}

.list-outer::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 0.7em;
}

@media screen and (max-width: 1670px) {
  .seller-property-wrapper {
    .prop-list {
      .status-tab {
        width: 13em;
      }
    }
  }
}

@media screen and (max-width: 1480px) {
  .seller-property-wrapper {
    .prop-list {
      .status-tab {
        width: 10.5em;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .seller-property-wrapper {
    .prop-list {
      .status-tab {
        font-size: 15px;
      }
    }
  }
}
