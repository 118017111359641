.hm-wrap {
  height: 100%;
  overflow-y: auto;
}



.page-wrapper {
  background-image: url("../images/page-bg.png");
  background-repeat: no-repeat;
  background-position: top left;
}
.buyer-seller-banner {
  display: flex;
  align-items: center;
  padding: 90px 0 180px;
  @include bp(xl-max) {
    padding: 50px 0 80px;
  }
  @include bp(lg-max) {
    padding: 50px 0;
  }
  @include bp(sm-max) {
    padding: 0 0 50px;
  }
  .container-main {
    display: flex;
    position: relative;
    @include bp(sm-max) {
      align-items: center;
    }
  }
  h1 {
    color: $color-light-green;
    margin-bottom: 15px;
  }
  p {
    font-family: $secondary-font-medium;
    color: $blue-shade-2;
    font-size: 24px;
    margin-bottom: 50px;
    @include bp(lg-max) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    @include bp(xs-max) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  ul {
    li {
      font-family: $secondary-font-bold;
      font-size: 45px;
      line-height: 56px;
      color: $blue;
      @include bp(lg-max) {
        font-size: 32px;
        line-height: 40px;
      }
      @include bp(xl-max) {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
  .search-box {
    max-width: 100%;
    border: 1px solid $gray-shade-2;
    border-radius: 31px;
    background-color: $white;
    padding: 0 5px 0 60px;
    display: flex;
    align-items: center;
    margin-bottom: 120px;
    background-image: url(../images/iconSearch.svg);
    background-repeat: no-repeat;
    background-position: center left 20px;
    position: relative;
    @include bp(xl-max) {
      margin-bottom: 50px;
    }
    @include bp(sm-max) {
      padding: 0 5px 0 45px;
      background-position: center left 15px;
      background-size: 20px;
    }
    input {
      background-color: $white;
      padding: 12px 20px 12px 0;
      font-size: 18px;
      width: calc(100% - 145px);
      @include bp(sm-max) {
        padding: 8px 15px 8px 0;
      }
      @include bp(xs-max) {
        width: 100%;
        font-size: 14px;
        padding: 8px 8px 8px 0;
        border-radius: 16px;
      }
    }
    button {
      background-color: $color-light-green;
      text-align: center;
      color: $white;
      text-align: center;
      padding: 8px 20px;
      font-size: 18px;
      font-weight: 600;
      border-radius: 31px;
      margin-left: auto;
      min-width: 145px;
      @include bp(sm-max) {
        padding: 6px 20px;
      }
      @include bp(xs-max) {
        position: absolute;
        bottom: -50px;
        left: 0;
      }
    }
  }
  .left-block {
    width: 60%;
    z-index: 1;
    padding-top: 50px;
    @include bp(xl-max) {
      width: 50%;
    }
    @include bp(sm-max) {
      width: 65%;
    }
    @include bp(xs-max) {
      width: 100%;
    }
  }
  .banner-image {
    width: calc(40% + 220px);
    margin: 0 -100px 0 -120px;
    z-index: 0;
    position: relative;
    @include bp(xl-max) {
      width: 50%;
      margin: 0 -100px 0 0;
    }
    @include bp(sm-max) {
      width: calc(35% + 100px);
      margin: 0 0 0 -100px;
    }
    @include bp(xs-max) {
      position: absolute;
      right: 0;
      bottom: 0px;
    }
    @include bp(s-max) {
      bottom: 50px;
    }
  }
}
.buyer-seller-content {
  padding: 80px 0 50px;
  @include bp(lg-max) {
    padding: 80px 0 30px;
  }
  @include bp(sm-max) {
    padding: 50px 0 30px;
  }
  @include bp(xs-max) {
    padding: 30px 0 50px;
  }
  .container-main {
    display: flex;
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
    .left-block {
      flex: 0 0 50%;
      max-width: 50%;
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .heading {
        font-family: $secondary-font-medium;
        font-size: 52px;
        line-height: 1;
        color: $color-light-green;
        margin-bottom: 10px;
        @include bp(lg-max) {
          font-size: 44px;
        }
        @include bp(xs-max) {
          font-size: 38px;
        }
      }
      .content {
        font-family: $secondary-font-medium;
        font-size: 20px;
        line-height: 1.3;
        color: $blue-shade-2;
        margin-bottom: 40px;
        @include bp(lg-max) {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
      .title {
        font-family: $secondary-font-medium;
        font-size: 30px;
        line-height: 1.2;
        margin-bottom: 15px;
        color: $color-light-green;
        @include bp(lg-max) {
          font-size: 28px;
          margin-bottom: 10px;
        }
        @include bp(xs-max) {
          font-size: 24px;
        }
      }
    }
    .right-block {
      padding-left: 30px;
      @include bp(sm-max) {
        flex: 0 0 70%;
        max-width: 70%;
        margin: 0 auto;
      }
      @include bp(xs-max) {
        flex: 0 0 80%;
        max-width: 80%;
      }
      @include bp(s-max) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
      }
    }
  }
}
.saving-section {
  background-image: url("../images/saving-bg.png");
  background-size: cover;
  padding: 80px 0 65px;
  @include bp(xs-max) {
    padding: 40px 0;
  }
  h2 {
    font-family: $secondary-font-medium;
    font-size: 52px;
    line-height: 1;
    color: $color-light-green;
    margin-bottom: 50px;
    @include bp(lg-max) {
      font-size: 44px;
      margin-bottom: 30px;
    }
    @include bp(xs-max) {
      font-size: 38px;
      margin-bottom: 20px;
    }
  }
  .content {
    font-family: $secondary-font-medium;
    font-size: 20px;
    line-height: 1.3;
    color: $blue-shade-2;
    margin-bottom: 40px;
    text-align: center;
    @include bp(lg-max) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .terms-price-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .title {
      font-family: $secondary-font-medium;
      font-size: 40px;
      line-height: 1.3;
      color: $blue-shade-2;
      margin-bottom: 40px;
      text-align: center;
      @include bp(lg-max) {
        font-size: 32px;
        margin-bottom: 20px;
      }
      @include bp(xs-max) {
        font-size: 24px;
      }
      span {
        position: relative;
        display: inline-block;
        &::after {
          content: "";
          position: absolute;
          width: 60px;
          height: 3px;
          border-radius: 2px;
          background-color: $color-light-green;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .left-block {
      flex: 0 0 50%;
      max-width: 50%;
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
      .term-btn {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        span {
          width: 150px;
          height: 55px;
          border-radius: 50px;
          border: solid 2px $gray-shade;
          background-color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 45px;
          font-family: $secondary-font-medium;
          font-size: 20px;
          line-height: 1.3;
          color: $blue;
          margin-bottom: 10px;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background-color: $orange-shade;
            border-color: $orange-shade;
            font-size: 20px;
            line-height: 1.3;
            color: $white;
          }
          @include bp(lg-max) {
            height: 45px;
            margin-right: 25px;
          }
          @include bp(xs-max) {
            margin: 0 5px 10px;
            width: auto;
            padding: 5px 15px;
          }
        }
      }
    }
    .right-block {
      flex: 0 0 22%;
      max-width: 22%;
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .total-saving {
      font-family: $secondary-font-medium;
      font-size: 40px;
      line-height: 1.3;
      color: $color-light-green;
      text-align: center;
      @include bp(lg-max) {
        font-size: 32px;
      }
      @include bp(xs-max) {
        font-size: 28px;
      }
    }
    .slider-wrapper {
      width: 100%;
      padding: 100px 100px 0;
      @include bp(lg-max) {
        padding: 50px 50px 0;
      }
      @include bp(xs-max) {
        padding: 30px 30px 0;
      }
    }
  }
}
.skew-section {
  display: flex;
  @include bp(sm-max) {
    flex-wrap: wrap;
  }
  .text-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50% + 50px);
    background-color: $color-light-green;
    margin-right: -50px;
    @include bp(sm-max) {
      width: 100%;
      padding: 35px 10px;
    }
    p {
      color: $white;
      font-family: $secondary-font-medium;
      font-size: 65px;
      @include bp(lg-max) {
        font-size: 48px;
      }
      @include bp(sm-max) {
        font-size: 38px;
      }
    }
  }
  .white-border-skew {
    background-color: $white;
    position: absolute;
    height: 100%;
    left: 30px;
    width: 20px;
    margin: 0 0 0 -20px;
    transform: skew(-13deg);
    -webkit-transform: skew(-13deg);
    -moz-transform: skew(-13deg);
    -o-transform: skew(-13deg);
  }
  .image-block {
    background-image: url("../images/buyer-skew.png");
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    width: 50%;
    background-size: cover;
    height: 200px;
    position: relative;
    @include bp(sm-max) {
      width: calc(100% + 60px);
      margin-left: -60px;
    }
    .white-fill-btn {
      min-width: 225px;
      min-height: 55px;
      @include bp(sm-max) {
        margin-left: 70px;
      }
    }
  }
}
.calculator-section {
  background-image: url("../images/calculator-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 80px 0 60px;
  @include bp(sm-max) {
    padding: 80px 0 20px;
  }
  .container-main {
    display: flex;
    @include bp(sm-max) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    .form-outer {
      flex: 0 0 50%;
      max-width: 50%;
      @include bp(lg-max) {
        flex: 0 0 45%;
        max-width: 45%;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
      .form-header {
        padding: 45px 45px 25px;
        border-radius: 40px 40px 0 0;
        background-color: $blue-shade-2;
        color: $white;
        @include bp(lg-max) {
          padding: 25px 35px 25px;
        }
        .form-title {
          font-family: $secondary-font-bold;
          font-size: 28px;
          position: relative;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 10px;
          @include bp(lg-max) {
            font-size: 24px;
          }
          &:after {
            content: "";
            position: absolute;
            width: 50px;
            height: 6px;
            border-radius: 3px;
            background-color: $color-light-green;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .amount {
          font-family: $secondary-font-medium;
          font-size: 55px;
          text-align: center;
          @include bp(lg-max) {
            font-size: 45px;
          }
          @include bp(sm-max) {
            font-size: 30px;
          }
          span {
            font-family: $secondary-font-book;
            font-size: 34px;
            sup {
              top: -5px;
              position: relative;
              left: -3px;
            }
          }
        }
      }
      .form-body {
        background-color: $white;
        padding: 40px;
        @include bp(lg-max) {
          padding: 20px;
        }
        .slider-wrapper {
          .MuiSlider-root {
            margin-bottom: 30px;
            @include bp(lg-max) {
              margin-bottom: 15px;
            }
          }
          p {
            font-family: $secondary-font-bold;
            font-size: 18px;
            color: $blue-shade-2;
          }
          h3 {
            font-family: $secondary-font-medium;
            font-size: 35px;
            color: $blue-shade-2;
            margin-bottom: 20px;
            @include bp(lg-max) {
              font-size: 24px;
              margin-bottom: 10px;
            }
            em {
              font-style: normal;
              color: $color-light-green;
              margin-right: 8px;
            }
          }
        }
      }
      .form-footer {
        background-color: $color-light-green;
        border-radius: 0 0 40px 40px;
        padding: 15px 15px 25px;
        text-align: center;
        margin-bottom: 10px;
        p {
          font-family: $secondary-font-medium;
          font-size: 35px;
          color: $white;
          @include bp(lg-max) {
            font-size: 28px;
            line-height: 35px;
          }
        }
      }
      .note-text {
        font-family: $secondary-font-medium;
        font-size: 19px;
        color: $blue;
        text-align: center;
        padding: 0 50px;
        @include bp(lg-max) {
          font-size: 16px;
          padding: 0 20px;
        }
      }
    }
    .content-block {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 25px;
      @include bp(lg-max) {
        flex: 0 0 55%;
        max-width: 55%;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
      }
      h2 {
        color: $blue-shade-2;
        font-family: $secondary-font-medium;
        font-size: 55px;
        margin-bottom: 30px;
        position: relative;
        @include bp(lg-max) {
          font-size: 48px;
          margin-bottom: 15px;
        }
        @include bp(sm-max) {
          font-size: 38px;
        }
        &:after {
          content: "";
          position: absolute;
          width: 50px;
          height: 6px;
          border-radius: 3px;
          background-color: $color-light-green;
          top: -10px;
          left: 0;
        }
      }
      ul {
        padding-left: 20px;
        margin-bottom: 30px;
        li {
          position: relative;
          font-family: $secondary-font-medium;
          font-size: 22px;
          line-height: 33px;
          color: $black-shade;
          @include bp(lg-max) {
            font-size: 18px;
            line-height: 25px;
          }
          &:before {
            content: "";
            height: 8px;
            width: 8px;
            display: inline-block;
            border-radius: 4px;
            background-color: $black-shade;
            position: absolute;
            left: -20px;
            top: 14px;
          }
        }
      }
      p {
        font-family: $secondary-font-medium;
        font-size: 22px;
        line-height: 33px;
        color: $black-shade;
        margin-bottom: 30px;
        @include bp(lg-max) {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
  }
}

// overwrite css for selleer page
.seller-banner {
  @include bp(sm-max) {
    padding: 0 0 30px;
  }
  h1 {
    color: $blue;
    span {
      color: $orange-shade;
    }
  }
  .search-box {
    background-image: url("../images/location.svg");
    button {
      background-color: $orange-shade;
    }
  }
  .banner-image {
    @include bp(xs-max) {
      bottom: -70px;
    }
  }
}
.seller-page-content {
  .container-main {
    .left-block {
      .heading,
      .title {
        color: $orange-shade;
      }
    }
  }
}
.seller-saving {
  background-image: url("../images/home-bg-image.png");
  padding: 110px 0 130px;
  background-position: center;
  @include bp(lg-max) {
    padding: 50px 0;
  }
  .image-block {
    display: block;
    max-width: 94px;
    margin: 0 auto 25px;
    img {
      max-width: 100%;
    }
  }
  h2 {
    color: $blue;
  }
  .terms-price-block {
    .slider-wrapper {
      padding: 0 100px;
      @include bp(sm-max) {
        padding: 0 40px 0 25px;
      }
    }
    .title {
      margin-bottom: 10px;
      color: $blue;
      span {
        &:after {
          display: none;
        }
      }
    }
    .total-saving {
      color: $orange-shade;
      margin-bottom: 90px;
      @include bp(sm-max) {
        margin-bottom: 30px;
      }
    }
    .count-wrapper {
      display: flex;
      width: 100%;
      @include bp(md-max) {
        flex-wrap: wrap;
        justify-content: center;
      }
      .count-block {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        @include bp(md-max) {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 20px;
        }
        @include bp(s-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 20px;
        }
        .title {
          font-family: $secondary-font-book;
        }
        .total-saving {
          margin-bottom: 0;
        }
      }
    }
  }
}
.seller-skew-block {
  .text-block {
    background-color: $orange-shade;
  }
  .white-border-skew {
    transform: skew(13deg);
    -webkit-transform: skew(13deg);
    -moz-transform: skew(13deg);
    -o-transform: skew(13deg);
  }
  .image-block {
    background-image: url("../images/seller-skew.png");
  }
}

.property-list-section {
  padding: 100px 0 30px;
  @include bp(sm-max) {
    padding: 50px 0 30px;
  }
  .title {
    font-family: $secondary-font-bold;
    font-size: 32px;
    color: $blue;
    position: relative;
    text-align: center;
    margin-bottom: 50px;
    &::after {
      content: "";
      position: absolute;
      width: 60px;
      height: 3px;
      border-radius: 2px;
      background-color: $orange-shade;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.property-wrapper {
  display: flex;
  margin: 0 -15px;
  @include bp(sm-max) {
    margin: 0 -5px;
  }
  flex-wrap: wrap;
  .property-block {
    margin: 0 15px 50px;
    flex: 0 0 calc(33.33% - 30px);
    max-width: calc(33.33% - 30px);
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);
    background-color: $white;
    position: relative;
    @include bp(lmd-max) {
      flex: 0 0 calc(50% - 30px);
      max-width: calc(50% - 30px);
    }
    @include bp(sm-max) {
      margin: 0 5px 20px;
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
    @include bp(xs-max) {
      flex: 0 0 calc(100% - 10px);
      max-width: calc(100% - 10px);
    }
    .top-block {
      position: absolute;
      top: 32px;
      left: 16px;
      right: 16px;
      display: flex;
      z-index: 1;
      justify-content: space-between;
      .profile-block {
        display: flex;
        align-items: center;
        i {
          display: inline-block;
          height: 30px;
          width: 30px;
          border-radius: 100%;
          margin-right: 25px;
          overflow: hidden;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        span {
          font-family: $secondary-font-book;
          font-size: 16px;
          color: $white;
        }
      }
      .like-block {
        img {
          max-width: 32px;
        }
      }
    }
    .property-image {
      height: 200px;
      display: inline-block;
      position: relative;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      width: 100%;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .like-view-block {
      border-radius: 6px;
      border: solid 1px $gray-shade-4;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      padding: 21px 14px 21px;
      margin: -30px 16px 20px;
      z-index: 1;
      position: relative;
      @include bp(sm-max) {
        margin: -30px 6px 20px;
        padding: 12px;
      }
      .left-block {
        flex: 0 0 50%;
        max-width: 50%;
        .lable {
          font-family: $secondary-font-book;
          font-size: 14px;
        }
        .price {
          font-family: $secondary-font-bold;
          font-size: 22px;
          color: $color-light-green;
          @include bp(sm-max) {
            font-size: 18px;
          }
        }
      }
      .right-block {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: right;
        i {
          margin-right: 5px;
          display: inline-block;
        }
        span {
          font-family: $secondary-font-bold;
          font-size: 18px;
          color: $blue;
          @include bp(sm-max) {
            font-size: 14px;
          }
        }
        .view {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          i {
            max-width: 28px;
          }
        }
        .like {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          i {
            max-width: 20px;
          }
        }
      }
    }
    .more-detail {
      padding: 0 16px;
      .property-name {
        font-family: $secondary-font-bold;
        font-size: 22px;
        color: $blue;
        @include bp(sm-max) {
          line-height: 1.1;
          margin-bottom: 10px;
        }
      }
      .property-address {
        font-family: $secondary-font-book;
        font-size: 17px;
        color: $gray-shade-5;
        margin-bottom: 25px;
        @include bp(sm-max) {
          margin-bottom: 15px;
        }
      }
      ul {
        display: flex;
        margin-bottom: 30px;
        @include bp(sm-max) {
          margin-bottom: 15px;
        }
        li {
          flex: 0 0 33.33%;
          max-width: 33.33%;
          position: relative;
          font-family: $secondary-font-book;
          font-size: 17px;
          color: $blue;
          @include bp(sm-max) {
            font-size: 14px;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            position: absolute;
            height: 9px;
            width: 1px;
            top: 8px;
            right: 25px;
            background-color: $gray-shade-6;
            @include bp(sm-max) {
              right: 15px;
            }
          }
        }
      }
    }
    .bottom-block {
      padding: 0 16px;
      p {
        font-family: $secondary-font-medium;
        font-size: 16px;
        color: $gray-shade-5;
        margin-bottom: 50px;
        @include bp(sm-max) {
          margin-bottom: 25px;
        }
      }
      .detail-block {
        display: flex;
        margin-bottom: 35px;
        @include bp(sm-max) {
          margin-bottom: 15px;
        }
        .left-block,
        .right-block {
          flex: 0 0 50%;
          max-width: 50%;
        }
        .left-block {
          display: flex;
          align-items: center;
          font-family: $secondary-font-medium;
          font-size: 16px;
          color: $blue;
          .icon-block {
            margin-right: 18px;
            position: absolute;
          }
          span {
            padding-left: 40px;
          }
        }
        .right-block {
          text-align: right;
          padding-left: 10px;
          font-family: $secondary-font-medium;
          font-size: 16px;
          color: $blue;
        }
      }
      .btn-wrapper {
        margin: 70px 0 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include bp(sm-max) {
          margin: 20px 0 10px;
        }
        button {
          width: calc(50% - 10px);
          margin-bottom: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}
.sm-visible {
  display: none;
  @include bp(sm-max) {
    display: block;
  }
}
.favrite-property-list {
  padding: 35px 0;
  .title {
    font-family: $secondary-font-bold;
    font-size: 22px;
    color: $blue;
    margin-bottom: 35px;
  }
  &.compare-property {
    .property-block {
      position: relative;
      display: flex;
      flex-direction: column;
      @include bp(sm-max) {
        margin: 0 10px 20px;
        flex: calc(50% - 20px);
        max-width: calc(50% - 20px);
      }
      @include bp(xs-max) {
        flex: calc(100% - 20px);
        max-width: calc(100% - 20px);
      }
      &:after {
        content: "";
        position: absolute;
        top: -6px;
        bottom: -6px;
        left: -6px;
        right: -6px;
        border-radius: 8px;
        border: 3px solid $color-light-green;
        z-index: -1;
      }
      .margin-top {
        margin-top: auto;
      }
    }
  }
}
.chart-wrapper {
  position: relative;
  padding: 0;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: -5px 0 0 16px;
    font-family: $secondary-font-book;
    font-size: 12px;
    color: $blue;
  }
  canvas {
    margin-top: -20px;
    height: 100px !important;
    width: 100px !important;
    margin-left: auto;
  }
}
