.buyer-application-header {
  .right-header {
    a {
      font-family: $secondary-font-medium;
      font-size: 18px;
      color: $blue;
    }
  }
}
.buyer-application-dashboard {
  display: flex;
  background-color: $gray-shade-8;
  padding: 30px 15px 30px 15px;
  @include bp(sm-max) {
    flex-wrap: wrap;
  }
  @include bp(xs-max) {
    padding: 30px 15px;
  }
  .image-block-wrapper {
    width: 290px;
    @include bp(lg-max) {
      width: 200px;
    }
    @include bp(sm-max) {
      width: calc(100% + 10px);
      display: flex;
      margin: 0 -5px 20px;
    }
    @include bp(xs-max) {
      flex-wrap: wrap;
    }
    .image-block {
      border-radius: 10px 0 0 10px;
      overflow: hidden;
      margin-bottom: 10px;
      @include bp(sm-max) {
        flex: 0 0 calc(25% - 10px);
        max-width: calc(25% - 10px);
        margin: 0 5px;
        border-radius: 5px;
      }
      @include bp(xs-max) {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin-bottom: 10px;
      }
      img {
        width: 100%;
      }
    }
  }
  .tab-outer-container {
    padding: 0 100px 0 30px;
    flex: 1;
    @include bp(xlg-max) {
      padding: 0 0 0 30px;
    }
    @include bp(sm-max) {
      padding: 0;
    }
    .tab-header {
      display: flex;
      border-bottom: solid 2px $grey-shade-15;
      padding-bottom: 20px;
      margin-bottom: 30px;
      @include bp(sm-max) {
        padding-bottom: 10px;
      }
      li {
        position: relative;
        cursor: pointer;
        @include bp(xs-max) {
          flex-direction: column;
          display: flex;
          width: 33.33%;
          align-items: center;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 15px;
          bottom: -30px;
          height: 2px;
          background-color: transparent;
          @include bp(sm-max) {
            right: 15px;
            bottom: -12px;
          }
          @include bp(xs-max) {
            right: 0;
          }
        }
        i {
          display: inline-block;
          font-style: normal;
          height: 28px;
          width: 28px;
          border-radius: 8px;
          background-color: $gray-shade-11;
          margin-right: 15px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: $white;
        }
        span {
          color: $gray-shade-5;
          font-size: 22px;
          font-family: $secondary-font-medium;
          margin-right: 90px;
          @include bp(md-max) {
            margin-right: 20px;
            font-size: 20px;
          }
        }
        &.active {
          &:after {
            right: 25px;
            bottom: -22px;
            background-color: $color-light-green;
            @include bp(sm-max) {
              right: 15px;
              bottom: -12px;
            }
            @include bp(xs-max) {
              right: 0;
            }
          }
          i {
            background-color: $color-light-green;
          }
          span {
            color: $blue;
          }
        }
      }
    }
    .tab-content {
      .tab-title {
        font-family: $primary-font;
        font-size: 22px;
        color: $blue;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .detail-block {
        border-radius: 10px;
        background-color: $white;
        padding: 35px 25px;
        display: flex;
        margin-bottom: 20px;
        @include bp(md-max) {
          padding: 15px;
        }
        @include bp(xs-max) {
          flex-wrap: wrap;
        }
        .image-block {
          max-width: 50px;
          margin-right: 20px;
        }
        .content-block {
          width: 100%;
          margin-right: 10px;
          .title {
            font-family: $secondary-font-medium;
            font-size: 18px;
            color: $blue-shade-2;
          }
          .list-wrapper {
            display: flex;
            width: 100%;
            @include bp(md-max) {
              flex-wrap: wrap;
            }
            .list-block {
              width: 50%;
              padding-right: 20px;
              display: flex;
              @include bp(md-max) {
                width: 100%;
              }
              .gray-text {
                margin-right: 10px;
              }
              .value-block {
                display: flex;
                flex-direction: column;
                strike {
                  font-family: $secondary-font-medium;
                  color: $orange-shade;
                  font-size: 17px;
                }
              }
              .value {
                font-family: $secondary-font-medium;
                color: $blue-shade-2;
                font-size: 17px;
              }
            }
          }
          .gray-text {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue-shade-3;
            @include bp(sm-max){
              font-size: 14px;
            }
          }
          .review-content {
            display: flex;
            margin-top: 20px;
            @include bp(lg-max) {
              flex-wrap: wrap;
            }
            .value {
              font-family: $secondary-font-medium;
              font-size: 17px;
              color: $blue-shade-2;
            }
            .block-33 {
              flex: 0 0 33.33%;
              max-width: 33.33%;
              @include bp(lg-max) {
                flex: 0 0 50%;
                max-width: 50%;
              }
            }
            .content {
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
                @include bp(lg-max) {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
        .btn-wrapper {
          margin-left: auto;
          @include bp(xs-max) {
            margin-top: 10px;
          }
          .arrow-btn {
            max-width: 50px;
          }
          .gray-border-btn {
            font-size: 16px;
            min-width: 150px;
            height: 52px;
            font-weight: 500;
            @include bp(xlg-max) {
              min-width: 80px;
              height: 40px;
            }
          }
        }
      }
    }
  }
}
.step-wrapper {
  padding: 75px 100px;
  background: $white;
  border-radius: 10px;
  margin-bottom: 30px;
  min-height: calc(100vh - 170px);
  position: relative;
  display: flex;
  justify-content: center;
  @include bp(lg-max) {
    padding: 50px;
  }
  @include bp(md-max) {
    padding: 30px 25px;
  }
  @include bp(sm-max) {
    flex-direction: column;
    padding: 20px;
  }
  > .left-block {
    flex: 0 0 35%;
    max-width: 35%;
    @include bp(lg-max) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @include bp(sm-max) {
      margin-bottom: 40px;
      flex: 0 0 100%;
      max-width: 100%;
    }
    img {
      width: 100%;
      object-fit: cover;
      height: 80%;
      @include bp(sm-max) {
        height: 300px;
      }
    }
  }
  > .right-block {
    flex: 0 0 65%;
    max-width: 65%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 30px 0 34px;
    @include bp(lg-max) {
      padding-left: 30px;
      flex: 0 0 60%;
      max-width: 60%;
    }
    @include bp(md-max) {
      padding-left: 15px;
    }

    @include bp(sm-max) {
      margin-bottom: 0;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
    }
    .question-block {
      font-family: $secondary-font-bold;
      font-size: 28px;
      line-height: 1;
      margin-bottom: 50px;
      color: $blue;
      @include bp(md-max) {
        font-size: 22px;
        margin-bottom: 15px;
      }
      @include bp(sm-max) {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    .ans-block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      @include bp(md-max) {
        margin-bottom: 30px;
        justify-content: flex-start;
      }
      @include bp(sm-max) {
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
      .select-ans {
        height: 60px;
        border-radius: 30px;
        min-width: 215px;
        line-height: 1;
        display: inline-block;
        border: 2px solid $gray-shade;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $secondary-font-medium;
        font-size: 19px;
        color: $blue;
        cursor: pointer;
        @include bp(md-max) {
          height: 45px;
          min-width: 150px;
          margin-right: 20px;
          font-size: 16px;
        }
        @include bp(sm-max) {
          margin-right: 10px;
          margin-bottom: 10px;
        }
        @include bp(xs-max) {
          min-width: 120px;
        }
        &:last-child {
          @include bp(sm-max) {
            margin-right: 0;
          }
        }
        &.video-icon {
          background-image: url('../images/icon-feather-video.svg');
          background-position: center left 35px;
          background-repeat: no-repeat;
          padding-left: 50px;
        }
        &.active {
          background-color: $orange-shade;
          color: $white;
          border-color: $orange-shade;
        }
      }
    }
    .tour-ans-block {
      display: flex;
      justify-content: space-between;
      @include bp(md-max) {
        justify-content: flex-start;
        margin-bottom: 30px;
      }
      @include bp(sm-max) {
        margin-bottom: 20px;
        flex-wrap: wrap;
      }
      .select-ans {
        height: 60px;
        border-radius: 30px;
        min-width: 215px;
        line-height: 1;
        display: inline-block;
        border: 2px solid $gray-shade;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $secondary-font-medium;
        font-size: 19px;
        color: $blue;
        cursor: pointer;
        @include bp(md-max) {
          height: 45px;
          min-width: 150px;
          margin-right: 20px;
          font-size: 16px;
        }
        @include bp(sm-max) {
          margin-right: 10px;
          margin-bottom: 10px;
        }
        &:last-child {
          @include bp(sm-max) {
            margin-right: 0;
          }
        }
        &.video-icon {
          background-image: url('../images/icon-feather-video.svg');
          background-position: center left 35px;
          background-repeat: no-repeat;
          padding-left: 50px;
        }
        &.active {
          background-color: $orange-shade;
          color: $white;
          border-color: $orange-shade;
        }
      }
    }
    .tour-ans-block-error {
      margin-bottom: 30px;
    }
    .form-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -5px;
      .field-wrapper {
        margin: 0 5px 30px;
        width: 100%;
        label {
          font-family: $secondary-font-medium;
          font-size: 16px;
          margin-bottom: 10px;
        }
        input {
          font-family: $secondary-font-book;
          font-size: 14px;
          height: 55px;
          @include bp(md-max) {
            height: 45px;
          }
          &::placeholder {
            font-size: 14px;
          }
        }
        .react-datepicker-wrapper {
          width: 100%;
        }
        &.date-picker {
          input {
            background-image: url('../images/icon-calendar.svg');
            background-repeat: no-repeat;
            background-position: center left 16px;
            padding-left: 50px;
          }
        }
        &.time-picker {
          input {
            background-image: url('../images/icon-calendar.svg');
            background-repeat: no-repeat;
            background-position: center left 16px;
            padding-left: 50px;
          }
        }
        &.width-50 {
          flex: calc(50% - 20px);
          max-width: calc(50% - 20px);
          @include bp(sm-max) {
            flex: 0 0 calc(100% - 10px);
            max-width: calc(100% - 10px);
          }
        }
      }
      &.fill-detail {
        .field-wrapper {
          margin-bottom: 20px;
        }
        .btn-wrapper {
          margin: 0 5px;
          display: flex;
          align-content: center;
          justify-content: space-between;
          width: calc(100% - 10px);
          @include bp(xs-max) {
            flex-wrap: wrap;
          }
        }
        .border-btn {
          width: 240px;
          height: 47px;
          padding: 5px;
          border: dashed 0.6px $blue;
          background-color: $color-grey;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $secondary-font-medium;
          font-size: 16px;
          @include bp(sm-max) {
            margin-bottom: 10px;
          }
          .img-icon {
            margin-right: 10px;
          }
        }
      }
    }
    .step-footer {
      border-top: solid 1px $blue-shade-3;
      padding: 20px 0;
      margin: 50px 0;
      text-align: right;
      @include bp(md-max) {
        margin: 10px 0;
        padding: 10px 0;
      }
      @include bp(sm-max) {
        margin: 20px 0;
      }
      button {
        height: 60px;
        border-radius: 30px;
        margin-left: 25px;
        min-width: 170px;
        @include bp(md-max) {
          height: 45px;
          min-width: 150px;
        }
        @include bp(xs-max) {
          min-width: 110px;
          margin-left: 10px;
        }
      }
    }
    .content-wrapper {
      .sub-title {
        font-family: $secondary-font-bold;
        font-size: 18px;
        color: $blue;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        @include bp(xs-max) {
          flex-wrap: wrap;
        }
        .right-block {
          @include bp(xs-max) {
            margin-left: 10px;
            margin-left: auto;
          }
          .title {
            font-family: $secondary-font-book;
            font-size: 16px;
            color: $gray-shade-5;
            margin-right: 10px;
          }
          .value {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue;
          }
        }
      }
      .slider-wrapper {
        margin-bottom: 60px;
        @include bp(md-max) {
          margin-bottom: 30px;
        }
        @include bp(sm-max) {
          padding: 0 10px 0 12px;
        }
        .MuiSlider-root {
          margin-bottom: 0;
          width: 98%;
        }
        .MuiSlider-markLabel {
          display: none;
        }
      }
      .value-wrapper {
        display: flex;
        justify-content: space-between;
        @include bp(xs-max) {
          flex-wrap: wrap;
        }
        button {
          height: 27px;
          line-height: 1;
          min-width: 100px;
          font-family: $secondary-font-book;
          font-size: 14px;
          margin-left: 10px;
          padding: 0;
        }
        .right-block {
          @include bp(xs-max) {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 10px;
          }
          button {
            @include bp(xs-max) {
              min-width: auto;
              padding: 2px 10px;
            }
          }
        }
      }
      .terms-btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include bp(xs-max) {
          justify-content: center;
        }
        // margin-bottom: 60px;
        .term-btn {
          border: 2px solid $gray-shade;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $secondary-font-medium;
          font-size: 19px;
          color: $blue;
          height: 50px;
          width: 144px;
          border-radius: 25px;
          font-family: $secondary-font-medium;
          font-size: 16px;
          cursor: pointer;
          @include bp(md-max) {
            height: 40px;
            width: auto;
            padding: 5px 15px;
          }
          @include bp(xs-max) {
            margin: 0 5px 5px;
          }
          &.active {
            background-color: $orange-shade;
            color: $white;
            border-color: $orange-shade;
          }
        }
      }
      .offer-block {
        .right-block {
          flex-direction: column;
          align-items: flex-end;
          display: flex;
          .title {
            margin-right: 0;
          }
        }
      }
      &.pay-cash {
        .content {
          .title {
            font-family: $secondary-font-book;
            font-size: 16px;
            color: $gray-shade-5;
            margin-right: 10px;
          }
          .value {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue;
          }
        }
        .right-block {
          text-align: right;
        }
      }
    }
  }
}
.calenderStyle {
  .react-datepicker {
    width: 100%;
    padding: 10px;
    border: 2px solid #e2e2e2;
    border-radius: 12px;

    .react-datepicker__month-container {
      width: 100%;
    }
    button.react-datepicker__navigation {
      margin-top: 28px;
    }
    .react-datepicker__header {
      background: none;
      border: none;
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-evenly;
    }

    .react-datepicker__week {
      display: flex;
      justify-content: space-evenly;
      margin-top: 24px;
    }

    .react-datepicker__current-month {
      text-align: left;
      padding: 10px 25px 27px;
      color: #22456e;
      font-size: 18px;
      font-family: 'Arboria-Book';
    }

    .react-datepicker__day {
      width: 36px;
      height: 36px;
      align-items: center;
      justify-content: center;
      display: flex;
      color: #595f6f;
      border-radius: 8px;
      background: none;
      &.react-datepicker__day--selected {
        background: #ff8800;
        font-weight: 800;
        color: #ffffff;
      }
      &.react-datepicker__day--today {
        background: #f2f3f5;
        &.react-datepicker__day--selected {
          background: #ff8800;
          color: #ffffff;
        }
      }
    }
  }
  .react-datepicker-wrapper {
    width: 80%;
    input {
      width: 100%;
      height: 43px;
      border-radius: 10px;
      border: solid 1px #e2e2e2;
      color: #aadc91;
      font-size: 18px;
      font-family: 'Arboria-Book';
      font-weight: 600;
    }
  }

  .react-datepicker__tab-loop .react-datepicker-popper {
    position: relative !important;
    inset: unset !important;
    transform: none !important;
    margin-top: 14px;
    .react-datepicker__triangle {
      display: none !important;
    }
  }
}
.step-3,
.step-2 {
  padding-left: 1.6rem;
  @include bp(sm-max) {
    padding-left: 0;
  }
}
.end-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  button.green-fill-btn {
    width: 171px;
    height: 60px;
    padding: 19px 15px 22px 16px;
    border-radius: 50px;
    background-color: #aadc91;
    font-family: Arboria;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
  }
}
