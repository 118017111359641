/* form elements */
.field-outer-flex {
  display: flex;
  margin: 0 -5px 20px;

  @include bp(md-max) {
    flex-wrap: wrap;
  }
}

.field-wrapper {
  margin-bottom: 30px;

  @include bp(lg-max) {
    margin-bottom: 20px;
  }

  &.no-margin {
    margin-bottom: 10px;
  }

  &.width-33 {
    flex: 0 0 calc(33.33% - 10px);
    max-width: calc(33.33% - 10px);
    margin: 0 5px;

    @include bp(md-max) {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
      margin-bottom: 10px;
    }

    @include bp(xs-max) {
      flex: 0 0 calc(100% - 10px);
      max-width: calc(100% - 10px);
    }
  }

  &.width-50 {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin: 0 5px;

    @include bp(md-max) {
      flex: 0 0 calc(100% - 10px);
      max-width: calc(100% - 10px);
      margin-bottom: 10px;
    }

    .flex {
      @include bp(xs-max) {
        flex-wrap: wrap;
      }
    }

    .width-50 {
      @include bp(md-max) {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin-bottom: 0;
      }

      @include bp(xs-max) {
        flex: 0 0 calc(100% - 10px);
        max-width: calc(100% - 10px);
        margin: 0 0 10px;
      }
    }
  }

  &.width-25 {
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    margin: 0 5px;

    @include bp(md-max) {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
      margin-bottom: 10px;
    }

    @include bp(xs-max) {
      flex: 0 0 calc(100% - 10px);
      max-width: calc(100% - 10px);
    }
  }

  .count-digit {
    display: flex;
    justify-content: flex-end;
    color: $gray-shade-10;
    font-size: 12px;
    font-family: $secondary-font-book;
  }

  .input-label {
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 500;
    color: $gray-shade-1;
    margin-bottom: 15px;
  }

  label {
    font-family: $secondary-font-book;
    font-size: 14px;
    color: $blue;
  }

  .custom-input {
    height: 56px;
    border-radius: 28px;
    padding: 16px 52px 16px 52px;
    border: solid 2px $gray-shade;
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 400;

    @include bp(sm-max) {
      padding: 16px 20px 16px 20px;
      font-size: 14px;
    }

    &.secondary-input {
      height: 40px;
      border-radius: 10px;
      padding: 5px 15px;

      @include bp(sm-max) {
        height: 35px;
      }

      &::placeholder {
        color: $gray-shade-11;
        font-size: 12px;
      }

      &:disabled {
        background-color: $gray-shade-2;
      }
    }

    &.type-mail {
      padding: 16px 66px 16px 52px;
      background-image: url("../images/email.svg");
      background-repeat: no-repeat;
      background-position: center right 32px;

      @include bp(sm-max) {
        padding: 16px 50px 16px 20px;
        background-position: center right 12px;
      }
    }

    &.type-password {
      padding: 16px 66px 16px 52px;
      background-image: url("../images/password.svg");
      background-repeat: no-repeat;
      background-position: center right 32px;

      @include bp(sm-max) {
        padding: 16px 50px 16px 20px;
        background-position: center right 12px;
      }
    }
  }

  select {
    background-image: url(../images/down-arrow.svg);
    background-repeat: no-repeat;
    background-position: center right 8px;
    padding-right: 35px !important;

    &::placeholder {
      color: $gray-shade-11;
      font-size: 12px;
    }
  }
}

// custom checkbox
.checkbox-wrapper {
  .input-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;

    &:checked ~ .checkmark {
      background-color: $color-light-green;
      border-color: $color-light-green;

      &:after {
        opacity: 1;
      }
    }
  }

  .checkmark {
    height: 18px;
    width: 18px;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 3px;
    transition: all 0.3s;

    @include bp(sm-max) {
      height: 15px;
      width: 15px;
    }

    &:after {
      content: "";
      height: 6px;
      width: 12px;
      border: 2px solid $white;
      transform: rotate(-45deg);
      position: absolute;
      border-top: 0;
      border-right: 0;
      top: 3px;
      left: 1px;
      opacity: 0;
      transition: all 0.3s;

      @include bp(sm-max) {
        height: 5px;
        width: 10px;
      }
    }
  }

  label {
    padding-left: 25px;
    position: relative;

    @include bp(sm-max) {
      padding-left: 22px;
    }
  }
}

// btn style
.btn:focus {
  box-shadow: none;
}

.rounded-fill-btn {
  border-radius: 25px;
  border: 2px solid $color-light-green;
  color: $white;
  background-color: $color-light-green;
  font-family: $secondary-font-medium;
  font-weight: 500;
  font-size: 18px;
  padding: 5px 15px;
  min-width: 150px;
  min-height: 40px;
  transition: all 0.3s;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    min-height: 35px;
    padding: 5px 10px;
  }

  &.link-btn {
    border: 2px solid $color-light-green !important;
    color: $white !important;
    background-color: $color-light-green !important;
    cursor: pointer;
  }

  &:hover {
    background-color: $white;
    color: $color-light-green;
  }

  &.blue-fill {
    border: 2px solid $blue;
    background-color: $blue;

    &:hover {
      background-color: $white;
      color: $blue;
    }
  }
}

.orange-fill-btn {
  background-color: $color-orange;
  font-family: $secondary-font-medium;
  font-weight: 500;
  color: $white;
  font-size: 16px;
  min-width: 150px;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    height: 35px;
    padding: 5px 10px;
  }

  &:hover {
    color: $white;
  }

  &.with-icon {
    display: flex;
    align-items: center;

    img {
      margin: 3px 10px 0 30px;
    }
  }
}

.red-fill-btn {
  background-color: $red-shade-1;
  font-family: $secondary-font-medium;
  font-weight: 500;
  color: $white;
  font-size: 16px;
  min-width: 150px;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    height: 35px;
    padding: 5px 10px;
  }
}

.white-fill-btn {
  background-color: $white;
  font-family: $secondary-font-medium;
  font-weight: 500;
  font-size: 18px;
  color: $blue;
  font-size: 16px;
  min-width: 150px;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    height: 35px;
    padding: 5px 10px;
  }
}

.gray-fill-btn {
  background-color: $color-grey;
  font-family: $secondary-font-medium;
  font-weight: 500;
  font-size: 18px;
  color: $blue;
  font-size: 16px;
  min-width: 150px;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    height: 35px;
    padding: 5px 10px;
  }
}

.rounded-border-btn {
  border-radius: 25px;
  border: 2px solid $color-light-green;
  color: $color-light-green;
  font-family: $secondary-font-medium;
  font-weight: 500;
  font-size: 18px;
  padding: 5px 15px;
  min-height: 40px;
  transition: all 0.3s;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    height: 35px;
    padding: 5px 10px;
  }

  &:hover {
    background-color: $color-light-green;
    color: $white;
  }
}

.gray-border-btn {
  border-radius: 25px;
  border: 2px solid $gray-shade;
  color: $blue;
  font-family: $secondary-font-medium;
  font-weight: 500;
  font-size: 18px;
  padding: 5px 15px;
  min-height: 40px;
  transition: all 0.3s;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    min-height: 35px;
    padding: 5px 10px;
  }
}

.text-btn {
  min-width: 100px;
  padding: 5px 15px;
  font-family: $secondary-font-medium;
  font-size: 18px;

  @include bp(sm-max) {
    font-size: 14px;
    line-height: 1;
    min-width: 120px;
    height: 35px;
  }

  &.blue-text-btn {
    color: $blue;
  }
}

// ------------------------Typography Css
h1 {
  font-size: 48px;
  font-family: $secondary-font-bold;

  span {
    display: block;
  }

  @include bp(lg-max) {
    font-size: 44px;
  }

  @include bp(sm-max) {
    font-size: 38px;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.slider-wrapper {
  .MuiSlider-root {
    height: 5px;
    margin-bottom: 60px;
  }

  .MuiSlider-thumb {
    background-color: $color-light-green;
    margin-left: -11px;
    width: 22px;
    height: 22px;
    margin-top: -8px;

    [class^="PrivateValueLabel-circle"] {
      background-color: $color-light-green !important;
      height: 25px;
      width: auto;
      transform: none;
      border-radius: 0;
      padding: 2px 10px;

      span {
        transform: none;
      }
    }
    span {
      &:first-child {
        &:before {
          border: solid;
          border-color: $color-light-green transparent;
          border-width: 7px 4px 0 4px;
          content: '';
          left: 40%;
          bottom: -7px;
          position: absolute;
        }
        span {
          &:first-child {
            background-color: $color-light-green !important;
            height: 25px;
            width: auto;
            transform: none;
            border-radius: 4px;
            padding: 6px 10px;
            margin-left: 0;
            span {
              transform: none;
            }
          }
        }
      }
    }
  }
  .MuiSlider-valueLabel {
    top: -25px;
    left: calc(-50% - 13px);
  }
  .MuiSlider-rail {
    height: 5px;
    background-color: $gray-shade;
    opacity: 1;
  }

  .MuiSlider-mark {
    width: 0;
  }

  .MuiSlider-track {
    height: 5px;
    background-color: $color-light-green;
  }

  .MuiSlider-markLabel {
    font-size: 16px;
    font-weight: 600;
  }

  &.orange-slider {
    .MuiSlider-thumb {
      background-color: $orange-shade !important;

      // .PrivateValueLabel-circle-4 {
      [class^="PrivateValueLabel-circle"] {
        background-color: $orange-shade !important;
        height: 25px;
        width: auto;
        transform: none;
        border-radius: 0;
        padding: 2px 10px;

        span {
          transform: none;
        }
      }
      span {
        &:first-child {
          &:before {
            border: solid;
            border-color: $orange-shade transparent;
            border-width: 7px 4px 0 4px;
            content: '';
            left: 40%;
            bottom: -7px;
            position: absolute;
          }
          span {
            &:first-child {
              background-color: $orange-shade !important;
              height: 25px;
              width: auto;
              transform: none;
              border-radius: 4px;
              padding: 6px 10px;
              margin-left: 0;
              span {
                transform: none;
              }
            }
          }
        }
      }
    }

    .MuiSlider-track {
      background-color: $orange-shade !important;
    }
  }
}

.react-datepicker-wrapper {
  input {
    height: 39px;
    border-radius: 10px;
    padding: 5px 15px;
    border: solid 2px $gray-shade;
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 400;

    @include bp(sm-max) {
      height: 35px;
      font-size: 14px;
    }

    &::placeholder {
      color: $gray-shade-11;
      font-size: 12px;
    }
  }
}

// table
table.custom-table {
  thead {
    tr {
      .MuiTableCell-root {
        background-color: $grey-shade-19;
        padding: 5px 16px;
        min-height: 33px;

        @include bp(sm-max) {
          padding: 5px 12px;
        }
      }
    }
  }

  tr {
    .MuiTableCell-root {
      font-family: $secondary-font-medium;
      font-size: 16px;
      color: $blue;
      border: none;
      padding: 10px 16px;

      @include bp(sm-max) {
        padding: 5px 12px;
      }
    }
  }
}

.error {
  @include bp(sm-max) {
    font-size: 14px;
  }
}
