/* Use prefix op for all custom class */
button {
  @apply font-lato font-bold;
}

button:disabled {
  opacity: 0.5;
}

.button:focus,
.button:hover {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  outline: none;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
