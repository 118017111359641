.home-banner-wrapper {
  background-color: $color-light-green;
  .container-main {
    background-image: url(../images/home-page-banner.png);
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
  .content-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 320px 0 265px;
    @include bp(lg-max) {
      padding: 180px 0;
    }
    @include bp(sm-max) {
      padding: 150px 0 80px;
    }
  }
  h1 {
    font-family: $secondary-font-medium;
    font-size: 72px;
    color: $white;
    margin-bottom: 55px;
    @include bp(lg-max) {
      font-size: 52px;
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .search-input {
    height: 56px;
    width: 665px;
    max-width: calc(100% - 15px);
    border: solid 1px $gray-shade-2;
    background-color: $white;
    background-image: url(../images/icon-search-orange.svg);
    padding: 0 52px;
    font-family: $secondary-font-book;
    font-size: 20px;
    color: $gray-shade-3;
    background-repeat: no-repeat;
    background-position: center left 17px;
    border-radius: 30px;
    margin-bottom: 25px;
    @include bp(lg-max) {
      padding: 0 15px 0 38px;
      font-size: 16px;
      background-size: 20px;
      background-position: center left 12px;
      &::placeholder {
        font-size: 14px;
        line-height: 1;
      }
    }
  }
  .orange-fill-btn {
    margin-bottom: 115px;
    @include bp(lg-max) {
      margin-bottom: 30px;
    }
  }
  p {
    font-family: $fonts-montserrat;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: $white;
    text-align: center;
  }
}

.auth-btns-space {
  .btn-mr {
    margin-right: 24px;
  }
  .rounded-fill-btn {
    &.blue-fill ,&.green-fill {
      @include bp(md-max) {
        min-width: 120px;
      }
    }
  }
}
