.site-header {
  padding: 25px 0;
  z-index: 9;
  &.fixed-header {
    box-shadow: 0 2px 4px 0 rgba(95, 95, 95, 0.5);
    background-color: $white;
    padding: 23px 0;
  }
  &.pre-login {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $color-light-green;
    .link-wrapper {
      ul {
        display: flex;
        align-items: center;
        li {
          a {
            color: $white;
            &:after {
              background-color: $white;
            }
          }
        }
      }
    }
    .right-header {
      @include bp(sm-max) {
        position: fixed;
        top: -100%;
        bottom: 0;
        left: 0;
        background: $color-light-green;
        height: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 3;
        transition: all 0.5s;
        .blue-fill {
          margin: 10px 0;
        }
      }
    }
  }
  .container-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left-header {
    display: flex;
    align-items: center;
    .page-logo {
      max-width: 180px;
      margin-right: 60px;
      @include bp(md-max) {
        margin-right: 30px;
      }
    }
  }
  .link-wrapper {
    @include bp(sm-max) {
      position: fixed;
      top: -100%;
      bottom: 0;
      left: 0;
      background: $color-light-green;
      height: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 3;
      transition: all 0.5s;
      .blue-fill {
        margin: 10px 0;
      }
    }
    ul {
      display: flex;
      @include bp(sm-max) {
        flex-direction: column;
        align-items: center;
      }
      li {
        a {
          font-size: 18px;
          color: $blue;
          font-family: $secondary-font-medium;
          padding: 10px 5px;
          position: relative;
          transition: all 0.3s;
          margin-right: 30px;
          display: inline-block;
          white-space: nowrap;
          @include bp(md-max) {
            margin-right: 20px;
          }
          &:after {
            content: "";
            height: 3px;
            background-color: $color-light-green;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            transition: all 0.3s;
            @include bp(sm-max) {
              background-color: $blue;
            }
          }
          &:hover {
            &:after {
              right: 0;
              width: 100%;
            }
          }
          &.active {
            &:after {
              right: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .right-header {
    display: flex;
    @include bp(sm-max) {
      display: none;
    }
    .blue-fill {
      height: 45px;
      min-width: 150px;
    }
  }
}
.profile-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  .content {
    .name {
      font-family: $secondary-font-bold;
      font-size: 14px;
      color: $blue;
    }
    .logout {
      font-family: $secondary-font-medium;
      font-size: 14px;
      color: $color-light-green;
      cursor: pointer;
    }
  }
  .dropdown-list {
    position: absolute;
    background: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    top: calc(100% + 10px);
    right: 0;
    min-width: 170px;
    z-index: 9;
    li {
     a{
      padding: 5px 15px;
      font-size: 14px;
      font-family: $secondary-font-book;
      color: $blue;
      display: block;
      display: flex;
      align-items: center;
      img{
        height: 15px;
        min-width: 15px;
        margin-right: 13px;
      }
      &.buyer-link{
        background-color: $color-light-green;
        color: $white;
        padding: 8px 15px;
      }
     }
    }
  }
  .profile-image {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    overflow: hidden;
    background-color: $gray-shade;
    margin-left: 20px;
  }
  .icon {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: $gray-shade;
    background-image: url(../../../images/image-arrow.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 4px;
    transform: rotate(90deg);
    margin-right: 20px;
    cursor: pointer;
  }
}
// overwrite css for selleer page
.seller-page-header {
  .left-header {
    ul {
      li {
        a {
          &:after {
            background-color: $orange-shade;
            @include bp(sm-max) {
              background-color: $blue;
            }
          }
        }
      }
    }
  }
}
.mobile-menu {
  display: none;
  z-index: 5;
  &.sidebar-toggle{
    @include bp(lg-max) {
      display: inline-block;
      margin-right: 10px;
    }
  }
  @include bp(sm-max) {
    display: block;
  }
  ul {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
  }
  ul li {
    list-style: none;
    position: absolute;
    background: $blue;
    width: 100%;
    height: 4px;
    transform: translateY(-50%);
    transition: 0.3s;
  }
  ul li:nth-of-type(1) {
    top: 20%;
  }
  ul li:nth-of-type(2) {
    top: 50%;
  }
  ul li:nth-of-type(3),
  ul li:nth-of-type(4) {
    width: 50%;
    top: 80%;
  }
  ul li:nth-of-type(1),
  ul li:nth-of-type(2),
  ul li:nth-of-type(3) {
    left: 0;
  }
  ul li:nth-of-type(4) {
    right: 0;
  }

  ul.active li:nth-of-type(1) {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  ul.active li:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
  ul.active li:nth-of-type(3) {
    left: -50%;
    opacity: 0;
  }
  ul.active li:nth-of-type(4) {
    right: -50%;
    opacity: 0;
  }
}

.menu-open {
  // overflow: hidden;
  .link-wrapper {
    top: 0;
    height: 100%;
  }
}
