footer {
  background: $blue-shade-2;
  padding: 30px 0;
  .container-main {
    display: flex;
    flex-wrap: wrap;
  }
  .left-footer {
    flex: 0 0 310px;
    max-width: 310px;
    @include bp(sm-max) {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0 auto 30px;
    }
    .logo {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      i {
        display: inline-block;
        max-width: 50px;
        margin-right: 15px;
      }
      span {
        font-family: $secondary-font-bold;
        font-size: 25px;
        color: $white;
        @include bp(sm-max) {
          font-size: 22px;
        }
      }
    }
    .field-wrapper {
      input {
        margin-bottom: 20px;
        height: 45px;
      }
      .btn {
        min-height: 45px;
        width: 100%;
      }
    }
  }
  .right-footer {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 calc(100% - 310px);
    max-width: calc(100% - 310px);
    padding-left: 80px;
    @include bp(md-max) {
      padding-left: 20px;
    }
    @include bp(sm-max) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
    }
    .footer-links {
      flex: 0 0 19%;
      max-width: 19%;
      @include bp(lg-max) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        margin-bottom: 30px;
      }
      @include bp(s-max) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 20px;
      }
      &.larg-width {
        flex: 0 0 24%;
        max-width: 24%;
        @include bp(lg-max) {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }
        @include bp(md-max) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include bp(sm-max) {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }
        @include bp(s-max) {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 20px;
        }
      }
      &:last-child {
        @include bp(md-max) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include bp(sm-max) {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }
        @include bp(s-max) {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 20px;
        }
      }
      h3 {
        font-family: $secondary-font-medium;
        font-size: 18px;
        letter-spacing: 1.29px;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 20px;
        @include bp(sm-max){
          margin-bottom: 10px;
        }
      }
      li {
        a {
          font-family: $fonts-montserrat;
          font-size: 15px;
          line-height: 1;
          font-weight: 500;
          color: $white;
          display: inline-block;
          margin-bottom: 10px;
          @include bp(sm-max){
            font-size: 13px;
            margin-bottom: 3px;
          }
        }
      }
    }
  }
  .bottom-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include bp(md-max) {
      flex-wrap: wrap;
    }
    .media-icon {
      display: flex;
      align-items: center;
      @include bp(md-max) {
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
    .icon-wrapper {
      margin-right: 35px;
      img {
        max-width: 30px;
      }
      &.facebook-icon {
        img {
          max-width: 17px;
        }
      }
    }
    .copy-right {
      font-family: $secondary-font-medium;
      font-size: 18px;
      color: $white;
      padding-left: 100px;
      @include bp(lg-max) {
        padding: 0;
      }
      @include bp(md-max) {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
      @include bp(sm-max) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .link-wrapper {
      @include bp(md-max) {
        width: 100%;
        text-align: center;
      }
      a {
        font-family: $secondary-font-medium;
        font-size: 18px;
        color: $white;
        margin-left: 32px;
        &:first-child {
          margin-left: 0;
        }
        @include bp(sm-max) {
          font-size: 14px;
        }
      }
    }
  }
}
