.buyer-dashboard {
  .welcome-section {
    button {
      white-space: nowrap;
      min-width: 175px;
    }
  }
}
.section-card {
  padding: 44px 30px 44px 37px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: $white;
  border-radius: 20px;
  padding: 25px 35px;
  margin-bottom: 20px;
  .sec-grid-item {
    .sec-grid-title {
      font-family: $secondary-font-medium;
      font-size: 16px;
      color: $gray-shade-9;
    }
    .sec-grid-text {
      font-family: $secondary-font-medium;
      color: $blue;
      font-size: 20px;
    }
  }
}
.buyer-dashboard {
  .section-card {
    @include bp(md-max) {
      padding: 20px;
    }
    @include bp(sm-max) {
      display: flex;
      flex-wrap: wrap;
    }
    .sec-grid-item {
      @include bp(sm-max) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 20px;
      }
      @include bp(s-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .content-block-wrapper {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    @include bp(sm-max) {
      flex-direction: column;
    }
    > .left-block {
      flex: 0 0 35%;
      max-width: 35%;
      background: $white;
      border-radius: 20px;
      padding: 25px 35px;
      @include bp(xl-max) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      @include bp(md-max) {
        padding: 20px;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 20px;
        display: flex;
        flex-wrap: wrap;
      }
      .block-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        @include bp(xl-max) {
          align-items: flex-start;
          flex-direction: column;
        }
        @include bp(sm-max){
          flex: 0 0 100%;
          max-width: 100%;
        }
        h2 {
          font-family: $secondary-font-medium;
          color: $blue;
          font-size: 16px;
          @include bp(xl-max) {
            margin-bottom: 15px;
          }
        }
        .refresh-block {
          color: $orange-shade;
          display: flex;
          align-items: center;
          font-family: $secondary-font-medium;
          font-size: 18px;
          img {
            max-width: 20px;
            margin-right: 10px;
          }
        }
      }
      .detail-wrapper {
        margin-bottom: 30px;
        @include bp(sm-max){
          flex:  0 0 50%;
          max-width: 50%;
        }
        @include bp(s-max){
          flex: 0 0 100%;
          max-width: 100%;
        }
        .title {
          font-family: $secondary-font-medium;
          font-size: 16px;
          color: $gray-shade-5;
        }
        .value {
          font-family: $secondary-font-medium;
          font-size: 22px;
          color: $blue;
          @include bp(sm-max) {
            font-size: 18px;
          }
        }
      }
    }
    > .right-block {
      flex: 0 0 calc(65% - 10px);
      max-width: calc(65% - 10px);
      display: flex;
      flex-wrap: wrap;
      @include bp(xl-max) {
        flex: 0 0 calc(70% - 10px);
        max-width: calc(70% - 10px);
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .block-wrapper {
        background-color: $white;
        border-radius: 10px;
        padding: 15px;
        flex: 0 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        margin: 0 10px 20px;
        display: flex;
        align-items: flex-start;
        @include bp(md-max) {
          flex: 0 0 calc(100% - 10px);
          max-width: calc(100% - 10px);
          margin-right: 0;
        }
        @include bp(sm-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin: 0 0 20px;
          padding: 15px 10px;
        }
        .left-block {
          width: calc(100% - 100px);
          display: flex;
          flex-direction: column;
          @include bp(md-max) {
            width: 100%;
            margin-bottom: 15px;
          }
          @include bp(sm-max) {
            width: calc(100% - 100px);
          }
          .label {
            font-family: $secondary-font-medium;
            font-size: 14px;
            color: $gray-shade-3;
          }
          .value {
            font-family: $secondary-font-bold;
            font-size: 24px;
            color: $color-light-green;
            line-height: 1;
          }
          .link-wrapper {
            display: flex;
            align-items: center;
            margin-top: auto;
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue;
            @include bp(sm-max) {
              font-size: 14px;
            }
            img {
              width: 20px;
              margin-left: 10px;
            }
          }
        }
        .chart-wrapper {
          width: 100px;
          canvas {
            margin-top: -10px;
          }
          .content {
            margin: 0;
          }
        }
      }
      .rented-property-block {
        flex: 0 0 calc(100% - 20px);
        max-width: calc(100% - 20px);
        margin: 0 10px;
        background-color: $white;
        border-radius: 10px;
        padding: 15px;
        @include bp(md-max) {
          flex: 0 0 calc(100% - 10px);
          max-width: calc(100% - 10px);
          margin-right: 0;
        }
        @include bp(sm-max) {
          flex: 0 0 100%;
          max-width: 100%;
          margin: 0;
        }
        h2 {
          margin-bottom: 20px;
          font-family: $secondary-font-medium;
          font-size: 16px;
          color: $blue;
        }
        .detail-wrapper {
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;
          @include bp(md-max) {
            flex-wrap: wrap;
          }
          .image-block {
            width: 70px;
            min-width: 70px;
            height: 70px;
            margin-right: 10px;
            border-radius: 10px;
            overflow: hidden;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .content {
            @include bp(md-max) {
              width: calc(100% - 80px);
            }
            @include bp(s-max) {
              width: 100%;
              margin-top: 10px;
            }
            h5 {
              font-family: $secondary-font-medium;
              font-size: 18px;
              color: $blue;
            }
            .address-detail {
              font-family: $secondary-font-medium;
              font-size: 16px;
              color: $gray-shade-5;
            }
            .room-detail {
              .room-span {
                font-family: $secondary-font-medium;
                font-size: 16px;
                color: $blue;
                padding: 0 15px;
                border-right: 1px solid $gray-shade-11;
                display: inline-block;
                line-height: 10px;
                &:first-child {
                  padding-left: 0;
                }
                &:last-child {
                  padding-right: 0;
                  border-right: 0;
                }
              }
            }
          }
          .btn-wrapper {
            margin-left: auto;
            @include bp(md-max) {
              margin: 20px 0 0;
            }
          }
        }
        .display-payment-detail {
          border: solid 1px $gray-shade-9;
          border-radius: 10px;
          padding: 15px;
          display: flex;
          @include bp(md-max) {
            flex-direction: column;
          }
          .left-block,
          .right-block {
            flex: 0 0 50%;
            max-width: 50%;
            position: relative;
            padding-right: 20px;
            @include bp(md-max) {
              flex: 0 0 100%;
              max-width: 100%;
            }
            .detail-block {
              display: flex;
              margin-bottom: 5px;
              @include bp(xxs-max){
                flex-direction: column;
              }
              .label {
                font-family: $secondary-font-medium;
                font-size: 16px;
                color: $gray-shade-5;
                min-width: 150px;
                margin-right: 10px;
              }
            }
          }
          .left-block {
            &:after {
              content: '';
              height: 65px;
              position: absolute;
              width: 1px;
              background-color: $gray-shade-9;
              right: 20px;
              top: 0;
              @include bp(md-max) {
                display: none;
              }
            }
          }
          .right-block {
            .download-btn {
              display: flex;
              align-items: center;
              padding: 0;
              img {
                max-width: 20px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  .bottom-block {
    .left-block {
      flex: 0 0 100%;
      max-width: 100%;
      button{
        height: 35px;
      }
      .content-block {
        position: relative;
        .info {
          max-width: 60%;
          padding-right: 50px;
          @include bp(xs-max){
            max-width: 100%;
          }
        }
        .reply-image {
          display: inline-block;
          max-width: 30px;
          position: absolute;
          bottom: 20px;
          right: 0;
        }
      }
    }
  }
}
