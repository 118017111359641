.map-wrapper {
  display: flex;
  flex-wrap: wrap;
  min-height: 90vh;
  > .left-block {
    flex: 0 0 45%;
    max-width: 45%;
    background-color: $gray-shade-3;
    height: 100%;
    @include bp(xl-max) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @include bp(lg-max) {
      flex: 0 0 100%;
      max-width: 100%;
      height: 70vh;
    }
  }
  > .right-block {
    flex: 0 0 55%;
    max-width: 55%;
    padding: 15px 25px;
    @include bp(xl-max) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @include bp(lg-max) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .filter-block-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      @include bp(sm-max) {
        flex-wrap: wrap;
      }
      .right-filter {
        display: flex;
        @include bp(sm-max) {
          width: 100%;
          margin-top: 20px;
          justify-content: flex-end;
        }
      }
      .filter-btn {
        padding: 5px 12px;
        height: 46px;
        background-color: $blue;
        border-radius: 4px;
        color: $white;
        display: flex;
        align-items: center;
        min-width: 100px;
        justify-content: center;
        font-family: $secondary-font-book;
        font-size: 16px;
        i {
          margin-left: 14px;
        }
      }
      .sort-btn {
        padding: 5px 12px;
        height: 46px;
        background-color: $color-grey;
        border-radius: 4px;
        color: $blue;
        display: flex;
        align-items: center;
        min-width: 100px;
        justify-content: center;
        font-family: $secondary-font-book;
        font-size: 16px;
        margin-right: 15px;
        i {
          margin-left: 14px;
        }
      }
    }
    .property-wrapper {
      margin: 0 -15px;
      height: calc( 100% - 76px);
      .property-block {
        margin: 0 15px 30px;
        flex: 0 0 calc(50% - 30px);
        max-width: calc(50% - 30px);
        position: relative;
        display: flex;
        flex-direction: column;
        @include bp(xl-max) {
          margin: 0 10px 30px;
          flex: 0 0 calc(50% - 20px);
          max-width: calc(50% - 20px);
        }
        @include bp(sm-max) {
          margin: 0 10px 30px;
          flex: 0 0 calc(100% - 20px);
          max-width: calc(100% - 20px);
        }
        &:after {
          content: "";
          position: absolute;
          top: -6px;
          bottom: -6px;
          left: -6px;
          right: -6px;
          border-radius: 8px;
          border: 3px solid $color-light-green;
          z-index: -1;
        }
        &.orange-border {
          &::after {
            border-color: $orange-shade;
          }
        }
        &.red-border {
          &::after {
            border-color: $red;
          }
        }
        .btn-wrapper {
          display: flex;
          padding: 0 15px;
          justify-content: space-between;
          margin-bottom: 20px;
          margin-bottom: 25px;
          margin-top: auto;
          @include bp(s-max) {
            flex-wrap: wrap;
          }
          button {
            font-size: 16px;
            line-height: 1;
            padding: 0 15px;
            min-width: auto;
            cursor: pointer;
            min-height: 40px;
            @include bp(s-max) {
              width: 100%;
              margin-bottom: 10px;
            }
            &.gray-fill-btn {
              &.active {
                background-color: $blue;
                color: $white;
              }
            }
          }
        }
      }
      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .compare-home-count {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 9;
    border-radius: 0;
    border: 2px solid $blue;
  }
}
.filter-tab {
  height: 34px;
  border: 1px solid $light-blue;
  border-radius: 20px;
  background-color: $light-blue-bg;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  margin-right: 20px;
  span {
    font-family: $secondary-font-book;
    font-size: 16px;
    line-height: 16px;
    color: $blue;
    margin-right: 12px;
  }
  i {
    cursor: pointer;
    display: inline-flex;
    width: 12px;
    min-width: 12px;
    align-items: center;
  }
}
.filter-modal {
  @include bp(md-max) {
    padding-left: 0;
  }
  .modal-lg {
    max-width: 58%;
    margin: 60px 0px 20px auto;
    min-height: calc(100% - 80px);
    align-items: flex-start;
    @include bp(lg-max) {
      margin: 28px auto;
      max-width: 80%;
    }
    @include bp(md-max) {
      margin: 25px auto;
      max-width: calc(100% - 50px);
    }
    @include bp(xs-max) {
      margin: 0px auto;
      max-width: 100%;
    }
    .modal-content {
      @include bp(lg-max) {
        width: 100%;
      }
    }
  }
  .filter-header {
    padding: 10px;
    border-bottom: 1px solid $light-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-icon {
      position: absolute;
      right: 20px;
    }
    .search-wrapper {
      width: 100%;
      position: relative;
      .tag-wrapper {
        display: flex;
        flex-wrap: wrap;
        .filter-tab {
          margin: 3px 5px;
          svg {
            height: 20px;
            width: 20px;
            color: $blue;
            cursor: pointer;
          }
          span {
            white-space: nowrap;
          }
        }
      }
      .list-wrapper {
        position: absolute;
        background-color: $white;
        left: -10px;
        right: -10px;
        z-index: 2;
        top: calc(100% + 12px);
        box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);
        border-radius: 0 0 10px 10px;
        overflow: auto;
        max-height: 180px;
        li {
          padding: 10px;
          margin-bottom: 2px;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
            border-radius: 0 0 10px 10px;
          }
          span {
            &.check-icon {
              height: 10px;
              width: 17px;
              border: 3px solid $white;
              margin-left: 10px;
              border-top: 0;
              border-right: 0;
              transform: rotate(-45deg);
              margin-top: -5px;
              display: none;
            }
          }
          svg {
            opacity: 0;
            visibility: hidden;
            color: $blue;
            color: #22456e;
            margin-top: -5px;
            margin-left: 6px;
          }
          &[aria-selected="true"] {
            background-color: rgba(108, 185, 255, 0.2);
            color: $blue;
            svg {
              opacity: 1;
              visibility: visible;
              color: $blue;
            }
            span {
              &.check-icon {
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .input-wrapper {
      flex: 1;
      input {
        height: 40px;
        width: 100%;
        padding: 10px;
      }
    }
  }
  .filter-body {
    padding: 20px;
    @include bp(xs-max){
      padding: 20px 10px;
    }
    .filter-wrapper {
      margin-bottom: 20px;
    }
    .filter-tab {
      width: 130px;
      margin: 0 15px 10px 0;
      @include bp(xs-max){
        margin: 0 5px 10px 0;
      }
      position: relative;
      cursor: pointer;
      &.active {
        i {
          transform: rotate(-90deg);
        }
      }
      &:last-child {
        margin-right: 0;
      }
      i {
        position: absolute;
        right: 10px;
      }
      span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .clear-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .left-filter {
      color: $blue;
      font-family: $secondary-font-book;
      font-size: 14px;
    }
    .right-filter {
      color: $red;
      font-family: $secondary-font-book;
      font-size: 14px;
    }
  }
  .filter-content {
    .slider-title {
      font-family: $secondary-font-bold;
      font-size: 14px;
      color: $blue;
      margin-bottom: 10px;
    }
    .prize-range {
      color: $blue;
      font-family: $secondary-font-book;
      font-size: 14px;
    }
    .slider-wrapper {
      padding: 50px 50px 20px;
    }
    .sub-checkbox {
      margin: 10px 0 10px 40px;
    }
    .checkbox-wrapper {
      margin-bottom: 10px;
      label {
        font-family: $primary-font;
        font-size: 18px;
        color: $blue;
      }
    }
    .checkbox-title {
      font-family: $secondary-font-bold;
      font-size: 16px;
      color: $blue;
      margin: 10px 0;
    }
    .check-outer {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      .checkbox-wrapper {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 10px;
      }
    }
    &.more-filter {
      display: flex;
      @include bp(sm-max) {
        flex-wrap: wrap;
      }
      .left-block,
      .right-block {
        flex: 0 0 50%;
        max-width: 50%;
        @include bp(sm-max) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .right-block {
        padding-left: 35px;
        @include bp(sm-max) {
          padding-left: 0;
        }
      }
      .check-outer {
        width: 100%;
        margin-bottom: 20px;
      }
      .field-wrapper {
        display: flex;
        align-items: center;
        @include bp(xs-max){
          flex-wrap: wrap;
        }
        label {
          font-family: $secondary-font-bold;
          font-size: 16px;
          color: $blue;
          min-width: 140px;
          @include bp(xs-max){
            display: block;
          }
        }
        .select-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .dash {
            height: 2px;
            width: 11px;
            background: $blue;
            top: 50%;
            display: block;
          }
          .custom-input {
            &.secondary-input {
              width: calc(50% - 20px);
            }
          }
        }
        input {
          &.custom-input {
            &.secondary-input {
              border: solid 1px rgba(45, 13, 13, 0.12);
              background: none;
              padding: 5px 10px;
              &::placeholder {
                font-size: 16px;
              }
            }
          }
        }
        .custom-input {
          &.secondary-input {
            border-radius: 0;
            height: 46px;
            border: none;
            border-bottom: solid 1px rgba(45, 13, 13, 0.12);
            padding: 0;
            background-image: url(../images/arrow-down.svg);
            background-position: center right 15px;
            background-repeat: no-repeat;
          }
        }
        .checkbox-wrapper {
          label {
            font-family: $primary-font;
            font-size: 18px;
            color: $blue;
          }
        }
      }
    }
    .sale-block-wrapper {
      display: flex;
      .sale-block {
        width: 122px;
        height: 115px;
        border-radius: 8px;
        border: solid 1px $color-light-green;
        background-color: $white;
        padding: 25px 25px 15px 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 40px 40px 0;
        @include bp(xs-max){
          margin: 0 10px 20px 0;
        }
        img {
          margin-bottom: 10px;
          &.active-image {
            display: none;
          }
        }
        span {
          margin-top: auto;
          text-align: center;
          font-family: $secondary-font-bold;
          color: $blue;
        }
        &.active {
          background-color: $orange-shade;
          img {
            display: none;
            &.active-image {
              display: inline-block;
            }
          }
          span {
            color: $white;
          }
        }
      }
    }
  }
  .btn-wrapper {
    margin-top: 10px;
    button {
      min-width: 120px;
      margin-right: 30px;
      font-family: $secondary-font-book;
      font-size: 14px;
      letter-spacing: 1.25px;
      @include bp(xs-max){
        margin-right: 10px;
      }
    }
  }
}
