.chat-block-wrapper {
  display: flex;
  background-color: $white;
  border-radius: 10px;
  width: 100%;
  min-height: calc(100vh - 210px);
  overflow-x: hidden;
  .chat-list-wrapper {
    flex: 0 0 350px;
    max-width: 350px;
    border-right: 1px solid $grey-shade-15;
    transition: all 0.5s;
    &:hover {
      @include bp(md-max) {
        flex: 0 0 350px;
        max-width: 350px;
      }
      @include bp(sm-max) {
        flex: 0 0 210px;
        max-width: 210px;
      }
      .chat-block {
        .right-block {
          @include bp(md-max) {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    @include bp(md-max) {
      flex: 0 0 80px;
      max-width: 80px;
    }
    @include bp(sm-max) {
      flex: 0 0 55px;
      max-width: 55px;
    }
    .search-wrapper {
      padding: 30px 20px 20px;
      @include bp(sm-max) {
        padding: 10px;
      }
      input {
        height: 40px;
        padding: 5px 20px 5px 45px;
        border-radius: 10px;
        border: solid 1px $gray-shade-9;
        background-color: rgba(255, 255, 255, 0.87);
        width: 100%;
        background-image: url(../images/iconSearch.svg);
        background-repeat: no-repeat;
        background-position: center left 10px;
        background-size: 20px;
        &:placeholder {
          @include bp(sm-max) {
            font-size: 14px;
          }
        }
        @include bp(md-max) {
          padding: 5px 0 5px 40px;
        }

        @include bp(sm-max) {
          height: 35px;
          background-size: 18px;
          padding: 5px 0 5px 35px;
        }
      }
    }

    .chat-block {
      display: flex;
      padding: 15px 25px 15px 20px;
      position: relative;
      border-bottom: solid 1px $grey-shade-15;

      @include bp(sm-max) {
        padding: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 6px;
        background-color: $color-light-green;
        display: none;
      }

      .image-block {
        height: 35px;
        min-width: 35px;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        margin-right: 15px;
      }

      .right-block {
        position: relative;
        width: calc(100% - 35px);
        @include bp(md-max) {
          visibility: hidden;
          opacity: 0;
          transition: all 0.5s;
        }

        .top-block {
          display: flex;
          align-items: center;

          .mail-icon {
            // margin: 2px 0 0 10px;
            margin: 2px 5px 0px 2px;
            max-width: 15px;
          }

          .name {
            font-family: $secondary-font-medium;
            font-size: 16px;
            color: $blue;
            white-space: nowrap;
            display: inline-block;
            white-space: nowrap;
            max-width: 190px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;

            @include bp(sm-max) {
              font-size: 14px;
              max-width: 120px;
            }
          }

          .msg-time {
            @include bp(md-max) {
              display: none;
            }
            font-family: $secondary-font-medium;
            font-size: 13px;
            color: $gray-shade-5;
            margin-left: auto;

            @include bp(sm-max) {
              font-size: 12px;
            }
          }
        }

        .msg-content {
          @include bp(md-max) {
            display: none;
          }
          p {
            font-family: $secondary-font-book;
            font-size: 15px;
            line-height: 18px;
            color: $gray-shade-5;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @include bp(sm-max) {
              font-size: 13px;
            }
          }
        }
      }

      &.active-chat {
        background-color: $grey-shade-17;

        &:after {
          display: block;
        }
      }
    }
  }

  .chat-content {
    flex: 0 0 calc(100% - 350px);
    max-width: calc(100% - 350px);
    display: flex;
    flex-direction: column;

    @include bp(md-max) {
      flex: 0 0 calc(100% - 80px);
      max-width: calc(100% - 80px);
    }

    @include bp(sm-max) {
      flex: 0 0 calc(100% - 55px);
      max-width: calc(100% - 55px);
    }

    .content-header {
      border-bottom: solid 1px $grey-shade-15;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 40px 20px 60px;

      @include bp(xlg-max) {
        padding: 20px 30px 20px 40px;
      }

      @include bp(md-max) {
        padding: 20px 20px 20px 30px;
      }

      @include bp(xs-max) {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 10px 0;
      }

      .title {
        font-family: $secondary-font-medium;
        font-size: 20px;
        color: $blue;

        @include bp(xlg-max) {
          margin: 0 15px 0 0;
        }

        @include bp(sm-max) {
          font-size: 18px;
        }

        @include bp(xs-max) {
          margin-bottom: 15px;
        }
      }

      .btn-wrapper {
        @include bp(sm-max) {
          display: flex;
          flex-wrap: wrap;
        }

        button {
          font-size: 16px;
          min-width: 120px;
          height: 40px;
          line-height: 1;
          // margin-left: 15px;
          margin-right: 15px;

          @include bp(xlg-max) {
            min-width: 85px;
          }

          @include bp(sm-max) {
            font-size: 14px;
            height: 35px;
            min-height: 35px;
            padding: 5px;
          }

          @include bp(xxs-max) {
            margin-bottom: 10px;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .content-block {
      padding: 12px 40px 12px 60px;
      display: flex;
      flex-direction: column;
      flex: 1;

      @include bp(xl-max) {
        padding: 12px 20px;
      }

      @include bp(md-max) {
        padding: 12px;
      }

      .payment-block {
        padding: 10px 35px;
        border: solid 1px $grey-shade-16;
        border-radius: 10px;
        margin-bottom: 15px;
        width: 80%;
        @include bp(xlg-max) {
          padding: 10px 20px;
        }
        @include bp(xs-max) {
          padding: 0;
          border: none;
        }
        .head-block {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .profile-block {
            height: 30px;
            width: 30px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 10px;
          }

          .profile-name {
            font-size: 16px;
            font-family: $secondary-font-bold;
            color: $color-light-green;
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          @include bp(sm-max) {
            flex-direction: column;
          }
          .left-block {
            height: 150px;
            width: 150px;
            border-radius: 10px;
            overflow: hidden;
            object-fit: cover;
            @include bp(sm-max) {
              margin-bottom: 20px;
            }
            @include bp(xs-max) {
              height: 80px;
              width: 80px;
              margin-bottom: 0px;
            }
          }

          .right-block {
            width: calc(100% - 180px);

            @include bp(sm-max) {
              width: 100%;
            }

            .heading {
              font-family: $secondary-font-medium;
              font-size: 18px;
              color: $blue;
              margin-bottom: 12px;
              display: block;

              @include bp(sm-max) {
                font-size: 16px;
                margin-bottom: 5px;
              }
            }

            .value-wrapper {
              display: flex;
              margin-bottom: 8px;

              .title {
                font-family: $secondary-font-medium;
                font-size: 16px;
                color: $blue-shade-3;
                margin-right: 15px;

                @include bp(sm-max) {
                  font-size: 14px;
                }
              }

              .value {
                font-family: $secondary-font-medium;
                font-size: 16px;
                color: $blue;

                @include bp(sm-max) {
                  font-size: 14px;
                }
              }
            }

            .btn-wrapper {
              @include bp(xlg-max) {
                display: flex;
                flex-wrap: wrap;
              }

              button {
                height: 40px;
                line-height: 1;
                min-width: 150px;
                font-size: 16px;
                margin-right: 20px;

                @include bp(xlg-max) {
                  margin: 0 15px 15px 0;
                  min-width: 135px;
                }

                @include bp(sm-max) {
                  font-size: 14px;
                  min-width: 120px;
                  height: 35px;
                  min-height: 35px;
                  padding: 5px;
                }

                &:last-child {
                  margin: 0;
                }
              }
            }
            .invite-tile {
              font-family: $secondary-font-book;
              font-size: 16px;
              margin-bottom: 10px;
              display: block;
            }
            .time-block {
              font-family: $secondary-font-bold;
              font-size: 16px;
              margin-bottom: 10px;
              display: block;
              margin-bottom: 10px;
              color: $orange-shade;
            }
            .note {
              .label {
                color: $gray-shade-5;
                font-family: $secondary-font-medium;
              }
              .description {
                font-family: $secondary-font-book;
                font-size: 14px;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
      .send-msg-block {
        margin-top: auto;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          background-image: url(../images/attachment.svg);
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          top: 30%;
          transform: translateY(-80%);
          left: 15px;
          transform: rotate(-45deg);
          background-size: 20px;
          @include bp(sm-max) {
            left: 8px;
            top: 25%;
            transform: translateY(-75%);
            transform: rotate(-45deg);
            background-size: 18px;
          }
        }
        &::after {
          content: '';
          position: absolute;
          background-image: url(../images/send.svg);
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          top: 50%;
          transform: translateY(-50%);
          right: 13px;
          background-size: 11px;
          background-position: center center;
          background-color: $blue;
          border-radius: 50%;
          @include bp(sm-max) {
            right: 10px;
          }
        }
        input {
          height: 40px;
          padding: 5px 45px;
          border-radius: 10px;
          border: solid 1px $gray-shade-9;
          background-color: rgba(255, 255, 255, 0.87);
          width: 100%;

          &:placeholder {
            @include bp(sm-max) {
              font-size: 14px;
            }
          }

          @include bp(sm-max) {
            height: 35px;
            padding: 5px 35px;
          }
        }
      }
    }
  }
}
.message-sec {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border-radius: 10px;
  border: solid 1px #bababa;
  .attachment-div {
    position: relative;
    label {
      position: relative;
      display: block;
      width: 20px;
      cursor: pointer;
      .btn-image {
        position: relative;
        height: 20px;
      }
    }
    input {
      display: none;
    }
  }
  .message-div {
    width: 95%;
    padding-left: 14px;
    position: relative;
    input {
      width: 100%;
      position: relative;
    }
  }
  .sendBtn-div {
    position: relative;
    .sendbtn {
      position: relative;
      width: 20px;
      display: flex;
      justify-content: center;
      height: 20px;
      background-color: #22456e;
      border-radius: 50%;
      align-items: center;
      cursor: pointer;
      img {
        width: 10px;
        height: 10px;
        transform: translate(-1px, 1px);
      }
    }
  }
}

.new-msg-block {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 20px;

  button {
    height: 40px;
    line-height: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include bp(sm-max) {
      font-size: 14px;
      height: 35px;
      min-height: 35px;
    }
    @include bp(xxs-max) {
      min-width: 120px;
    }
  }

  .new-msg {
    button {
      background-image: url('../images/icon-add-filled.svg');
      background-repeat: no-repeat;
      background-position: center left 20px;
      padding: 10px 30px 10px 50px;
      text-transform: uppercase;

      @include bp(sm-max) {
        padding: 10px 15px 10px 30px;
        background-position: center left 10px;
        background-size: 15px;
      }

      &:hover {
        background-color: $color-light-green;
        color: $white;
      }
    }
  }

  .msg-notification {
    button {
      padding: 10px 35px;

      @include bp(sm-max) {
        padding: 10px 15px;
      }
    }

    span {
      height: 22px;
      width: 22px;
      background-color: $white;
      color: $orange-shade;
      border-radius: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      margin-right: -15px;

      @include bp(sm-max) {
        height: 17px;
        width: 17px;
        margin-left: 10px;
        margin-right: 0;
        font-size: 13px;
      }
    }
  }
}

.chat-message-wrapper {
  .send-message {
    padding: 10px 20px;
    border-radius: 0 10px 10px 10px;
    background-color: $color-grey;
    max-width: 55%;
    margin-bottom: 50px;

    @include bp(xlg-max) {
      margin-bottom: 20px;
    }

    @include bp(md-max) {
      max-width: 70%;
    }

    @include bp(sm-max) {
      max-width: 85%;
      padding: 10px;
    }
    @include bp(xs-max) {
      max-width: calc(100% - 10px);
      padding: 10px;
      margin-bottom: 10px;
    }

    p {
      font-family: $primary-font;
      font-size: 14px;
      line-height: 20px;
      color: $blue;
      @include bp(xs-max) {
        font-size: 13px;
        line-height: 18px;
      }
    }

    &.img-block {
      background-color: transparent;
      height: 195px;
      width: 195px;
      border-radius: 10px;
      @include bp(sm-max) {
        height: 150px;
        width: 150px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      @include bp(xs-max) {
        height: 100px;
        width: 100px;
      }
    }
  }

  .recive-message {
    padding: 10px 20px;
    border-radius: 10px 10px 0 10px;
    background-color: $color-light-green;
    max-width: 55%;
    margin: 0 0 50px auto;

    @include bp(xlg-max) {
      margin: 0 0 20px auto;
    }

    @include bp(md-max) {
      max-width: 70%;
    }

    @include bp(sm-max) {
      max-width: 85%;
      padding: 10px;
    }
    @include bp(xs-max) {
      max-width: calc(100% - 10px);
      padding: 10px;
      margin-bottom: 10px;
    }

    p {
      font-family: $primary-font;
      font-size: 14px;
      line-height: 20px;
      color: $blue;
      @include bp(xs-max) {
        font-size: 13px;
        line-height: 18px;
      }
    }

    &.img-block {
      background-color: transparent;
      height: 195px;
      width: 195px;
      border-radius: 10px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      @include bp(xlg-max) {
        margin: 0 0 20px auto;
      }
      @include bp(sm-max) {
        height: 150px;
        width: 150px;
      }
      @include bp(xs-max) {
        height: 100px;
        width: 100px;
      }
    }
  }
}

.counter-popup .sche-btn-wrap.counter-btn-wrap .btn {
  min-width: 30.33%;
  padding: 9px 0px !important;
  text-align: center;
}
.slider-wrapper {
  margin-bottom: 60px;
  @include bp(md-max) {
    margin-bottom: 30px;
  }
  @include bp(sm-max) {
    padding: 0 10px 0 12px;
  }
  .MuiSlider-root {
    margin-bottom: 0;
    width: 98%;
  }
  .MuiSlider-markLabel {
    display: none;
  }
}
.slider-wrapper {
  .MuiSlider-root {
    height: 5px;
    margin-bottom: 60px;
  }

  .MuiSlider-thumb {
    background-color: $color-light-green;
    margin-left: -11px;
    width: 22px;
    height: 22px;
    margin-top: -8px;

    [class^='PrivateValueLabel-circle'] {
      background-color: $color-light-green !important;
      height: 25px;
      width: auto;
      transform: none;
      border-radius: 0;
      padding: 2px 10px;

      span {
        transform: none;
      }
    }
    span {
      &:first-child {
        &:before {
          border: solid;
          border-color: $color-light-green transparent;
          border-width: 7px 4px 0 4px;
          content: '';
          left: 40%;
          bottom: -7px;
          position: absolute;
        }
        span {
          &:first-child {
            background-color: $color-light-green !important;
            height: 25px;
            width: auto;
            transform: none;
            border-radius: 4px;
            padding: 6px 10px;
            margin-left: 0;
            span {
              transform: none;
            }
          }
        }
      }
    }
  }
  .MuiSlider-valueLabel {
    top: -25px;
    left: calc(-50% - 13px);
  }
  .MuiSlider-rail {
    height: 5px;
    background-color: $gray-shade;
    opacity: 1;
  }

  .MuiSlider-mark {
    width: 0;
  }

  .MuiSlider-track {
    height: 5px;
    background-color: $color-light-green;
  }

  .MuiSlider-markLabel {
    font-size: 16px;
    font-weight: 600;
  }

  &.orange-slider {
    .MuiSlider-thumb {
      background-color: $orange-shade !important;

      // .PrivateValueLabel-circle-4 {
      [class^='PrivateValueLabel-circle'] {
        background-color: $orange-shade !important;
        height: 25px;
        width: auto;
        transform: none;
        border-radius: 0;
        padding: 2px 10px;

        span {
          transform: none;
        }
      }
      span {
        &:first-child {
          &:before {
            border: solid;
            border-color: $orange-shade transparent;
            border-width: 7px 4px 0 4px;
            content: '';
            left: 40%;
            bottom: -7px;
            position: absolute;
          }
          span {
            &:first-child {
              background-color: $orange-shade !important;
              height: 25px;
              width: auto;
              transform: none;
              border-radius: 4px;
              padding: 6px 10px;
              margin-left: 0;
              span {
                transform: none;
              }
            }
          }
        }
      }
    }

    .MuiSlider-track {
      background-color: $orange-shade !important;
    }
  }
}

.slider-section {
  margin: 30px 0 60px;
  .container-main {
    display: flex;
    justify-content: space-between;
    @include bp(xs-max) {
      flex-wrap: wrap;
    }
    .slider-wrapper {
      flex: 0 0 calc(100% - 360px);
      width: calc(100% - 360px);
      border: 1px solid $grey-shade-15;
      border-radius: 15px 0 0 15px;
      overflow: hidden;
      height: 567px;
      position: relative;
      @include bp(lg-max) {
        height: 450px;
        flex: 0 0 calc(100% - 300px);
        width: calc(100% - 300px);
      }
      @include bp(sm-max) {
        height: 300px;
        flex: 0 0 calc(100% - 210px);
        width: calc(100% - 210px);
      }
      @include bp(xs-max) {
        flex: 0 0 100%;
        width: 100%;
        margin-bottom: 20px;
        border-radius: 15px 15px 0 0;
      }
      .owl-item {
        height: 567px;
        @include bp(lg-max) {
          height: 450px;
        }
        @include bp(sm-max) {
          height: 300px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .owl-prev {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        background-image: url(../images/image-left-arrow.png);
        height: 40px;
        width: 40px;
        background-size: 40px;
        font-size: 0;
      }
      .owl-next {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background-image: url(../images/image-right-arrow.png);
        height: 40px;
        width: 40px;
        background-size: 40px;
        font-size: 0;
      }
    }
    .right-block {
      width: 350px;
      @include bp(lg-max) {
        width: 290px;
      }
      @include bp(sm-max) {
        width: 200px;
      }
      @include bp(xs-max) {
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .top-image {
        margin-bottom: 8px;
        border-top-right-radius: 15px;
        overflow: hidden;
        @include bp(lg-max) {
          height: 220px;
        }
        @include bp(sm-max) {
          height: 145px;
        }
        @include bp(xs-max) {
          border-radius: 0 0 15px 15px;
          width: calc(50% - 5px);
          height: 120px;
          img {
            height: 100%;
          }
        }
        img {
          width: 100%;
        }
      }
      .bottom-image {
        border-bottom-right-radius: 15px;
        overflow: hidden;
        @include bp(lg-max) {
          height: 220px;
        }
        @include bp(sm-max) {
          height: 145px;
        }
        @include bp(xs-max) {
          border-radius: 0 0 15px 15px;
          width: calc(50% - 5px);
          height: 120px;
          img {
            height: 100%;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
.content-wrapper {
  .sub-title {
    font-family: $secondary-font-bold;
    font-size: 18px;
    color: $blue;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    @include bp(xs-max) {
      flex-wrap: wrap;
    }
    .right-block {
      @include bp(xs-max) {
        margin-left: 10px;
        margin-left: auto;
      }
      .title {
        font-family: $secondary-font-book;
        font-size: 16px;
        color: $gray-shade-5;
        margin-right: 10px;
      }
      .value {
        font-family: $secondary-font-medium;
        font-size: 16px;
        color: $blue;
      }
    }
  }
  .slider-wrapper {
    margin-bottom: 60px;
    @include bp(md-max) {
      margin-bottom: 30px;
    }
    @include bp(sm-max) {
      padding: 0 10px 0 12px;
    }
    .MuiSlider-root {
      margin-bottom: 0;
      width: 98%;
    }
    .MuiSlider-markLabel {
      display: none;
    }
  }
  .value-wrapper {
    display: flex;
    justify-content: space-between;
    @include bp(xs-max) {
      flex-wrap: wrap;
    }
    button {
      height: 27px;
      line-height: 1;
      min-width: 100px;
      font-family: $secondary-font-book;
      font-size: 14px;
      margin-left: 10px;
      padding: 0;
    }
    .right-block {
      @include bp(xs-max) {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 10px;
      }
      button {
        @include bp(xs-max) {
          min-width: auto;
          padding: 2px 10px;
        }
      }
    }
  }
  .terms-btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include bp(xs-max) {
      justify-content: center;
    }
    // margin-bottom: 60px;
    .term-btn {
      border: 2px solid $gray-shade;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $secondary-font-medium;
      font-size: 19px;
      color: $blue;
      height: 50px;
      width: 144px;
      border-radius: 25px;
      font-family: $secondary-font-medium;
      font-size: 16px;
      cursor: pointer;
      @include bp(md-max) {
        height: 40px;
        width: auto;
        padding: 5px 15px;
      }
      @include bp(xs-max) {
        margin: 0 5px 5px;
      }
      &.active {
        background-color: $orange-shade;
        color: $white;
        border-color: $orange-shade;
      }
    }
  }
  .offer-block {
    .right-block {
      flex-direction: column;
      align-items: flex-end;
      display: flex;
      .title {
        margin-right: 0;
      }
    }
  }
  &.pay-cash {
    .content {
      .title {
        font-family: $secondary-font-book;
        font-size: 16px;
        color: $gray-shade-5;
        margin-right: 10px;
      }
      .value {
        font-family: $secondary-font-medium;
        font-size: 16px;
        color: $blue;
      }
    }
    .right-block {
      text-align: right;
    }
  }
}

.pay-wrap {
  display: flex;

  width: 100%;
  position: initial;
}
.mv-r {
  justify-content: end;
}
.mv-l {
  justify-content: start;
}
.attachment-img {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.5em;
}

.attachment-img img {
  width: 7em;
  margin-bottom: 1em;
}
